import React, {useEffect,useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Container, Card, Table,Accordion} from 'react-bootstrap';
import { formatINR,formatSymbolFromCurrency } from '../../utils/CommonUtil';
import {getOrderHistory} from '../../actions/UserAction';
import {setMsgModal} from '../../actions/AuthAction';
import Info from '../Info';
import OrderItem from './OrderPreviewItem';

/**
 * Creates a new OrderedItemTable.
 * @class
 */
const AllOrders = props => {
	
	var CryptoJS = require("crypto-js");
	
	const openAckPage = (id)=>{
		var ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();
		console.log(ciphertext);
		let counter=0;
		while(ciphertext.indexOf('+')!=-1)
		{
			counter++
			ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();
			console.log(ciphertext);
			if(ciphertext.indexOf('+')==-1)
			window.open("/order_print/"+encodeURIComponent(ciphertext), '_blank');
		}
		if(counter==0)
		window.open("/order_print/"+encodeURIComponent(ciphertext), '_blank');
		
	}
	
	const openInvoice = (id)=>{
		
		var ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();

		console.log(ciphertext);
		let counter=0;
		while(ciphertext.indexOf('+')!=-1)
		{
			counter++
			ciphertext = CryptoJS.AES.encrypt(String(id), 'test123').toString();
			console.log(ciphertext);
			if(ciphertext.indexOf('+')==-1)
			window.open("/invoice/"+encodeURIComponent(ciphertext), '_blank');
		}
		if(counter==0)
		window.open("/invoice/"+encodeURIComponent(ciphertext), '_blank');
	}
	
	const [from,setFrom]=useState(1);
	const [to,setTo]=useState(6);
	
	const cancel=()=>
	{
		props.setMsgModal({"title": "Cancel Order","message": "Please call up 033-40653034/+91 80172 14927 to request for cancellation","show": true})
	}
	const invoice_error=()=>
	{
		props.setMsgModal({"title": "Invoice Order","message": "The order is not yet invoiced.","show": true})
	}
	const info_content=<><p>can claim to return within 7 days of receipt of the order </p>&nbsp;<p>refund will be initiated only on receipt of the order and subject to the terms and conditions</p>&nbsp;<p>The product must be returned in its original condition along with the invoice, all labels, tags should be present in the garments </p>&nbsp;<p>Any garment sought to be returned or exchanged, should be unworn or unwashed. Garments that are found to be worn or damaged or soiled will not be accepted for return or exchange</p>&nbsp;<p>Please note an order can only cancelled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained.</p>&nbsp;<p>As part of usual business practice, Naumi reserves the right to cancel the order and refund the entire amount back to you, if order has not been approved / processed by Naumi.</p></>
	
	const return_order=()=>
	{
		props.setMsgModal({"title": "Return Order","message": "Please call up 033-40653034/+91 80172 14927 to request for returning the product.","show": true})
	}
	
		
	const item_display=(data,orderid)=>
	{
		return(
		data.map((item,i)=>{
			return(
					<OrderItem product={item} key={orderid.toString().concat(item.itemId).concat(item.itemVariantId).concat(item.rating)} />
			)
		})
		);
	}
	const terms=()=>
	{
		props.setMsgModal({"title":"Terms & Conditions","message":props.termsandcondition,"show":true});
	}
	const reduce=()=>{
		setTo(to-5);
		setFrom(from-5);
	}
	const increase=()=>{
		setTo(to+5);
		setFrom(from+5);
	}
		

    useEffect(() => {
        props.getOrderHistory({"CompanyId":props.companyid,"UserId":props.loginData.data.id,"RecordFrom":from,"RecordTo":to}); 
        },[from,to]);

 
    const renderTable = () => {
        
            return (typeof props.orderhistory.data != "undefined" && props.orderhistory.data!=null && props.orderhistory.data.orderListCustomer!=null) ? props.orderhistory.data.orderListCustomer.map((item, i) => {
                return (
						
						 <Card key={i} className="position-relative mt-3 overflow-none border rounded-0">
                                <Accordion.Toggle title="Open Order" as={Card.Header} className="p-0 order_header pl-2 pr-3 pt-2 pb-2" eventKey={i.toString()}>
										<p className="mb-0"><b>Order ID: </b>{(item.custOrdNo!=null)?item.custOrdNo:"Not Generated"}</p>
                                </Accordion.Toggle>

                            <Accordion.Collapse eventKey={i.toString()}>
                                <Card.Body>
                                    <Row>
									 <Col className="pl-0 fs-14 col-md-4">
									 <p className="mb-0"><b>Order Date/Time: </b>{(item.custOrdNo!=null)?item.orderDateStr:"Not Generated"}</p>
									 <p className="mb-0"><b>Order Status: </b>{item.orderStatus}</p>
									 <p className="mb-0"><b>Order Amount: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.total))}</p>
									 <p className="mb-0"><b> Paid Amount: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.paidAmount))}</p>
									 <p className="mb-0 mt-2">{(item.isCancel)?<a href="#" className='ret-item' onClick={cancel}><b>Cancel Order</b></a>:((item.isReturn)?<a href="#" className='ret-item' onClick={return_order}><b>Return Order</b></a>:<i className="not_cancel"><b>Not Cancellable</b></i>)}</p>
									 </Col>
									 
									 <Col className="pl-0 fs-14 col-md-4">
									 <p className="mb-0"><b>Sub Total: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.subTotal))}</p>
									 <p className="mb-0"><b>Total Tax: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.tax))}</p>
									 <p className="mb-0"><b> Delv Chrg: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.delvCharge))}</p>
									 <p className="mb-0"><b> Discount: </b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(item.discount))}</p>
									 </Col>
                                  
								
							
										
                                    </Row>
<Row className='d-block'>
{(item.items)?(item_display(item.items,item.orderId)):"No items present"}
</Row>
                                </Card.Body>
                            </Accordion.Collapse>
                            <Accordion.Collapse eventKey={i.toString()}>
                                <Card.Footer className="border-0 bg-white">
								<div className='text-right'>
								
								{(item.custOrdNo!=null)?(<button className="primary-button min-same-width mr-2 mb-2" onClick={()=>openAckPage(item.orderId)}>Open Order Acknowledgement</button>):"Order Acknowledgement Not Generated"}
								
								<button className="primary-button min-same-width mb-2" onClick={item.isInvoiced?()=>openInvoice(item.orderId):()=>invoice_error()}>Open Invoice</button>
                                
								</div>
								</Card.Footer>
                            </Accordion.Collapse>

                        </Card>
                               
            
                )
            }) : null;
         
    }

    return (
        <React.Fragment>
        <Card.Body className=" pr-0 pl-0-mob pr-0-mob border-none-mob pb-0-mob">
		
		<div className="col mb-3"><i>For the terms and conditions please click here </i><Info title="Return and Cancellation Policy" content={info_content} /></div>
		
		<br/>
		{(props.orderhistory.data)?((props.orderhistory.data.count>5)?(
				<div className="scroll_order">
				<span>
				<i className="fa fa-arrow-circle-left fa-3x" onClick={(from!=1)?(()=>reduce()):(()=>console.log(from))}/>
				</span>
				&nbsp;
				&nbsp;
				&nbsp;
				<span>
				<i className="fa fa-arrow-circle-right fa-3x" onClick={(to<props.orderhistory.data.count)?(()=>increase()):(()=>console.log(from))}/>
			</span>
			</div>
			):null):null}
        <div className="order_accordion">
                
				<Accordion className="address_accordion" defaultActiveKey="0" >
                        {renderTable()}
						
				</Accordion>
           
        </div>
		<br/>
		{(props.orderhistory.data)?((props.orderhistory.data.count>5)?(
				<div className="scroll_order">
				<span>
				<i className="fa fa-arrow-circle-left fa-3x" onClick={(from!=1)?(()=>reduce()):(()=>console.log(from))}/>
				</span>
				&nbsp;
				&nbsp;
				&nbsp;
				<span>
				<i className="fa fa-arrow-circle-right fa-3x" onClick={(to<props.orderhistory.data.count)?(()=>increase()):(()=>console.log(from))}/>
			</span>
			</div>
			):null):null}
    </Card.Body>
        </React.Fragment>
    );
}
 
const mapStateToProps = state => ({
   companyid:state.HomeData.companyid,
   loginData: state.AuthData.loginData,
   orderhistory: state.UserData.orderhistory,
   phone: state.HomeData.phoneno,
   termsandcondition: state.HomeData.termsncondnord,
   companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
})

export default connect(mapStateToProps,{getOrderHistory,setMsgModal})(AllOrders);
