import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import "../assets/css/generic.css";
import { connect } from 'react-redux';
import "../assets/css/home-v2.css";
/**
 * Creates a new ImageModal.
 * @class
 */
const ImageModal = (props) => {
    
    useEffect(()=>{
        props.openAction();
    }, [])

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
    let secondaryColor=props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:'';
    let tertiaryColor=props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:'';
    
    return (
        <React.Fragment>
            <Modal size="lg" show={props.show} onHide={props.closeAction} centered>
                <Modal.Header className='custom-modal-header pt-1 pb-1 text-white' closeButton> 
                    <Modal.Title >{props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {typeof props.message === 'object' 
                        ? <div>{props.message}</div>
                        : <div dangerouslySetInnerHTML={{__html:props.message}}/>
                    }
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

ImageModal.propTypes = {
    title: PropTypes.string,
    show: PropTypes.bool.isRequired,
    openAction: PropTypes.func,
    closeAction: PropTypes.func,
};

ImageModal.defaultProps = {
    show: false,
    openAction: () => {},
    closeAction: () => {},
}

const mapStateToProps = state => ({
    theme: state.HomeData.theme,
	url: state.HomeData.url,
    companyid: state.HomeData.companyid,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency
})

export default connect(mapStateToProps,{})(ImageModal);
