import React,{useState,useEffect} from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ImageUtil from '../utils/ImageUtil';
import { clearCategory } from '../actions/CategoryAction';
import { connect } from 'react-redux';
import Loading from './Loading';
import { useWindowResizeListener } from '../utils/useHooks';

const Singlebrand = props => {
	const [loading, setLoading] = useState(false);
	const [css,setCss]=useState({width:'100px',height:'80px',borderRadius:'50%'});
	const { width } = useWindowResizeListener();

useEffect(() => {
        setCss((width<768)?{width:'61px',height:'48px',borderRadius:'50%'}:{width: '80px',height:'75px',borderRadius:'50%'});
		console.log(props.mobileState);
    }, [width]);
	const onLoadcomplete = () =>
	{
		setLoading(true);
		props.loading(true);
	}
	return(
	<React.Fragment>
	<div className={loading ? "branding-div branding-div-first pr-2 show" : "hide"}>
    <img alt={props.item.text} src={ImageUtil.getImage(props.item.image)} onLoad={() =>onLoadcomplete()} />
    </div>
	<div className={loading ? "hide" : "show"}  >
       <Loading key={css} css={css} />
     </div>
	 </React.Fragment>
	);
}


const Brands = props => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const handleClick = param => e => {
        e.preventDefault();
        props.clearCategory();
        navigate('/search', {state:{term:param}});
    };

    const renderBrands = () => {
        return props.brands.map((item, index) => {
            return (
                <Col key={index} className="bord-right1px p-0-tab cursor brand-controleachdiv" onClick={(loading)?handleClick(item.text):null}>
                   <Singlebrand item={item} loading={setLoading} />
                    {/* <div className="branding-div branding-div-second pr-2"><h3 className="brands-name">{item.text}</h3></div> */}
                </Col>
            ) 
        })
    }

    return (
        <React.Fragment>
            {/* <div className="branding-section" >
                <div className="container p-0-xs p-0-tab">
                    <Row>
                        {props.brands ? renderBrands() : null}
                    </Row>
                </div>
            </div> */}
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    brands:state.HomeData.brands,
	mobileState: state.CategoryData.isMobileMenu
  })

export default connect(mapStateToProps, { clearCategory }) (Brands);