import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { connect } from "react-redux";
import mobarrow from "../../assets/img/next-arr-drk.png";
import mobarrowdark from "../../assets/img/next-arr-drk-old.png";
import searchback from "../../assets/img/back-arrows.png";
import ImageUtil from "../../utils/ImageUtil"
import "bootstrap/dist/css/bootstrap.css";
import {
  Row,
  Col,
  Container,
  Navbar,
  Nav,
  Dropdown,
  DropdownButton,
  ListGroup,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Logo from "./Logo";
import Cart from "./Cart";
import CartView from "./CartView";
import Search from "./Search";
import CategoryMenu from "../category/CategoryMenu";
import { toggleMenuState } from "../../actions/CategoryAction";
import { set_authenticated } from "../../actions/AuthAction";
import { getCart, saveCart } from "../../actions/CartAction";
//import {addStyle} from '../../utils/CommonUtil';
import { useNavigate } from "react-router-dom";
import MegaCategoryMenu2 from "../category/MegaCategoryMenu2";
import MegaCategoryMenu1 from "../category/MegaCategoryMenu1";
import { cleanDiscount, clearOrder } from "../../actions/CheckoutAction";
import {
  set_phone,
  setValidateOtp,
  setProfoption,
} from "../../actions/UserAction";
import LoginModal from "../Login/LoginModal";
import {
  setSearch,
  setLogin,
  setLoginModal,
  set_phoneNumber,
  set_password,
  set_login_head,
} from "../../actions/AuthAction";
import { clearLocation } from "../../actions/LocationAction";
import { setWishList } from "../../actions/ProductsAction";
import TopHeader from "../header/TopHeader";
import Announcement from "../header/Announcement";
import imglogo from "../../assets/img/com-logo.png";
import imgdoglogo from "../../assets/img/only-dog-logo.png";

const Header = (props) => {
  const node = useRef();
  const navigate = useNavigate();
  const logOut = () => {
    props.cleanDiscount();
    props.clearOrder();
    props.set_authenticated(false);
    props.set_phone("");
    props.clearLocation();
    props.setValidateOtp({ status: 1, message: "Failed", valid: false });
    props.setLoginModal(true);
    props.set_login_head("Login");
    props.setWishList({ data: [] });
    props.setLogin({});
    props.getCart({ companyid: 0, customerid: parseInt(0) });
    navigate("/");
  };
  //const showHideMenu = (typeof props.showHide == "undefined") ? true : props.showHide;
  const [menuShowHide, setmenuShowHide] = useState(true);
  const [scrollPosition, setPosition] = useState(0);

  const [show, setShow] = useState(false);
  const [topshow, settopShow] = useState(true);
  const showhidetopmobile = () => {
    setShow(true);
    settopShow(false);
  };
  const showhidetopmobilearrow = () => {
    setShow(false);
    settopShow(true);
  };
  const [showmenu, setmenuShow] = useState(false);
  const showmenupannel = () => {
    setmenuShow(true);
  };
  const hidemenupannel = () => {
    setmenuShow(false);
  };
  useLayoutEffect(() => {
    function updatePosition() {
      setPosition(window.pageYOffset);
    }
    window.addEventListener("scroll", updatePosition);
    updatePosition();
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  useEffect(() => {
    if (scrollPosition == 0) {
      setmenuShowHide(true);
      //console.log('bodyDynamicClass',bodyDynamicClass);
    } else {
      setmenuShowHide(false);
      //console.log('bodyDynamicClass',bodyDynamicClass);
    }
  });
  const closeAction = () => {
    props.setLoginModal(false);
    props.set_phoneNumber("");
    props.set_password("");
  };

  const login = () => {
    props.setLoginModal(true);
    props.set_login_head("Login");
  };

  const profile = () => {
    props.setProfoption("first");
    setmenuShow(false);
    navigate("/profile");
  };
  const order = () => {
    props.setProfoption("fourth");
    setmenuShow(false);
    navigate("/profile");
  };
  const wishlist = () => {
    props.setProfoption("seventh");
    setmenuShow(false);
    navigate("/profile");
  };
  const change_pw = () => {
    props.setProfoption("fifth");
    setmenuShow(false);
    navigate("/profile");
  };
  const handleClick = (e) => {
    if (!node.current.contains(e.target)) {
      props.setSearch(false);
    }
  };
  // function showHideSearch() {
  //     const searchboxid = document.getElementById('searchboxid');
  //     searchboxid.style.display = 'block';
  //     alert(1111)
  // }
  useEffect(() => {
    //console.log(props.loginDetails);
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const OnClickandler = () => {
    //window.open("#");
    navigate("/");
  };

  /* const [stylePath, setStylePath] = useState('./assets/css/style1.css');
    
    const [toggle, setToggle] = useState(false);
    const handleButtonClick = (e) => {  
      setToggle(!toggle);
      setStylePath((toggle) ? './assets/css/style1.css' : './assets/css/style2.css');
      console.log('handleButtonClick theme changed to ', stylePath);
    }

    useEffect(() => {
      addStyle(stylePath);
    }, [stylePath]);  */

  const route = (
    <div className="text-center">
      <label className="position-relative hover-submenu mb-0">
        {/* <svg width="19" height="19" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M12 0c-5.083 0-8.465 4.949-3.733 13.678 1.596 2.945-1.725 3.641-5.09 4.418-3.073.709-3.187 2.235-3.177 4.904l.004 1h23.99l.004-.969c.012-2.688-.093-4.223-3.177-4.935-3.438-.794-6.639-1.49-5.09-4.418 4.719-8.912 1.251-13.678-3.731-13.678m0 1c1.89 0 3.39.764 4.225 2.15 1.354 2.251.866 5.824-1.377 10.06-.577 1.092-.673 2.078-.283 2.932.937 2.049 4.758 2.632 6.032 2.928 2.303.534 2.412 1.313 2.401 3.93h-21.998c-.01-2.615.09-3.396 2.401-3.93 1.157-.266 5.138-.919 6.049-2.94.387-.858.284-1.843-.304-2.929-2.231-4.115-2.744-7.764-1.405-10.012.84-1.412 2.353-2.189 4.259-2.189" /></svg> */}
        {/* <p className="m-0 font-12 hidden-xs">{(typeof props.loginDetails.data != "undefined" && props.loginDetails.data != null) ? ((props.loginDetails.data.firstName.length > 5) ? props.loginDetails.data.firstName.substring(0, 5).concat("...") : props.loginDetails.data.firstName) : "Profile"}</p> */}
        <div className="d-inline-block align-top text-center position-relative d-block-only-log">
          <div className="text-center ">
            <label className="m-0 log-user">
              {/* <i className="fa fa-user-o activ-user" aria-hidden="true"></i> */}

             <svg className="user-icon"  height="128px" id="Layer_1"  width="128px" xmlns="http://www.w3.org/2000/svg"><path d="M64.281,84.291c-15.54,0-28.184-12.643-28.184-28.184V42.196c0-15.542,12.644-28.186,28.184-28.186  c15.542,0,28.186,12.644,28.186,28.186v13.911C92.467,71.648,79.823,84.291,64.281,84.291z M64.281,18.01  c-13.335,0-24.184,10.85-24.184,24.186v13.911c0,13.334,10.849,24.184,24.184,24.184c13.336,0,24.186-10.85,24.186-24.184V42.196  C88.467,28.86,77.617,18.01,64.281,18.01z"/><path d="M102.332,114.01h-76.51c-6.518,0-11.819-5.303-11.819-11.82v-8.734c0-3.857,1.953-9.027,11.26-11.738l11.443-3.168  c1.067-0.291,2.167,0.33,2.461,1.395s-0.33,2.166-1.395,2.461l-11.417,3.16c-8.353,2.434-8.353,6.541-8.353,7.891v8.734  c0,4.313,3.508,7.82,7.819,7.82h76.51c4.312,0,7.819-3.508,7.819-7.82v-8.734c0-3.643-2.816-6.299-8.372-7.896l-10.892-3.045  c-1.064-0.297-1.686-1.4-1.388-2.465c0.298-1.063,1.398-1.689,2.464-1.387l10.906,3.049c9.326,2.682,11.281,7.867,11.281,11.744  v8.734C114.151,108.707,108.85,114.01,102.332,114.01z"/></svg>

            </label>
            <p className="mb-0 d-inline-block log-personname">
              {typeof props.loginDetails.data != "undefined" &&
              props.loginDetails.data != null
                ? props.loginDetails.data.firstName.length > 5
                  ? props.loginDetails.data.firstName
                      .substring(0, 5)
                      .concat("...")
                  : props.loginDetails.data.firstName
                : "Profile"}{" "}
            </p>
          </div>
        </div>
        <label className="position-absolute mb-0 hovdropdowndiv">
          <ListGroup>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className=" text-decoration-none" onClick={profile}>
                Profile
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className="text-decoration-none" onClick={order}>
                Order History
              </a>
            </ListGroup.Item>
            {/* <ListGroup.Item className="profile-each-list">
              <a href="#" className="text-decoration-none" onClick={wishlist}>
                Wish List
              </a>
            </ListGroup.Item> */}
            <ListGroup.Item className="profile-each-list">
              <a href="#" className=" text-decoration-none" onClick={change_pw}>
                Change Password
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className="text-decoration-none" onClick={logOut}>
                Log Out
              </a>
            </ListGroup.Item>
          </ListGroup>
        </label>
      </label>
    </div>
  );

  const renderPhone = () => {
    let mobileNum = null;
    if (
      props.phone !== "" &&
      props.phone !== null &&
      typeof props.phone === "object"
    ) {
      if (props.phone.hasOwnProperty("custservice") === true) {
        if (
          typeof props.phone.custservice === "string" &&
          props.phone.custservice.includes("/") === true
        ) {
          let array = props.phone.custservice.split("/");

          if (props.mobileState) {
            mobileNum = (
              <React.Fragment>
                {array[0]}
                <br />
                <span className="alt_mobile_no">{array[1]}</span>
              </React.Fragment>
            );
          } else {
            mobileNum = `${array[0]} / ${array[1]}`;
          }
        } else {
          mobileNum = props.phone.custservice;
        }
      }
    }
    return props.phone ? mobileNum : null;
  };
const menutype =() =>{
  if(props.companyDetailsCurrency.menuType==1){
    return(
      <MegaCategoryMenu1 hidemenupannel={hidemenupannel} />)
  }
  if(props.companyDetailsCurrency.menuType==2){
    return(
    <MegaCategoryMenu2 hidemenupannel={hidemenupannel} />)
}
}
  const displayHeader = () => {
    return (
      <React.Fragment>
        <div className="full-header">
          <div
            className="d-md-none fix-div-mobilecategory"
            style={{ display: showmenu ? "block" : "none" }}
          >
            <div className="mobile-category-menupannel">
              <div className="mob-profilepannel">
                  <div className="mob-proficon">
                    
                    {/* <i className="fa fa-user-o"></i> */}
                    <svg className="user-icon"  height="128px" id="Layer_1"  width="128px" xmlns="http://www.w3.org/2000/svg"><path d="M64.281,84.291c-15.54,0-28.184-12.643-28.184-28.184V42.196c0-15.542,12.644-28.186,28.184-28.186  c15.542,0,28.186,12.644,28.186,28.186v13.911C92.467,71.648,79.823,84.291,64.281,84.291z M64.281,18.01  c-13.335,0-24.184,10.85-24.184,24.186v13.911c0,13.334,10.849,24.184,24.184,24.184c13.336,0,24.186-10.85,24.186-24.184V42.196  C88.467,28.86,77.617,18.01,64.281,18.01z"/><path d="M102.332,114.01h-76.51c-6.518,0-11.819-5.303-11.819-11.82v-8.734c0-3.857,1.953-9.027,11.26-11.738l11.443-3.168  c1.067-0.291,2.167,0.33,2.461,1.395s-0.33,2.166-1.395,2.461l-11.417,3.16c-8.353,2.434-8.353,6.541-8.353,7.891v8.734  c0,4.313,3.508,7.82,7.819,7.82h76.51c4.312,0,7.819-3.508,7.819-7.82v-8.734c0-3.643-2.816-6.299-8.372-7.896l-10.892-3.045  c-1.064-0.297-1.686-1.4-1.388-2.465c0.298-1.063,1.398-1.689,2.464-1.387l10.906,3.049c9.326,2.682,11.281,7.867,11.281,11.744  v8.734C114.151,108.707,108.85,114.01,102.332,114.01z"/></svg>
                    </div>
                  <p className="mb-0 log-personname-mobile">
              {typeof props.loginDetails.data != "undefined" &&
              props.loginDetails.data != null
                ? props.loginDetails.data.firstName.length > 5
                  ? props.loginDetails.data.firstName
                      .substring(0, 5)
                      .concat("...")
                  : props.loginDetails.data.firstName
                : "Profile"}{" "}
                {typeof props.loginDetails.data != "undefined" &&
              props.loginDetails.data != null?
              <React.Fragment>
                <span><img src={mobarrow}/></span>
                <label className="position-absolute mb-0 hovdropdowndiv">
          <ListGroup>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className=" text-decoration-none" onClick={profile}>
                Profile
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className="text-decoration-none" onClick={order}>
                Order History
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className="text-decoration-none" onClick={wishlist}>
                My WishList
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className=" text-decoration-none" onClick={change_pw}>
                Change Password
              </a>
            </ListGroup.Item>
            <ListGroup.Item className="profile-each-list">
              <a href="#" className="text-decoration-none" onClick={logOut}>
                Log Out
              </a>
            </ListGroup.Item>
          </ListGroup>
        </label>
        </React.Fragment>:''}
            </p>
            
              </div>
              <span className="mobile-close-icon" onClick={hidemenupannel}>
                ×
              </span>
              {showmenu ? menutype(): null}
              
              {/* <div className="d-md-inline-block align-top gn-style text-center txt-left-mob">
                    <div className="megamenu-section">
                     
                      <div className="megamenu-section-inner">
                        <ul>
                          <li>
                            <a href="#">Eye Care</a>
                          </li>
                          <li className="megamenu-item">
                            <a href="#">
                              Lip Care
                              <span className="second-label-arrow"><img src={mobarrowdark}/></span>
                            </a>
                            <div className="mega-menu-hoverfull">
                            <Container className="p-0-mob">
                              <div className="all-megamenu-list-div">
                                <div className="main-flex-menulist">
                                  <div className="main-flex-eachdiv">
                                    <h3>Lipcare <span className="second-label-arrow"><img src={mobarrowdark}/></span></h3>
                                    <ul className="d-md-block d-none">
                                      <li>
                                        <a href="">Menu 1</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 2</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 3</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 4</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="main-flex-eachdiv">
                                    <h3>Lipcare And Skin <span className="second-label-arrow"><img src={mobarrowdark}/></span></h3>
                                    <ul className="d-md-block d-none">
                                      <li>
                                        <a href="">Menu 1</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 2</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 3</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 4</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="main-flex-eachdiv">
                                    <h3>Lipcare and Beauty <span className="second-label-arrow"><img src={mobarrowdark}/></span></h3>
                                    <ul className="d-md-block d-none">
                                      <li>
                                        <a href="">Menu 1</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 2</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 3</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 4</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="main-flex-eachdiv">
                                    <h3>Lipcare <span className="second-label-arrow"><img src={mobarrowdark}/></span></h3>
                                    <ul className="d-md-block d-none">
                                      <li>
                                        <a href="">Menu 1</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 2</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 3</a>
                                      </li>
                                      <li>
                                        <a href="">Menu 4</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              </Container>
                            </div>
                          </li>
                          <li>
                            <a href="#">Skin Care</a>
                          </li>
                          <li>
                            <a href="#">Body Care</a>
                          </li>
                        </ul>
                      </div>
                      
                    </div>
                  </div> */}

            </div>
          </div>
          <Announcement/>
          <TopHeader />
          <div className="head-shadow">
            <div className="container">
              <Row
                ref={node}
                className="topsection-header"
                style={{ display: topshow ? "flex" : "none" }}
              >
                {/* <Col md={4} xs={1} className={props.mobileState?"hidden-xs":"hidden-xs categorymenu-section"}><CategoryMenu /></Col> */}
                <Col md={2} className="col-5 p-0-mob">
                  <i
                    className="fa fa-bars mr-3 align-middle d-inline-block d-md-none mobile-bar"
                    aria-hidden="true"
                    onClick={showmenupannel}
                  ></i>

                  <img
                    // src={ImageUtil.getImage(props.companyDetailsCurrency && props.companyDetailsCurrency.companyLogo?props.companyDetailsCurrency.logo.src:null)}
                    src={props.companyDetailsCurrency && props.companyDetailsCurrency.companyLogo?props.companyDetailsCurrency.companyLogo:null}
                    onClick={OnClickandler}
                    className="cpointer mains-pagelogo"
                  />
                  {/* <img src={imgdoglogo} onClick={OnClickandler} className="mob-logo d-block d-sm-none" /> */}
                </Col>

                <Col
                  md={10}
                  className={
                    props.mobileState
                      ? "text-right pr-0-mob col-7"
                      : "text-right header-left-section pr-0-mob rest-srchdiv col-9"
                  }
                >
                 {/* <div className="d-none d-md-inline-block align-top gn-style text-center header_scroller_width">
                    <CategoryMenu />
                </div>*/}

                {!props.mobileState?menutype():''}
                  <div className="d-inline-block align-top text-center comm-hovclass comm-marclass m-cont-l-r d-md-none mobile-search-div">
                    <svg
                      className="svg-icon search-icon"
                      onClick={showhidetopmobile}
                      aria-labelledby="title desc"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 19.9 19.7"
                    >
                      <title id="title">Search Icon</title>
                      <desc id="desc">A magnifying glass icon.</desc>
                      <g class="search-path" fill="none" stroke="#848F91">
                        <path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" />
                        <circle cx="8" cy="8" r="7" />
                      </g>
                    </svg>
                  </div>

                  <div className="d-inline-block align-top mr-2 text-center comm-hovclass">
                    {/* <Search /> */}
                    <div className="sh-hd-search" id="searchboxid">
                      <Search />{" "}
                    </div>
                  </div>

                  {/* <div className="d-inline-block align-top mr-2 text-center comm-hovclass">
                                        <ul className='header-twomenu'>
                                            <li><a href='#' className='active-head-labelmenu'>Home</a></li>
                                            <li><a href='#'>Shop</a></li>
                                        </ul>
                                    </div> */}
                  {/* <div className="d-inline-block align-top text-center comm-hovclass comm-marclass m-cont-l-r">
                                        <svg className="svg-icon search-icon-only" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g class="search-path" fill="none" stroke="#848F91"><path stroke-linecap="square" d="M18.5 18.3l-5.4-5.4" /><circle cx="8" cy="8" r="7" /></g></svg>
                                    </div> */}
                  <div className="d-inline-block align-top text-center comm-hovclass comm-marclass m-cont-l-r">
                    {props.authenticated ? (
                      route
                    ) : (
                      <div className="text-center ">
                        <label className="log-user m-0" onClick={login}>
                          {/* <i
                            class="fa fa-user-o nonactvuser"
                            aria-hidden="true"
                          ></i> */}

<svg className="user-icon nonactvuser"  height="128px" id="Layer_1"  width="128px" xmlns="http://www.w3.org/2000/svg"><path d="M64.281,84.291c-15.54,0-28.184-12.643-28.184-28.184V42.196c0-15.542,12.644-28.186,28.184-28.186  c15.542,0,28.186,12.644,28.186,28.186v13.911C92.467,71.648,79.823,84.291,64.281,84.291z M64.281,18.01  c-13.335,0-24.184,10.85-24.184,24.186v13.911c0,13.334,10.849,24.184,24.184,24.184c13.336,0,24.186-10.85,24.186-24.184V42.196  C88.467,28.86,77.617,18.01,64.281,18.01z"/><path d="M102.332,114.01h-76.51c-6.518,0-11.819-5.303-11.819-11.82v-8.734c0-3.857,1.953-9.027,11.26-11.738l11.443-3.168  c1.067-0.291,2.167,0.33,2.461,1.395s-0.33,2.166-1.395,2.461l-11.417,3.16c-8.353,2.434-8.353,6.541-8.353,7.891v8.734  c0,4.313,3.508,7.82,7.819,7.82h76.51c4.312,0,7.819-3.508,7.819-7.82v-8.734c0-3.643-2.816-6.299-8.372-7.896l-10.892-3.045  c-1.064-0.297-1.686-1.4-1.388-2.465c0.298-1.063,1.398-1.689,2.464-1.387l10.906,3.049c9.326,2.682,11.281,7.867,11.281,11.744  v8.734C114.151,108.707,108.85,114.01,102.332,114.01z"/></svg>
                        </label>
                      </div>
                    )}
                  </div>

                  {/* {(!props.authenticated) ? <div className="d-inline-block align-top gn-style mr-0-mob text-center comm-marclass m-cont-l-r"><div onClick={() => navigate("/wishlist")}><i class="fa fa-heart-o wish-heart" aria-hidden="true"></i></div></div> : null} */}

                  <div className="d-inline-block align-top gn-style text-center comm-marclass m-cont-l-r pr-0">
                    <CartView />
                  </div>
                </Col>
              </Row>
              <div
                className="w-100 d-md-none mobile-seach-div p-0-mob"
                style={{ display: show ? "flex" : "none" }}
              >
                <Col className="col-1 p-0-mob pt-5px-mob pb-5px-mob">
                  {" "}
                  {/* <i
                    class="fa fa-arrow-left"
                    aria-hidden="true"
                    onClick={showhidetopmobilearrow}
                  ></i> */}
                  <img className="searchbackicon" src={searchback} onClick={showhidetopmobilearrow} />
                </Col>
                <Col className="col-11 pr-0-mob pl-0-mob">
                  {" "}
                  <Search />{" "}
                </Col>
              </div>
              {/* <Col className={props.mobileState ? "hidden-xs" : "hidden-xs categorymenu-section"}><CategoryMenu /></Col> */}
            </div>
          </div>
        </div>

        {/* <Row className="topsection-category p-0-xs p-0-tab w-100-mob">
                    <Col className="p-0-xs p-0-tab">
                        <Container className="p-0-xs">
                            <Row className="w-100-mob">
                                <Col className="p-0-mob"><CategoryMenu /></Col>
                            </Row>
                        </Container>
                    </Col>

                </Row> */}

        {props.loginmodalshow ? (
          <LoginModal
            closeAction={closeAction}
            show={props.loginmodalshow}
            head={props.loginhead}
          />
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className=" p-0-mob">
        {props.mobileState ? (
          <Navbar
            bg="white"
            sticky="top"
            expand="lg border-bottom"
            className="navstylecontrol"
            onToggle={() => props.toggleMenuState(!props.menuState)}
            expanded={props.menuState}
          >
            {" "}
            {displayHeader()}
          </Navbar>
        ) : (
          displayHeader()
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  phone: state.HomeData.phoneno,
  mobileState: state.CategoryData.isMobileMenu,
  menuState: state.CategoryData.menuState,
  authenticated: state.AuthData.authenticated,
  search: state.AuthData.search,
  loginDetails: state.AuthData.loginData,
  loginmodalshow: state.AuthData.loginmodalshow,
  loginhead: state.AuthData.loginhead,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
});

export default connect(mapStateToProps, {
  toggleMenuState,
  set_authenticated,
  cleanDiscount,
  clearOrder,
  set_phone,
  setValidateOtp,
  setSearch,
  setProfoption,
  clearLocation,
  setLogin,
  setLoginModal,
  setWishList,
  set_phoneNumber,
  set_password,
  set_login_head,
  getCart,
})(Header);
