import React from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Product from '../components/Product';
import { Container, Row } from 'react-bootstrap';
import NoResults from '../components/NoResults';

function SearchPanel(props) {

    const location = useLocation();
    console.log(location.state.term);
    const searchingFor = (term) => {
        return (x) => {
			let final_res=false;
			if(typeof x !="undefined" && x.name!=null && x.brandicon!=null)
			{
				final_res= x.name.toLowerCase().includes(term.toLowerCase()) || x.brandicon.toLowerCase().includes(term.toLowerCase()) || !term;
			}
			else if(typeof x !="undefined" && x.name!=null)
			{
				final_res= x.name.toLowerCase().includes(term.toLowerCase()) || !term;
			}
			else
				final_res= false;
			if(final_res==false)
			{
				if(typeof x !="undefined" && x.item_code!=null)
				{
				final_res= x.item_code.toLowerCase().includes(term.toLowerCase()) || !term;
				}
				else
					final_res=false;
			}
			return final_res;
        }
    }

    let productsData = [];
    if (props.productsList) {
        productsData = props.productsList.filter(searchingFor(location.state.term))
            .map(product => {
                const cartObj = props.cartList.filter(item => {
                    return item.id === product.id
                })
                //
                return (
                    <Product
                        key={product.id}
                        product={product}
                        cart={cartObj[0]}
                    />
                );
            });
    }

    return (
        <div className="products-wrapper">
            
                <div className="mar-0-auto text-center">
                    {productsData.length > 0 ? productsData : <NoResults/>}
                </div>
           
        </div>
    );
}

const mapStateToProps = state => ({
    productsList: state.ProductsData.productsList,
    cartList: state.CartData.cartList,
})

export default connect(mapStateToProps)(SearchPanel);
