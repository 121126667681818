import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';

// Description on const CATEGORYLIST

const PRODUCT_LIST = "PRODUCT_LIST";
const PRODUCT_ADD_INFO = "PRODUCT_ADDITIONAL_INFO";
const PRODUCT_FAQ_INFO = "PRODUCT_FAQ_INFO";
const SELECTED_PRODUCT = "SELECTED_PRODUCT";
const FILTERED_PRODUCTS_BY_CATEGORY = "FILTERED_PRODUCTS_BY_CATEGORY";
const VARIANT_MODAL = "VARIANT_MODAL";
const VARIANT_LIST = "VARIANT_LIST";
const CLEAR_VARIANTS = "CLEAR_VARIANTS";

const FEATURED_ITEMS = "FEATURED_ITEMS";
const GET_WISH_LIST = "GET_WISH_LIST";
const GET_WISH_LIST_CATEGORY="GET_WISH_LIST_CATEGORY"
const GET_WISH_ITEM = "GET_WISH_ITEM";
const SAVE_WISH_LIST = "SAVE_WISH_LIST";
const REMOVE_WISH_LIST = "REMOVE_WISH_LIST";
const DELETE_WISH_LIST="DELETE_WISH_LIST";
const DELETE_WISH_LIST_CATEHORY="DELETE_WISH_LIST_CATEHORY";
const SAVE_RATING = "SAVE_RATING";
const GET_RATING = "GET_RATING";
const GET_SECTIONS = 'GET_SECTIONS';
const SET_SECTIONS = 'SET_SECTIONS';
const SECTIONFOR_LIST = "SECTIONFOR_LIST";
const PRODUCT_RELATEDITEM_INFO = "PRODUCT_RELATEDITEM_INFO";

//.....................................
/**
 * Action getProductList defined.
 * @constructor
 */
export const getProductsList = (cid) => {
    return apiAction({
        url: `${API.GET_ALL_ITEMS}${cid}`,
        method: "post",
        onSuccess: setProductsList,
        onFailure: () => console.log("Error occured in getCategoryList"),
        label: PRODUCT_LIST
    });
}
/**
 * Action getProductsDetailsByItem defined.
 * @constructor
 */
export const getProductsDetailsByItem = (obj) => {
    return apiAction({
        url: API.GET_ADDITIONALINFO_BY_ITEM,
        method: "post",
        data: obj,
        onSuccess: getProductsAddtionalInfo,
        onFailure: () => console.log("Error occured in getProductsDetailsByItem"),
        label: PRODUCT_ADD_INFO
    });
}
export const getFAQDetails = (obj) => {
    return apiAction({
        url: API.GET_FAQ,
        method: "post",
        data: obj,
        onSuccess: getFAQInfo,
        onFailure: () => console.log("Error occured in getFAQDetails"),
        label: PRODUCT_FAQ_INFO
    });
}
export const getSectionItems = (ob) => {
    return apiAction({
        url: API.GET_SECTIONS,
        method: "post",
        data: ob,
        onSuccess: setSectionItems,
        onFailure: () => console.log("Error occured in getSectionItems"),
        label: GET_SECTIONS
    });
}
export const getRelatedItemDetails = (obj) => {
    return apiAction({
        url: API.GET_RELATEDITEM,
        method: "post",
        data: obj,
        onSuccess: getRelatedItemInfo,
        onFailure: () => console.log("Error occured in getRelatedItemDetails"),
        label: PRODUCT_RELATEDITEM_INFO
    });
}
export const setSectionItems = param => ({
    type: SET_SECTIONS,
    payload: param.data
});

export const setProductsList = param => ({
    type: PRODUCT_LIST,
    payload: param.data
});
export const getProductsAddtionalInfo = param => ({
    type: PRODUCT_ADD_INFO,
    payload: param.data

});
export const getFAQInfo = param => ({
    type: PRODUCT_FAQ_INFO,
    payload: param.data

});

export const getRelatedItemInfo = param => ({
    type: PRODUCT_RELATEDITEM_INFO,
    payload: param.data

});
export const saveRating = (obj) => {
    return apiAction({
        url: API.SAVE_RATING,
        data: obj,
        method: "post",
        onSuccess: setSaveRating,
        onFailure: () => console.log("Error occured in saveRating"),
        callback: 'saverating',
        label: SAVE_RATING
    });
}

export const setSaveRating = param => ({
    type: SAVE_RATING,
    payload: param
});

export const getRating = (obj) => {
    return apiAction({
        url: API.GET_RATING,
        method: "post",
        data: obj,
        onSuccess: setGetRating,
        onFailure: () => console.log("Error occured in getRating"),
        callback: 'getrating',
        label: GET_RATING
    });
}

export const setGetRating = param => ({
    type: GET_RATING,
    payload: param
});

/**
 * Action getfeaturedItems defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const getfeaturedItems = (cid) => {
    return apiAction({
        url: `${API.GET_FEATURE_ITEMS}${cid}`,
        method: "post",
        onSuccess: setfeaturedItems,
        onFailure: () => console.log("Error occured in getfeaturedItems"),
        label: FEATURED_ITEMS
    });
}

export const setfeaturedItems = param => ({
    type: FEATURED_ITEMS,
    payload: param.data
});
export const getWishList = (ob) => {
    return apiAction({
        url: API.GET_WISH,
        method: "post",
        data: ob,
        onSuccess: setWishList,
        onFailure: () => console.log("Error occured in getWishList"),
        label: GET_WISH_LIST
    });
}

export const setWishList = param => ({

    type: GET_WISH_LIST,
    payload: param.data.getWishListCategory

});
export const getWishListItemById = (ob) => {
    return apiAction({
        url: API.GET_WISHLIST_ITEM,
        method: "post",
        data: ob,
        onSuccess: setWishListItemById,
        onFailure: () => console.log("Error occured in getWishListItemById"),
        label: GET_WISH_ITEM
    });
}

export const setWishListItemById = param => ({

    type: GET_WISH_ITEM,
    payload: param.data.customerWishItems

});
export const saveWishList = (ob) => {
    return apiAction({
        url: API.SAVE_WISH,
        method: "post",
        data: ob,
        onSuccess: savedWishList,
        onFailure: () => console.log("Error occured in saveWishList"),
        callback: 'savewishlist',
        label: SAVE_WISH_LIST
    });
}

export const savedWishList = param => ({
    type: SAVE_WISH_LIST,
    payload: param
});

export const SaveUpdateWishCategory = (ob) => {
    return apiAction({
        url: API.SAVE_WISHLIST,
        method: "post",
        data: ob,
        onSuccess: setWishListCategory,
        onFailure: () => console.log("Error occured in SaveUpdateWishCategory"),
        callback: 'getWishList',
        label: GET_WISH_LIST_CATEGORY
    });
}
export const setWishListCategory = param => ({

    type: GET_WISH_LIST_CATEGORY,
    payload: param.data.wishListCategory
});
export const removeWishList = (ob) => {
    return apiAction({
        url: API.REMOVE_WISH,
        method: "post",
        data: ob,
        onSuccess: removedWishList,
        onFailure: () => console.log("Error occured in removeWishList"),
        callback: 'savewishlist',
        label: REMOVE_WISH_LIST
    });
}
export const removedWishList = param => ({
    type: REMOVE_WISH_LIST,
    payload: param
});
export const deleteWishItem = (ob) => {
    return apiAction({
        url: API.DELETE_WISHITEM,
        method: "post",
        data: ob,
        onSuccess: deletedWishItem,
        onFailure: () => console.log("Error occured in deleteWishItem"),
        callback: 'getWishListItemById',
        label: DELETE_WISH_LIST
    });
}

export const deletedWishItem = param => ({
    type: DELETE_WISH_LIST,
    payload: param.data.deleteCustomerWishItem[0].returnMessage
});

export const DeleteWishListCategoryWithItems = (ob) => {
    return apiAction({
        url: API.DELETE_WISHLISTCATEHORY,
        method: "post",
        data: ob,
        onSuccess: deletedWishlistCategory,
        onFailure: () => console.log("Error occured in DeleteWishListCategoryWithItems"),
        callback: 'deletedWishlistCategory',
        label: DELETE_WISH_LIST_CATEHORY
    });
}

export const deletedWishlistCategory = param => ({
    type: DELETE_WISH_LIST_CATEHORY,
    payload: param.data.deleteWishListCategoryWithItems
});

//.....................................
/**
 * Action getItemsByVariant defined.
 * @constructor
 */
export const getItemsByVariant = (obj) => {
    return apiAction({
        url: `${API.GET_ITEMS_BY_VARIANT}`,
        method: "post",
        data: obj,
        onSuccess: setItemByVariant,
        onFailure: () => console.log("Error occured in getItemsByVariant"),
        label: VARIANT_LIST,
        callback: 'itemsbyvariant'
    });
}

export const setItemByVariant = param => {
    return ({
        type: VARIANT_LIST,
        payload: param.data
    })
};
//...................................................................

/**
 * Action GetItemDetailBySectionFor defined.
 * @constructor
 */
export const getItemDetailBySectionFor = (obj) => {
    console.log('obj', obj)
    return apiAction({
        url: `${API.Get_ITEM_DETAIL_BY_SECTIONFOR}`,
        method: "post",
        data: obj,
        onSuccess: setItemVariantBySectionFor,
        onFailure: () => console.log("Error occured in getItemDetailBySectionFor"),
        label: SECTIONFOR_LIST,
        callback: 'sectionFor'
    });
}

export const setItemVariantBySectionFor = param => {
    return ({
        type: SECTIONFOR_LIST,
        payload: param.data
    })
};


//.....................................
//...................................................................

/**
 * Action GetSearchItem defined.
 * @constructor
 */
export const GetSearchItem = (obj) => {
    console.log('obj', obj)
    return apiAction({
        url: `${API.SEARCHITEM}`,
        method: "post",
        data: obj,
        onSuccess: setItemVariantBySectionFor,
        onFailure: () => console.log("Error occured in getItemDetailBySectionFor"),
        label: SECTIONFOR_LIST,
        callback: 'sectionFor'
    });
}


//.....................................
/**
 * Action  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const filterByCategory = param => ({
    type: FILTERED_PRODUCTS_BY_CATEGORY,
    payload: param
});

//...................................................................
/**
 * Action  defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const selectProduct = param => ({
    type: SELECTED_PRODUCT,
    payload: param
});


//...................................................................
/**
 * Action setVariantModal defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setVariantModal = param => ({
    type: VARIANT_MODAL,
    payload: param
});

//...................................................................
/**
 * Action setVariantModal defined.
 * @constructor
 * 
 */
export const clearVariants = () => ({
    type: CLEAR_VARIANTS
});
//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    productsList: [],
    featuredItems: [],
    selectedProduct: {},
    variantModal: false,
    variantList: [],
    filteredProductsByCategory: "",
    wishlist: [],
    wishlistitem: [],
    savewishlist: {},
    deletewishlist:["Yes"],
    removewishlist: {},
    saveratingdata: {},
    getratingdata: {},
    sectionItems: [],
    sectionForList: [],
    AddInfo: [],
    FAQInfo: [],
    RELATEDITEMInfo: [],

}

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_LIST: {
            return {
                ...state,
                productsList: action.payload
            }
        }
        case PRODUCT_ADD_INFO: {
            return {
                ...state,
                AddInfo: action.payload
            }
        }
        case PRODUCT_FAQ_INFO: {
            return {
                ...state,
                FAQInfo: action.payload
            }
        }
        case PRODUCT_RELATEDITEM_INFO: {
            return {
                ...state,
                RELATEDITEMInfo: action.payload
            }
        }
        case GET_WISH_LIST: {
            return {
                ...state,
                wishlist: action.payload
            }
        }
        case GET_WISH_LIST_CATEGORY: {
            return {
                ...state,
                wishlist: action.payload
            }
        }
        case GET_WISH_ITEM: {
            return {
                ...state,
                wishlistitem: action.payload
            }
        }
        case SAVE_WISH_LIST: {
            return {
                ...state,
                savewishlist: action.payload
            }
        }
        case REMOVE_WISH_LIST: {
            return {
                ...state,
                removewishlist: action.payload
            }
        } 
        case DELETE_WISH_LIST: {
            return {
                ...state,
                deletewishlist: action.payload
            }
        }
        case DELETE_WISH_LIST_CATEHORY: {
            return {
                ...state,
                wishlist: action.payload
            }
        }
        case VARIANT_LIST: {
            return {
                ...state,
                variantList: action.payload
            }
        }
        case SECTIONFOR_LIST: {
            return {
                ...state,
                sectionForList: action.payload
            }
        }
        case SELECTED_PRODUCT: {
            return {
                ...state,
                selectedProduct: action.payload
            }
        }
        case CLEAR_VARIANTS: {
            return {
                ...state,
                variantList: []
            }
        }
        case VARIANT_MODAL: {
            return {
                ...state,
                variantModal: action.payload
            }
        }

        case SAVE_RATING: {
            return {
                ...state,
                saveratingdata: action.payload
            }
        }

        case GET_RATING: {
            return {
                ...state,
                getratingdata: action.payload
            }
        }

        case SET_SECTIONS: {
            return {
                ...state,
                sectionItems: Array.isArray(action.payload) ? action.payload : []
            }
        }

        case FEATURED_ITEMS: return { ...state, featuredItems: action.payload }

        default:
            return state;
    }
}

//...................................................................
//.. selectors :: TODO


//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const ProductsAction = {
    reducer: reducer,
    actions: {
        getProductsList,
        filterByCategory,
        selectProduct,
        getItemsByVariant,
        setVariantModal,
        clearVariants,
        saveWishList,
        getWishList,
        getWishListItemById,
        SaveUpdateWishCategory,
        deleteWishItem,
        removeWishList,
        saveRating,
        getRating,
        getSectionItems,
        getItemDetailBySectionFor,
        getProductsDetailsByItem,
        getFAQDetails,
        getRelatedItemDetails
    }
}

export default ProductsAction