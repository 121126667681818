import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import ImageUtil from "../utils/ImageUtil";
import { formatINR, formatSymbolFromCurrency } from '../utils/CommonUtil';
import Counter from "./Counter";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { getItemsByVariant,getProductsDetailsByItem, selectProduct, saveWishList, getWishList, removeWishList, getRating } from '../actions/ProductsAction';
import { Global } from '../utils/Env';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css'
import InnerImageZoom from 'react-inner-image-zoom'
import Info from './Info';
import { previewCart } from '../actions/CartAction';
import MsgModal from '../container/MsgModal';
import SizeChart from './SizeChart';
import { setLoginModal, set_login_head } from '../actions/AuthAction';
import 'react-accessible-accordion/dist/fancy-example.css';

const ProductDetails = (props) => {

	const navigate = useNavigate();
	const params = useParams();
	const [selectedProduct, setSelectedProduct] = useState({});
	const [show, setShow] = useState(false);
	const [content, setContent] = useState(<SizeChart />);
	const [head, setHead] = useState("Size Chart");
	const [final_color, setColor] = useState(null);
	const [final_size, setSize] = useState(null);
	const [colorList, setColorList] = useState([]);
	const [sizeList, setSizeList] = useState([]);
	const [rate, setRate] = useState(0);
	let wishlisting = false;
	useEffect(() => {
		if (selectedProduct.id && selectedProduct.variantid)
			props.getRating({ "CompanyId": props.companyid, "ItemId": selectedProduct.id, "VariantId": selectedProduct.variantid, "OrderId": 0 });
	}, [selectedProduct])

	Global.callback.getrating_onComplete = () => {
		if (props.getratingdata.status && props.getratingdata.data.length > 0)
			setRate(props.getratingdata.data[0].rating);
		else
			setRate(0);
	}

	const product = (props.productsList.filter(product => {
		return Number(product.id) === Number(params.slug)
	}))[0];

	const info_content = <><p>Any prepaid order pending due to unavability of stock from our end will be refunded of the full value paid</p></>
	const info_content1 = <><p>We have made every effort to display the colours of our products that appear on our site as accurately as possible. However, as computer monitors, tablets and mobile devices vary, we cannot guarantee that your monitor's display of a colour will be completely accurate</p></>
	//console.log("params ", params, product, selectedProduct);
	useEffect(() => {
		//console.log(product);
		if (typeof product != "undefined" && typeof product.variant != "undefined") {
			if (product.variant[0] == null)
				setSelectedProduct(product);
			else
				props.getItemsByVariant({ companyid: props.companyid, variant: product.variant, itemId: Number(params.slug) })
		}
	},
		// eslint-disable-next-line
		[product])
		useEffect(() => {
			//console.log(product);
			if (typeof product != "undefined" && typeof product.variant != "undefined") {
				if (product.variant[0] == null)
					setSelectedProduct(product);
				else
					props.getProductsDetailsByItem({ companyid: props.companyid, variant: product.variant, itemId: Number(params.slug) })
			}
		},
			// eslint-disable-next-line
			[product])
	Global.callback.itemsbyvariant_onComplete = () => {
		let final_deep = (props.wishlist.filter((item) => { return item.itemId == product.id }).length > 0) ? (props.variantList.filter((item) => { return item.id == props.wishlist.filter((item) => { return item.itemId == product.id })[0].itemId && item.variantid == props.wishlist.filter((item) => { return item.itemId == product.id })[0].variantId }).length > 0 ? props.variantList.filter((item) => { return item.id == props.wishlist.filter((item) => { return item.itemId == product.id })[0].itemId && item.variantid == props.wishlist.filter((item) => { return item.itemId == product.id })[0].variantId })[0] : props.variantList[0]) : props.variantList[0];
		setSelectedProduct(final_deep);
		let colors = [];
		props.variantList.map((item, i) => {
			if (colors.filter((color) => { return (color == item.color) }).length == 0)
				colors.push(item.color);
		});
		setColorList(colors);
		setColor(final_deep.color);
		let sizes = [];
		props.variantList.map((item, i) => {
			if (item.color == final_deep.color)
				sizes.push(item.size);
		});
		setSize(final_deep.size);
		setSizeList(sizes);
	}

	Global.callback.savewishlist_onComplete = () => {
		props.getWishList({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
	}

	const closeAction = () => {
		setShow(false);
	}

	const onCartClicked = () => {
		//props.checkOutOrder(props.checkoutData);
		props.previewCart(true);
	}

	const wishlist = () => {
		wishlisting = true;
		if (!props.authenticated) {
			props.setLoginModal(true);
			props.set_login_head("Login");
		}
		else {
			let obj = {
				"companyid": props.companyid,
				"customerid": parseInt(props.loginData.data.customerId),
				"userid": props.loginData.data.id,
				"carttItemVariant": [{
					"itemId": selectedProduct.id,
					"itemVariantId": (selectedProduct.variantid ? selectedProduct.variantid : 0)
				}]
			}
			props.saveWishList(obj);
			setHead("Wish List");
			setShow(true);
			setContent("The item has been added to your wishlist");
		}

	}

	const removewishlist = () => {
		wishlisting = true;
		if (!props.authenticated) {
			props.setLoginModal(true);
			props.set_login_head("Login");
		}
		else {
			let obj = {
				"companyid": props.companyid,
				"customerid": parseInt(props.loginData.data.customerId),
				"userid": props.loginData.data.id,
				"itemId": selectedProduct.id,
				"itemVariantId": (selectedProduct.variantid ? selectedProduct.variantid : 0)
			}
			props.removeWishList(obj);
			setHead("Wish List");
			setShow(true);
			setContent("The item has been removed from your wishlist");
		}

	}

	const onMoreShopping = () => window.history.length <= 2 ? navigate("/products") : navigate(-1);

	//console.log(props.variantList, product, selectedProduct)

	const handleVariantChange = (size, color) => {
		props.variantList.map((item, i) => {
			if (item.color == color && item.size == size)
				setSelectedProduct(item);
		});
	}

	const color_selected = (color) => {
		setColor(color);
		let sizes = []
		props.variantList.map((item, i) => {
			if (item.color == color)
				sizes.push(item.size);
		});
		if (sizes.filter((item) => { return item == final_size }).length == 0) {
			setSize(sizes[0]);
			handleVariantChange(sizes[0], color);
		}
		else
			handleVariantChange(final_size, color);
		setSizeList(sizes);
	}
	const size_selected = (size) => {
		setSize(size);
		handleVariantChange(size, final_color);
	}
	const handleVarientChange = (product) => {
		setSelectedProduct(product)
	}

	const handleImageZoom = (i, e) => {
		if (wishlisting == false) {
			setHead("Image Zoom");
			setShow(true);
			setContent(selectedProduct.images.map((item, id) => {
				if (id == i)
					return (
						<InnerImageZoom key={id} src={ImageUtil.getImage(item.name)} alt={item.name} fullscreenOnMobile={true} mobileBreakpoint={450} height={200} />)
			}));
		}
		wishlisting = false;
	}
	const renderThumbs = () => {
		let imageList;
		if (selectedProduct.images[0]) {
			imageList = selectedProduct.images.map((item, id) => {
				return (
					<img key={id} src={ImageUtil.getImage(item.name.substring(0, item.name.lastIndexOf(".")).concat("_Thumbnail").concat(item.name.substring(item.name.lastIndexOf("."), item.name.length)))} alt={item.name} />
				)
			})
		}
		else {
			imageList = (
				<img className="quick-view-product-image"
					src={ImageUtil.getErrorImage()}
					alt=""
				/>
			);
		}
		return imageList;
	}

	const sizechart = () => {
		setHead("SIZE CHART");
		setShow(true);
		setContent(<SizeChart />);
	}

	const renderImages = () => {
		let imageList;
		if (selectedProduct.images[0]) {
			imageList = selectedProduct.images.map((item, id) => {
				return (
					<div className="quick-view-image" key={id}>
						<img key={id} src={ImageUtil.getImage(item.name.substring(0, item.name.lastIndexOf(".")).concat("_Medium").concat(item.name.substring(item.name.lastIndexOf("."), item.name.length)))} alt={item.name} />
						{selectedProduct.brandicon ? <div className="brand-iconmaindiv"><img className="quick-view-brand-img" alt={selectedProduct.brandicon} src={selectedProduct.brandicon} /></div> : null}
						<div className="brand-iconmaindiv-details">
							{(props.authenticated) ? ((selectedProduct.variantid) ?
								((props.wishlist.filter((item) => { return item.itemId == selectedProduct.id && item.variantId == selectedProduct.variantid }).length != 0) ? <span className={props.mobileState ? " label-wish" : " label-wish-product"}><i className="fa fa-heart fa-2x wishlisted" onClick={() => removewishlist()} /></span> : <span className={props.mobileState ? " mt-1 label-wish" : " label-wish-product"}><i className="fa fa-heart fa-2x not-wishlisted" onClick={() => wishlist()} /></span>) :
								((props.wishlist.filter((item) => { return item.itemId == selectedProduct.id }).length != 0) ? <span className={props.mobileState ? " label-wish" : " label-wish-product"}><i className="fa fa-heart fa-2x wishlisted" onClick={() => removewishlist()} /></span> : <span className={props.mobileState ? " mt-1 label-wish" : " label-wish-product"}><i className="fa fa-heart fa-2x not-wishlisted" onClick={() => wishlist()} /></span>)) : <span className={props.mobileState ? " mt-1 label-wish" : " label-wish-product"}><i className="fa fa-heart fa-2x not-wishlisted" onClick={() => wishlist()} /></span>
							}
						</div>
					</div>
				)
			})
		}
		else {
			imageList = (<div className="quick-view-image">
				<img className="quick-view-product-image"
					src={ImageUtil.getErrorImage()}
					alt=""
				/>
				{selectedProduct.brandicon !== "" ? <div className="brand-iconmaindiv"><img className="quick-view-brand-img" alt={selectedProduct.brandicon} src={selectedProduct.brandicon} /></div> : null}

			</div>
			)
		}
		return imageList;
	}
	if (Object.keys(selectedProduct).length > 0) {
		return (
			<React.Fragment>
				{/* <div className="action-block action-blockpopup pos-fixedclass">
          <Col>
            <div className="chk-out-button  text-left bx-shadow-withbord pl-0 pr-0">
              <div className="product-links-anchor cursor" onClick={onMoreShopping}>Back to Shopping</div>
            </div>
          </Col>
        </div> */}

				<Row className="product-details-maindiv">
					<Container className='pl-10-pr-10-mob'>
						<Row>
							<Col md="3">
								<Carousel key={(selectedProduct.variantid) ? selectedProduct.variantid : selectedProduct.id} thumbWidth={"50px"} renderThumbs={renderThumbs} autoPlay={true} stopOnHover={true} infiniteLoop={true} onClickItem={handleImageZoom}>
									{renderImages()}
								</Carousel>
							</Col>
							<Col md="9">
								<div className="quick-view-description">
									<h3 className="product-codes">{product.item_code}</h3>
									<h3 className="product-names">{selectedProduct.name} &nbsp; <Info title="Disclaimer" content={info_content} />
									</h3>

									{selectedProduct.promo_price ? <p className="product-mrp"><b>Product MRP:</b> <s>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (selectedProduct.price_regular))}</s></p> : ""}

									<p className="prod-selling-price">Selling Price: <span className="">{selectedProduct.promo_price ? formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (selectedProduct.promo_price)) : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (selectedProduct.price_regular))}</span>

										{(selectedProduct.savePricePctg && selectedProduct.savePricePctg > 0) ? <><span>&nbsp;&nbsp;<del style={{opacity:"45%",fontSize:"20px",color:"#040404"}}>{selectedProduct.mrp}</del></span><span className="percentage_off"> <b>({selectedProduct.savePricePctg}% OFF)</b></span></> : null}</p>
									<h4 className='outstock-details'>Out of Stock</h4>
									<span className="badge badge-danger bg-red font-weight-normal mr-5">{selectedProduct.promo_percentage ? `${selectedProduct.promo_percentage} Off` : ""}</span>
									<span className="badge badge-pill badge-danger bg-red font-weight-normal p-1 pl-2 pr-2">{selectedProduct.promo_name || ""}</span>


								</div>
								{!product.issoldout ? (
									<>
										<div className="add-button pt-3 pb-3">
											<Counter product={selectedProduct} />
											{selectedProduct.variantid ? ((props.cartList.filter((item, i) => { return (item.variantid == selectedProduct.variantid && item.id == selectedProduct.id) }).length > 0) ? (<button className={props.mobileState ? "secondary-button_mobile min-same-width_mobile ml-4" : "secondary-button min-same-width ml-4"} onClick={onCartClicked}><i className="fa fa-shopping-bag"></i>&nbsp;&nbsp;Go To Cart</button>) : null) : ((props.cartList.filter((item, i) => { return item.id == selectedProduct.id }).length > 0) ? (<button className={props.mobileState ? "secondary-button_mobile min-same-width_mobile ml-4" : "secondary-button min-same-width ml-4"} onClick={onCartClicked}><i className="fa fa-shopping-bag"></i>&nbsp;&nbsp;Go To Cart</button>) : null)}
											<br />
											<button className={props.mobileState ? "secondary-button_mobile min-same-width_mobile" : "secondary-button mt-3"} onClick={onMoreShopping}><i className="fa fa-arrow-left"></i>&nbsp;&nbsp;Back to Shopping</button>
										</div>
										<div className="size-button add-button">

										</div>
										<div className="quick-view-details">


											{(product.variant[0] != null) ? (<> <div className="mt-3">
												<p className="avl-in mb-2"><b>Available in:</b>&nbsp; <Info title="Colors" content={info_content1} /></p>

												<div className="variant-select-btn-control">
													{colorList.filter(item => { return item != "" }).length > 0 ? <p className="mb-1"><b>Colors</b></p> : null}
													{colorList.map(item => ((item != "") ? <button key={item} className={final_color == item ? "active-variant-btn" : ""} onClick={() => color_selected(item)}>{item}</button> : null))}
												</div>



											</div></>) : null}


											{/* {selectedProduct.price_member ? <button className="btn btn-outline-dark btn-sm mb-3">Price for Club Members:<b>{formatINR(selectedProduct.price_member)}</b></button> : ""} */}
											<div className="details-description-section">

											

												<div className="mt-2 mb-2"><b>Item Description: </b><div className="product_details_cont" dangerouslySetInnerHTML={{ __html: selectedProduct.desc }} /></div>
											</div>
										</div>
									</>) : <><div className="add-button pt-3"><b className="stock_off">SOLD OUT Please try later</b></div><div className="size-button add-button pt-3 pb-3">
									</div></>}
								{rate != 0 ? <p><b>Rating:</b>&nbsp; <i className="fa fa-star" /> {rate}</p> : <p><b>Rating:</b>&nbsp; <i className="fa fa-star" /> No Ratings Yet</p>}
							</Col>
						</Row>
						<Row>
							<Col md={12} className="p-0">
								<div className='faq-div pt-4 pb-4 border-top'>
									<h2 className='faq-header-label'>Buy It With</h2>
									<div className='buy-it-with mt-4'>
										<ul>
											<li>
												<a href='#'><img className="buy-it-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/SHAMPOO-2_mobile.jpg" /></a></li>
											<li> + </li>
											<li>
												<a href='#'><img className="buy-it-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/toy-2_mobile.jpg" /></a></li>
											<li> + </li>
											<li>
												<a href='#'><img className="buy-it-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/toy001_mobile.jpg" /></a></li>
										</ul>
										<div className="fs-14px">
											<div>
												<input type='checkbox' checked />&nbsp;&nbsp;<b>Meat Up Chicken Flavour Real Chicken Biscuit, 1kg (Buy 1 Get 1 Free)
												</b><span>... </span><span className='price-color'>
													₹297.00 (₹14.85/100 g)</span></div>
											<div><input type='checkbox' checked />&nbsp;&nbsp;<a href="#">Chappi Adult Dry Dog Food, Chicken & Rice, 8kg Pack<span>... </span></a> <span className='price-color'>
												₹1,092.00 (₹13.42/100 g)</span></div>
											<div><input type='checkbox' checked />&nbsp;&nbsp;<a href="#">Chappi Puppy Dry Pellet Dog Food, Chicken & Milk, 8 kg<span>... </span></a> <span className='price-color'>
												₹1,116.00 (₹13.95/100 g)</span></div>
										</div>
									</div>

								</div>
							</Col>
						</Row>
						<Row>
							<Col md={6} className="p-0">
								<div className='faq-div pt-4 pb-4 border-top'>
									<h2 className='faq-header-label mb-4'>Additional Information</h2>
									<div>
										<table className='w-100'>
											<tbody>
												<tr>
													<td className='adi-info-leftpart'>
														Flavour
													</td>
													<td className='adi-info-rightpart'>
														Chicken & Lamb in Jelly
													</td>
												</tr>
												<tr>
													<td className='adi-info-leftpart'>
														Key Benefit
													</td>
													<td className='adi-info-rightpart'>
														Complete & Balanced Nutrition
													</td>
												</tr>
												<tr>
													<td className='adi-info-leftpart'>
														Suitable for
													</td>
													<td className='adi-info-rightpart'>
														All Breeds
													</td>
												</tr>
												<tr>
													<td className='adi-info-leftpart'>
														Product Format
													</td>
													<td className='adi-info-rightpart'>
														Gravy Pouch
													</td>
												</tr>
												<tr>
													<td className='adi-info-leftpart'>
														Life stage
													</td>
													<td className='adi-info-rightpart'>
														Adult, Puppy
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</Col>
						</Row>
						<Row>
							<Col md={12} className="p-0">
								<div className='faq-div pt-4 pb-4 border-top'>
									<h2 className='faq-header-label'>Frequently Asked Questions</h2>
									<p className='faq-header-p'>Here are few of the most frequently asked questions by our valueable customers</p>

									<form action="" className="search-form active search-formques mt-4 mb-5">
										<input type="text" placeholder="Have a question? Search for answers" className="search-keyword" value="" /><button className="search-buttontext-light" type="submit"><i className="fa fa-search"></i></button></form>

									<div className='each-qu-ans-div'>
										<div className='quesdiv'><span className='a-text-bold'>Question:</span><span className='d-inline-block pl-4 a-text-regular'>Where to claim the warranty ?</span></div>
										<div className='ansdiv'><span className='a-text-bold'>Answer:</span><span className='d-inline-block pl-4 a-text-regular-ans'>Website</span></div>
									</div>
									<div className='each-qu-ans-div'>
										<div className='quesdiv'><span className='a-text-bold'>Question:</span><span className='d-inline-block pl-4 a-text-regular'>is there any guarantee or warranty of this product?</span></div>
										<div className='ansdiv'><span className='a-text-bold'>Answer:</span><span className='d-inline-block pl-4 a-text-regular-ans'>yes</span></div>
									</div>
									<div className='each-qu-ans-div'>
										<div className='quesdiv'><span className='a-text-bold'>Question:</span><span className='d-inline-block pl-4 a-text-regular'>Where to claim the warranty ?</span></div>
										<div className='ansdiv'><span className='a-text-bold'>Answer:</span><span className='d-inline-block pl-4 a-text-regular-ans'>Website</span></div>
									</div>
									<div className='each-qu-ans-div'>
										<div className='quesdiv'><span className='a-text-bold'>Question:</span><span className='d-inline-block pl-4 a-text-regular'>is there any guarantee or warranty of this product?</span></div>
										<div className='ansdiv'><span className='a-text-bold'>Answer:</span><span className='d-inline-block pl-4 a-text-regular-ans'>yes</span></div>
									</div>
								</div>
							</Col>
						</Row>

						<Row>
							<Col md={12} className="p-0">
								<div className='faq-div pt-4 pb-b border-top'>
									<h2 className='faq-header-label'>Products related to this item1</h2>
								</div>
								<div className="product-details-maindiv raw-bites row mt-0" >
									<div className="container pl-10-pr-10-mob">
										<div className="position-relative row">
											<label className="comon-arrow-style lft-pos">❮</label>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add to cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add to cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add To Cart</button></div>
													</div>
												</div>
											</div>
											<div className="col-md-3">
												<div className="prod">
													<div>
														<img className="prod-img" src="https://library.wizardcomm.in/company/petindiab2c/images/Item/Original/CHICKEN &amp;VEG GRAVY.jpg" />
													</div>
													<div className="col">
														<div className="product_name text-center"> IndiPet Organics Raw Bites Premium Dog Treats- Rabbit Jerky</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 col"><div className="vendor text-center mt-2 mb-2">INDIPET</div></div>
												</div>
												<div className="row">
													<div className="pl-10 col">
														<div className="Price-renge text-center mb-2">
															<div className="price-new mr-1">₹ 690.00</div>
														</div>
													</div>
												</div>
												<div className="row">
													<div className="pl-10 mb-5 col">
														<div className="addtocart-div text-center"><button className="primary-button min-same-width">Add to cart</button></div>
													</div>
												</div>
											</div>
											<label className="comon-arrow-style rig-pos">❯</label>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</Row>

				<MsgModal show={show} title={head} message={content} closeAction={closeAction} />

			</React.Fragment>
		);
	}
	else {
		return ""
	}
}

const mapStateToProps = state => ({
	productsList: state.ProductsData.productsList,
	product: state.ProductsData.selectedProduct,
	variantList: state.ProductsData.variantList,
	companyid: state.HomeData.companyid,
	cartList: state.CartData.cartList,
	mobileState: state.CategoryData.isMobileMenu,
	authenticated: state.AuthData.authenticated,
	loginData: state.AuthData.loginData,
	wishlist: state.ProductsData.wishlist,
	getratingdata: state.ProductsData.getratingdata,
	companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
})

export default connect(mapStateToProps, { getItemsByVariant,getProductsDetailsByItem, selectProduct, previewCart, saveWishList, setLoginModal, getWishList, removeWishList, getRating, set_login_head })(ProductDetails);
