import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { getCategoryList, selectCategory } from "../../actions/CategoryAction";
import { Container, Col } from "react-bootstrap";
import mobarrowdark from "../../assets/img/next-arr-drk-old.png";
import smmenubanner from "../../assets/img/sm-menubanner.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
const MegaCategoryMenu1 = (props) => {
  const { companyid, getCategoryList } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [itemImage, setItemImage] = useState(null);

  useEffect(() => {
    if (companyid && companyid !== "" && props.CategoryList.length==0) {
      getCategoryList(companyid);
    }
  }, [companyid]);

  useEffect(() => {
    props.selectCategory(location.pathname.replace("/category/", ""));
  }, [location.pathname]);

  const menuClick = (itemId) => {
    console.log("on menuclick");
    console.log("itemId", itemId);
    window.location.href = `/categorySection/${0}/${itemId}/${0}`;
  };

  const redirectToAppStore=()=>{
    const ua = navigator.userAgent;
    if(ua.indexOf('iPhone')!=-1)
    {
      console.log("ios");
      window.open('itms-apps://apps.apple.com/in/app/girlco/id1642185530', '_blank').focus();
    }
    else if(ua.indexOf('Android')!=-1)
    {
      console.log("android");
      window.open('https://play.google.com/store/apps/details?id=com.Girlco.App', '_blank').focus();
    }
  }
  return (
    <React.Fragment>
      {props.isMobileMenu ? (
        <div className="d-md-inline-block align-top gn-style text-center txt-left-mob">
          <div className="megamenu-section">
            <div className="megamenu-section-inner">
              <Nav className="me-auto-mobile">
                {props.CategoryList && props.CategoryList.length > 0
                  ? props.CategoryList.map((item, i) => {
                      return (
                        <React.Fragment>
                          {item.sub_category &&
                          item.sub_category.length == 0 ? (
                            <Nav.Link style={{cursor:"pointer"}} className="navlink-mobile-onlymenu"
                              onClick={()=>{props.hidemenupannel();navigate(`/categorySection/${3}/${item.id}/${0}`)}}
                            >
                              {item.name}
                            </Nav.Link>
                          ) : null}
                          {item.sub_category && item.sub_category.length > 0 ? (
                            <NavDropdown className="nav-dropdown-first-mobile"
                              
                              title={item.name}
                              id="basic-nav-dropdown"
                            >
                              {item.sub_category.map((sub) => {
                                return (
                                  <React.Fragment>
                                    {sub.subCategoryItem &&
                                    sub.subCategoryItem.length == 0 ? (
                                      <NavDropdown.Item className="secon-label-menu-mobile"
                                        style={{
                                          border: "0px",
                                          fontSize: "15px",
                                          cursor:"pointer"
                                        }}
                                        onClick={()=>{
                                          props.hidemenupannel();
                                          navigate(`/categorySection/${0}/${
                                          sub.id
                                        }/${0}`)}}
                                      >
                                        {sub.name}
                                      </NavDropdown.Item>
                                    ) : (
                                      <NavDropdown className="nav-drop-second-label-mobile"
                                        title={sub.name}
                                        id="basic-nav-dropdown"
                                      >
                                        {sub.subCategoryItem &&
                                          sub.subCategoryItem.map((sub_sub) => {
                                            return (
                                              <NavDropdown.Item className="nav-dropdown-thitd-mobile"
                                              style={{cursor:"pointer"}}
                                              onClick={()=>{
                                                props.hidemenupannel();
                                                navigate(`/categorySection/${1}/${
                                                  sub_sub.itemId
                                                }/${0}`)}}
                                              >
                                                {sub_sub.name}
                                              </NavDropdown.Item>
                                            );
                                          })}
                                      </NavDropdown>
                                    )}
                                  </React.Fragment>
                                );
                              })}
                            </NavDropdown>
                          ) : null}
                        </React.Fragment>
                      );
                    })
                  : null}
              </Nav>
            </div>
            <div className="mob-menu-footer-sm-bannerdiv" onClick={()=>redirectToAppStore()}>
              <div className="row">
                <div className="col-5">
                  <img src={smmenubanner} className="sm-menubanner w-100pxl" />
                </div>
                <div className="col-7">
                  <p className="app-label-text">
                    Enjoy the best shopping Experience!
                  </p>
                  <button className="primary-button min-same-width mb-3" onClick={()=>redirectToAppStore()}>
                    Get Girlco App
                  </button>
                </div>
              </div>
            </div>
            <div className="hamberger-footer">
              <div className="header-bottom-rightcontact-section text-right ">
                <a href="#" target="_blank">
                  <i class="fa fa-facebook" aria-hidden="true"></i>
                </a>
                <a href="#" target="_blank">
                  <i class="fa fa-instagram" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-none d-md-inline-block align-top gn-style text-center">
          <div className="megamenu-section">
            <div className="megamenu-section-inner">
              <ul>
                {props.CategoryList && props.CategoryList.length > 0
                  ? props.CategoryList.map((item, i) => {
                      return (
                        <React.Fragment>
                          {i < 4 ? (
                            <li>
                              {item.sub_category &&
                              item.sub_category.length == 0 ? (
                                <a className="only-mega-noarrow"
                                style={{cursor:"pointer"}}
                                onClick={()=>navigate(`/categorySection/${3}/${item.id}/${0}`)}
                                >
                                  {item.name}
                                </a>
                              ) : null}

                              {item.sub_category &&
                              item.sub_category.length > 0 ? (
                                <li className="megamenu-item">
                                  <a className="main-megamenu" href="#">
                                    {item.name}{" "}
                                    {/* <i className="fa fa-chevron-down mega-arrow"></i> */}
                                    <svg
                                      className="mega-arrow-svg"
                                      xmlns="http://www.w3.org/2000/svg"
                                      data-name="Layer 1"
                                      viewBox="0 0 64 64"
                                    >
                                      <line
                                        x1="2.13"
                                        x2="33.38"
                                        y1="16.38"
                                        y2="47.62"
                                        fill="none"
                                        stroke="#010101"
                                        stroke-miterlimit="10"
                                        stroke-width="6"
                                      />
                                      <line
                                        x1="61.87"
                                        x2="30.62"
                                        y1="16.38"
                                        y2="47.62"
                                        fill="none"
                                        stroke="#010101"
                                        stroke-miterlimit="10"
                                        stroke-width="6"
                                      />
                                    </svg>
                                  </a>
                                  <div className="mega-menu-hoverfull">
                                    <Container>
                                      <div className="all-megamenu-list-div">
                                        <div className="main-flex-menulist">
                                          <div className="row">
                                            <div className="col-md-9">
                                              <div className="row">
                                                {item.sub_category.map(
                                                  (sub,index) => {
                                                    return (
                                                      <div className="col-md-3 main-flex-eachdiv">
                                                        {sub.subCategoryItem &&
                                                        sub.subCategoryItem
                                                          .length == 0 ? (
                                                          <h3 className="category_without_item">
                                                            <a
                                                              style={{cursor:"pointer"}}
                                                              onClick={()=>navigate(`/categorySection/${0}/${
                                                                sub.id
                                                              }/${0}`)}
                                                            >
                                                              {sub.name}
                                                            </a>
                                                          </h3>
                                                        ) : (
                                                          <h3>{sub.name}</h3>
                                                        )}
                                                        <ul>
                                                          {sub.subCategoryItem &&
                                                            sub.subCategoryItem.map(
                                                              (sub_sub) => {
                                                                return (
                                                                  <li
                                                                    style={{
                                                                      marginLeft:
                                                                        "0px",
                                                                    }}
                                                                    onMouseOver={()=>setItemImage(sub_sub.fullImagepath)}
                                                                    onMouseLeave={()=>setItemImage(null)}
                                                                  >
                                                                    <a
                                                                      style={{cursor:"pointer"}}
                                                                      onClick={()=>navigate(`/categorySection/${1}/${
                                                                        sub_sub.itemId
                                                                      }/${0}`)}
                                                                    >
                                                                      {
                                                                        sub_sub.name
                                                                      }
                                                                    </a>
                                                                  </li>
                                                                );
                                                              }
                                                            )}
                                                        </ul>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                            {itemImage?
                                            <div className="col-md-3">
                                              <img src={itemImage} className="w-100-hcontrol"/>
                                            </div>:''}
                                          </div>
                                        </div>
                                      </div>
                                    </Container>
                                  </div>
                                </li>
                              ) : null}
                            </li>
                          ) : null}
                        </React.Fragment>
                      );
                    })
                  : null}
              </ul>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  companyid: state.HomeData.companyid,
  CategoryList: state.CategoryData.categoryList,
  menuState: state.CategoryData.menuState,
  isMobileMenu: state.CategoryData.isMobileMenu,
});

export default connect(mapStateToProps, { getCategoryList, selectCategory })(
  MegaCategoryMenu1
);
