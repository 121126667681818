import React,{useState} from 'react';
import { connect } from 'react-redux';
import { to2Decimal, formatINR } from '../../utils/CommonUtil';
//import ImageUtil from '../../utils/ImageUtil';
import {previewCart,getCartPrice,getCart} from '../../actions/CartAction';
import cartIcon from '../../assets/img/bag.png';
import { useNavigate } from 'react-router-dom';
import Cart from './Cart';
import PreviewItemList from '../../components/cartpreview/PreviewItemList';
import { Container } from 'react-bootstrap';
import CartItem from '../../components/cartpreview/CartPreviewItem';
import EmptyCart from "../EmptyCart";
import PhoneNumberAdd from "../PhoneNumberAdd";
import {setLoginModal} from '../../actions/AuthAction';



const CartView = (props) => {
    const navigate = useNavigate();
    const [stateData, setState] = useState("");
    //const [popupUpenClose, setPopupUpenClose] = useState(false);
    const onCartClicked = () => {
        if(props.authenticated)
        {
            console.log('props.loginData.data.phone',props.loginData.data.phone)
            console.log('props.updatedUserPhoneNumber',props.updatedUserPhoneNumber)
        if((props.loginData && props.loginData.data && props.loginData.data.phone !="") || props.updatedUserPhoneNumber !="")
         {
            console.log('weweweweweew')
            if(props.loginData && props.loginData.data && props.loginData.data.customerId !="")
            props.getCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
            navigate("/Cart");
         }
         else
            {
                setState("PhoneReInsert");
               
            }
        }
        else
        {
            props.setLoginModal(true);
        }
    }
    const closeAction = () => {
        setState("");
    }
    return (
        <div className="row">
               
            <div className="cart">
                <div className={props.deliverablePin ? "cart-icon cursor d-inline-block cart-icon-blank-notification" : "cart-icon cursor d-inline-block"} onClick={onCartClicked}>
                    
                    {/* <img
                        className={props.cartBounce ? "tada" : " "}
                        src={cartIcon}
                        alt="Cart" /> */}
                        {/* <svg className={props.cartBounce ? "tada fa fa-shopping-bag commclass" : " fa fa-shopping-bag commclass"} viewBox="0 0 24 24"><path fill="#3E4152" fill-rule="evenodd" d="M4.012 20.718L5.246 7.314H7.27v1.763a.733.733 0 101.466 0V7.314h6.528v1.763a.733.733 0 001.466 0V7.314h2.024l1.234 13.404H4.012zM12 3.282c1.56 0 2.865 1.1 3.187 2.565H8.813A3.268 3.268 0 0112 3.282zm8.15 3.228a.733.733 0 00-.73-.663h-2.747A4.734 4.734 0 0012 1.816a4.734 4.734 0 00-4.673 4.03H4.58a.733.733 0 00-.73.664L2.475 21.38a.734.734 0 00.73.804h17.59a.733.733 0 00.73-.803L20.15 6.51z"></path></svg> */}
                        {/* <i className={props.cartBounce ? "tada fa fa-shopping-basket commclass" : " fa fa-shopping-basket commclass"} aria-hidden="true"></i> */}

                       
                        <svg className={props.cartBounce ? "tada fa fa-shopping-basket commclass" : " fa fa-shopping-basket commclass"} xmlns="http://www.w3.org/2000/svg" height="24" width="24"><path d="M5.8 20.7q-.65 0-1.075-.425Q4.3 19.85 4.3 19.2V8.8q0-.65.425-1.075Q5.15 7.3 5.8 7.3h2.5q0-1.55 1.075-2.625T12 3.6q1.55 0 2.625 1.075T15.7 7.3h2.5q.65 0 1.075.425.425.425.425 1.075v10.4q0 .65-.425 1.075-.425.425-1.075.425Zm0-.7h12.4q.3 0 .55-.25.25-.25.25-.55V8.8q0-.3-.25-.55Q18.5 8 18.2 8H5.8q-.3 0-.55.25Q5 8.5 5 8.8v10.4q0 .3.25.55.25.25.55.25Zm6.2-7.3q1.55 0 2.625-1.075T15.7 9H15q0 1.25-.875 2.125T12 12q-1.25 0-2.125-.875T9 9h-.7q0 1.55 1.075 2.625T12 12.7ZM9 7.3h6q0-1.25-.875-2.125T12 4.3q-1.25 0-2.125.875T9 7.3ZM5 20V8 20Z"/></svg>
                        {/* <i className={props.cartBounce ? "tada fa fa-shopping-bag" : " fa fa-shopping-bag"}></i> */}
                       
                    {props.totalItems ? (<span className="cart-count">{props.totalItems}</span>) : ("")} 
                </div> 
                <div>
                {(stateData == "PhoneReInsert") ? (
                <PhoneNumberAdd showValue={true} closeAction={closeAction}/>
                    ) : null}</div>
            </div>
        </div>
    )
}


const mapStateToProps = state => ({
    totalItems:state.CartData.totalItems,
    totalAmount:state.CartData.totalAmount,
    cartBounce:state.CartData.cartBounce,
    deliverablePin: state.LocationData.deliverablePin,
	companyid:state.HomeData.companyid,
	cartList:state.CartData.cartList,
    cartItems: state.CartData.cartList,
    loginData: state.AuthData.loginData,
    authenticated: state.AuthData.authenticated,
    updatedUserPhoneNumber:state.AuthData.updatedUserPhoneNumber
  })

  export default connect(mapStateToProps, { setLoginModal,previewCart,getCartPrice,getCart })(CartView);