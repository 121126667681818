import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { sendOtp, clearOtpStatus } from '../../actions/UserAction';
import {convertTime} from '../../utils/CommonUtil';

const ResendOtp = props => {

    const [seconds, setSeconds] = useState(300);
    const [isActive, setIsActive] = useState(false);

    const startCountDown = (time) => {
        setSeconds(time);
        setIsActive(true);
    }

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            if(seconds === 0) {
                setIsActive(!isActive);
            } 
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    useEffect(() => {
        if(props.otpSendStatus && !isNaN(props.otpSendStatus.time)){
            startCountDown(props.otpSendStatus.time*60); //value converted to sec    
        }
    },[props.otpSendStatus]);

    const resendHandler = () => {
        props.clearOtpStatus();
        props.sendOtp({companyid:props.companyid,phone:props.phone})
    }

    return (
        <React.Fragment>
            <div className="w-100 text-left">
            <button className="btn btn-link text-danger pl-0" disabled={isActive} onClick={resendHandler}>Resend OTP</button>
           {isActive ? convertTime(seconds) : null}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    otpSendStatus:state.UserData.otpSendStatus,
    phone:state.UserData.phone,
    companyid: state.HomeData.companyid
})

export default connect(mapStateToProps, { sendOtp, clearOtpStatus }) (ResendOtp);