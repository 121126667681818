import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';
import GPayButton from 'react-google-pay-button';
import { Card, Row, Col, Nav, Form,Modal } from 'react-bootstrap';
import { paymentMethods, loadPaymentDataHandler } from '../../utils/GPayGateway';
import PromoModal from '../../components/checkout/PromoModal';
import {getCart} from '../../actions/CartAction';
import { to2Decimal, formatINR, formatSymbolFromCurrency, hasProps } from '../../utils/CommonUtil';
import { selectPlaceOrderJSON, placeOrder, razorPay1func, razorpaysuccess_func, razorpayfailure_func,fetchPaymentStatus, setRazorPayStatus, setModal, placeOrderCash, set_paymentmode, savePayment, setSelectedvoucher,initiatePhonePe,cleanDiscountD,setAcceptTerms } from '../../actions/CheckoutAction';
import { setLogin, set_phoneNumber, set_password, set_authenticated, setMsgModal } from '../../actions/AuthAction';
import { Global } from '../../utils/Env';
import { set_phone, setValidateOtp,setProfoption } from '../../actions/UserAction';
import MsgModal from '../../container/MsgModal';
import Info from '../Info';
import { merchantId, saltkey,saltindex } from "../../utils/CommonUtil";
import { useNavigate } from 'react-router';
import {config} from '../../config';


const Payment = (props) => {
	const [paymentMethod, setPaymentMethod] = useState(true);
	const [cash, setCash] = useState(true);
	const [razorpay, setRazorpay] = useState(false);
	const [phonePay, setphonePay] = useState(false);
	const [gpay, setgpay] = useState(false);
	const [paymentUuid,setPaymentUuid]=useState(null);
	const [paymentLoaderModal,setPaymentLoaderModal]=useState(false);
	const [apiInterval,setApiInterval]=useState(null);
	const [timeMin,setTimeMin]=useState(0);

	const navigate=useNavigate();

	useEffect(() => {
		props.setSelectedvoucher({});
	}, [props.paymentCard]);
	useEffect(()=>{
		if(props.companyPaymentMethod.length>0)
		{
			let temp=props.companyPaymentMethod.filter((item)=>item.default==true);
			setPaymentMethod(temp[0]);
			props.set_paymentmode(temp[0].name);
		}

	},[props.companyPaymentMethod])

	useEffect(()=>{
		props.cleanDiscountD();
		props.setAcceptTerms(false);
	},[]);

	const profile = () => {
		props.setProfoption("fourth");
		navigate("/profile");
	  };
	  const callCart=()=>{
            props.getCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
	  }

	const info_content = <><p>can claim to return within 7 days of receipt of the order </p>&nbsp;<p>refund will be initiated only on receipt of the order and subject to the terms and conditions</p>&nbsp;<p>The product must be returned in its original condition along with the invoice, all labels, tags should be present in the garments </p>&nbsp;<p>Any garment sought to be returned or exchanged, should be unworn or unwashed. Garments that are found to be worn or damaged or soiled will not be accepted for return or exchange</p>&nbsp;<p>Please note an order can only cancelled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained.</p>&nbsp;<p>As part of usual business practice, We reserves the right to cancel the order and refund the entire amount back to you, if order has not been approved / processed by us.</p></>
	const onOrderClicked = () => {
			props.placeOrderCash(props.placeOrderData);
	}
	const terms = () => {
		props.setMsgModal({ "title": "Terms & Conditions", "message": props.termsandcondition, "show": true });
	}
	const razorpay_clicked = (e) => {
		//console.log(e);
		props.set_paymentmode("razorpay");
			setphonePay(false);
			setCash(false);
			setgpay(false);
			setRazorpay(true);
	}
	const phonePay_clicked = (e) => {
		props.set_paymentmode("PhonePay");
			setphonePay(true);
			setCash(false);
			setgpay(false);
			setRazorpay(false);
	}
	const cod_clicked = (e) => {
		props.set_paymentmode("COD");
		setCash(true);
		setphonePay(false);
		setgpay(false);
		setRazorpay(false);
	} 
	const gPay_clicked = (e) => {
		props.set_paymentmode("GPAY");
		setCash(false);
		setphonePay(false);
		setgpay(true);
		setRazorpay(false);
	}
	useEffect(()=>{
		if(props.fetchedPaymentStatusData.transactionId)
		{
		if(props.fetchedPaymentStatusData.isPaymentInProcess==false || (timeMin+10)==180)
		{
		if((timeMin+10)==180 || props.fetchedPaymentStatusData.isPaymentSuccess || props.fetchedPaymentStatusData.isPaymentFailed || props.fetchedPaymentStatusData.isPaymentCancelled)
		{
			setApiInterval(null);
			setPaymentLoaderModal(false);
			clearInterval(apiInterval);
			setPaymentUuid(null);
			profile();
			setTimeMin(null);
			callCart();

		}
		}
		setTimeMin(timeMin+10);
		}

	},[props.fetchedPaymentStatusData])
	Global.callback.placeordercash_onComplete = () => {
		if (typeof props.order.orderId != "undefined" && props.order.orderId != "" && typeof props.order.orderNo != "undefined" && props.order.orderno != "")
		{	
			if(paymentMethod.paymentGatewayId==0)
			props.setRazorPayStatus({ "status": true, "message": "Your order will be delivered shortly" });
			else
			{
				let uuidValue=uuid();
				console.log(uuidValue);
				setPaymentUuid(uuidValue);
				window.open(`${config.app.API_URL.toLowerCase().replace('/frontend/','')}/Company/ProcessPayment?transId=${uuidValue}&orderId=${props.order.orderId}&companyId=${props.companyid}&paymentGateWayId=${paymentMethod.paymentGatewayId}&paymentAmount=${props.order.order.grandtotalamt}&callFrom=WebSite`, "","width=1000,height=700").focus();
				setPaymentLoaderModal(true);
				props.setModal(false);
				setApiInterval(setInterval(() => props.fetchPaymentStatus({"TransactionId":uuidValue}), 10000));
			}
			 {/*else if (phonePay)
			 {
				 props.initiatePhonePe({"CompanyId":props.companyid,
				 "OrderId":props.order.orderId,
				 "PaymentGatewayId":2,
				 "PaymentAmount":props.placeOrderData.order.grandtotalamt,
				 "BaseURL":window.location.origin.concat('/')
				})
				props.setModal(false);
			 }*/}

		}
		else
			props.setRazorPayStatus({ "status": false, "message": "The order has failed. Please try again later on" });
		if (typeof props.loginData.data == "undefined" || props.loginData.data == null) {
			//console.log(props.order.loginInfo);
			let obj = {
				"status": true,
				"message": "",
				"data": JSON.parse(JSON.stringify(props.order.loginInfo))
			}
			obj.data["customerId"] = props.order.customerId;
			props.setLogin(obj);
			props.set_authenticated(true);
			props.set_phoneNumber('');
			props.set_password('');
			props.set_phone(props.order.loginInfo.phone);
			props.setValidateOtp({ "status": 1, "message": "Success", "valid": true });
		}

	}
	Global.callback.initiatephonepe_onComplete = () => {
		window.location.href=props.phonePeData.redirectURL;
	}
	const onErrorLoginClose = () => {
		props.setMsgModal({
			"title": "",
			"message": "",
			"show": false
		});
	}
	
	Global.callback.placeordercon_onComplete = () => {
		//console.log( props.loginData.data);
		if (typeof props.loginData.data == "undefined" || props.loginData.data == null) {
			//console.log(props.order.loginInfo);
			let obj = {
				"status": true,
				"message": "",
				"data": JSON.parse(JSON.stringify(props.order.loginInfo))
			}
			obj.data["customerId"] = props.order.customerId;
			props.setLogin(obj);
			props.set_authenticated(true);
			props.set_phoneNumber('');
			props.set_password('');
			props.set_phone(props.order.loginInfo.phone);
			props.setValidateOtp({ "status": 1, "message": "Success", "valid": true });
		}
		props.setModal(false);
		let order_data = JSON.parse(JSON.stringify(props.placeOrderData));
		order_data["OrderId"] = props.order.orderId;
		order_data["OrderNo"] = props.order.orderNo;
		order_data.customer.fname = (props.authenticated) ? props.loginData.data.firstName : ((props.order.loginInfo != null) ? props.order.loginInfo.firstName : order_data.customer.fname);
		order_data.customer.mname = (props.authenticated) ? props.loginData.data.middleName : ((props.order.loginInfo != null) ? props.order.loginInfo.middleName : order_data.customer.mname);
		order_data.customer.lname = (props.authenticated) ? props.loginData.data.lastName : ((props.order.loginInfo != null) ? props.order.loginInfo.lastName : order_data.customer.lname);
		props.razorPay1func(order_data);
	}
	Global.callback.razorpay1_onComplete = () => {
		props.setModal(false);
		//console.log(props.razorpay1);
		let options = {
			"companyId": props.razorpay1.companyId,
			"invoiceId": props.razorpay1.invoiceId,
			"razoyPaymentDetailId": props.razorpay1.razoyPaymentDetailId,
			"invoiceNumber": props.razorpay1.invoiceNumber,
			"razorPaymentTransactionId": props.razorpay1.razorPaymentTransactionId,
			"key": props.razorpay1.key,
			"secret": props.razorpay1.secret,
			"amount": props.razorpay1.amount,
			"currency": props.razorpay1.currency,
			"name": props.razorpay1.name,
			"description": props.razorpay1.description,
			"image": props.razorpay1.image,
			"order_id": props.razorpay1.order_id,
			"prefill": props.razorpay1.prefill,
			"notes": props.razorpay1.notes,
			"theme": props.razorpay1.theme,
			"subscription_id": props.razorpay1.subscription_id,
			"subscription_card_change": props.razorpay1.subscription_card_change,
			"recurring": props.razorpay1.recurring,
			"callback_url": props.razorpay1.callback_url,
			"redirect": props.razorpay1.redirect,
			"customer_id": props.razorpay1.customer_id,
			"timeout": props.razorpay1.timeout,
			"remember_customer": props.razorpay1.remember_customer,
			"readonlyOption": props.razorpay1.readonlyOption,
			"send_sms_hash": props.razorpay1.send_sms_hash,
			"allow_rotation": props.razorpay1.allow_rotation,
			"modal": {
				"escape": props.razorpay1.razorModal.escape,
				"confirm_close": props.razorpay1.razorModal.confirm_close,
				"ondismiss": function () {
					let failure_response = { "CompanyId": props.razorpay1.companyId, "key": props.razorpay1.key, "secret": props.razorpay1.secret, "RazorPaymentTransactionId": props.razorpay1.razorPaymentTransactionId, "RazorpayOrderId": '', "RazorpayPaymentId": '' };
					props.razorpayfailure_func(failure_response);
				}
			},
			"handler": function (response) {
				let success_response = { "CompanyId": props.razorpay1.companyId, "key": props.razorpay1.key, "secret": props.razorpay1.secret, "RazorPaymentTransactionId": props.razorpay1.razorPaymentTransactionId, "RazorpayOrderId": response.razorpay_order_id, "RazorpayPaymentId": response.razorpay_payment_id };
				props.razorpaysuccess_func(success_response);
			}
		}
		var rzp1 = new window.Razorpay(options);
		rzp1.on('payment.failed', function (response) {
			let failure_response = { "CompanyId": props.companyid, "key": props.razorpay1.key, "secret": props.razorpay1.secret, "RazorPaymentTransactionId": props.razorpay1.razorPaymentTransactionId, "RazorpayOrderId": response.error.metadata.order_id, "RazorpayPaymentId": response.error.metadata.payment_id };

			props.razorpaysuccess_func(failure_response);
		});
		rzp1.open();
	}

	Global.callback.razorpaysuccess_onComplete = () => {
		props.savePayment({ "CompanyId": props.placeOrderData.companyid, "UserId": props.placeOrderData.customer.userid, "CustomerId": props.order.customerId, "OrderId": props.order.orderId, "status": props.razorpaysuccess.status, "message": props.razorpaysuccess.message, "paymentMode": props.razorpaysuccess.paymentMode, "paymentResponse": props.razorpaysuccess.paymentResponse, "cardResponse": props.razorpaysuccess.cardResponse });
		if (props.razorpaysuccess.status == 'captured')
			props.setRazorPayStatus({ "status": true, "message": "<b>The Payment has been successful. Your order will be delivered shortly</b>" });
		else
			props.setRazorPayStatus({ "status": false, "message": "<b>The Payment has failed. Your order will become cancelled. Please try to place the order again Any money deducted will be returned back</b>" });
		props.setModal(true);
	}
	Global.callback.razorpayfailure_onComplete = () => {
		//console.log(props.razorpayfailure);
		props.savePayment({ "CompanyId": props.placeOrderData.companyid, "UserId": props.placeOrderData.customer.userid, "CustomerId": props.order.customerId, "OrderId": props.order.orderId, "status": "failed", "message": "Payment was dismissed", "paymentMode": null, "paymentResponse": null, "cardResponse": null });
		props.setRazorPayStatus({ "status": false, "message": "<b>The Payment has failed. Your order will become cancelled. Please try to place the order again Any money deducted will be returned back</b>" });
		props.setModal(true);
	}

	const evaluateVoucherMsg = () => {
		//console.log("evaluateVoucherMsg called");
		return props.selected_voucher.valid != null && props.selected_voucher.valid != ""
			? <div className="alert alert-warning" role="alert"><p>{props.selected_voucher.msg}</p></div>
			: null
	}
	

	return (
		<div>
		<Card className="mb-2">
			{/* <div className="row pt-3">
				<Col>
				</Col>
			</div> */}
			{/* <Card.Body style={{ display: props.paymentCard ? "block" : "none" }}> */}

			<div className='row'>
				<div className='col-md-5'>
					<div className='payment-method pt-3'>
						<div className="ship-add-head">
							Select Payment Method
							{/* <span className="fs-13-f-n float-right text-muted">{props.checkoutDetails.date}</span> */}
						</div>
						<div>
						{/*props.companyDetailsCurrency.isPhonePeActive?<div className="form-check form-check-hcont">
  <input className="form-check-input" type="radio" name="flexRadioDefault" onClick={()=>phonePay_clicked()} checked={phonePay} />
  <label className="form-check-label" for="flexRadioDefault1">
    <img className='im-w-55' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRDPp9w0VVVKMq3vn5KFq9NzB3zPjuqeW_1b7Se0z2CW49JSwetVX6wzbvHH30TxtN2DMY&usqp=CAU" />Pay with Phonepe
  </label>
		</div>:''*/}
{/* Razor pay disabled
<div className="form-check form-check-hcont">
  <input className="form-check-input" type="radio" name="flexRadioDefault" onClick={() => razorpay_clicked()} checked={cash} />
  <label className="form-check-label fsize13" for="flexRadioDefault2">
    Pay with RazorPay
  </label>
</div>*/}
{props.companyPaymentMethod && props.companyPaymentMethod.length>0?
props.companyPaymentMethod.map((item)=>{
	return(
<div className="form-check form-check-hcont">
  <input className="form-check-input" type="radio" name="flexRadioDefault" onClick={() => {setPaymentMethod(item);props.set_paymentmode(item.name)}} checked={paymentMethod.paymentGatewayId==item.paymentGatewayId} />
  <label style={{cursor:"pointer"}} className="form-check-label fsize13" for="flexRadioDefault2"  onClick={() => {setPaymentMethod(item);props.set_paymentmode(item.name)}}>
    {item.name}
  </label>
</div>);
}):null}
{/* <div className="form-check form-check-hcont">
  <input className="form-check-input" type="radio" name="flexRadioDefault" onClick={() => gPay_clicked()} checked={gpay} />
  <label className="form-check-label" for="flexRadioDefault2">
      G-PAY
  </label>
</div> */}
						</div>
					</div>
				</div>
				<div className='col-md-7 p-0'>
					<Card.Body className='pay-card-body' style={{ display: true ? "block" : "none" }}>

						<Card className="p-2">
							<div><span className="fs-13-f-n">Total Amount </span><b className=" fs-14 float-right">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.promoApplied?props.checkoutDetails_backup.totalamt:props.checkoutDetails.totalamt))}</b></div>
							<div>
								<span className="fs-13-f-n">
									Total Discount Amount </span>
								<b className=" fs-14 float-right">
									{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.promoApplied?props.checkoutDetails.vouchers && props.checkoutDetails.vouchers.isValid?props.checkoutDetails_backup.totalamt-props.checkoutDetails.totalamt:0:0))}
								</b>
								<span className="fs-14">
									({props.promoApplied?props.checkoutDetails.vouchers && props.checkoutDetails.vouchers.isValid?props.checkoutDetails.vouchers.discountAmount:0:0}{props.promoApplied?props.checkoutDetails.vouchers && props.checkoutDetails.vouchers.isValid?props.checkoutDetails.vouchers  && props.checkoutDetails.vouchers.isPercentage?'%':'':'':''})
								</span>
							</div>
							{props.promoApplied?
							<div><span className="fs-13-f-n">Net Amount</span><b className=" fs-14 float-right">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totalamt))}</b></div>:''}
							<div><span className="fs-13-f-n">Total Tax Amount </span><b className=" fs-14 float-right">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.totaltaxamt))}</b></div>

							<div><span className="fs-13-f-n">Delivery Charges </span><b className=" fs-14 float-right">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.deliverychargeamt))}</b></div>
							<div className="grand-total"><span className="fs-13-f-n totaltd">Grand Total Amount</span><b className=" fs-14 float-right">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), to2Decimal(props.checkoutDetails.grandtotalamt))}</b></div>
							{props.checkoutDetails.deliverytxt && <span className="fs-13-f-n">{props.checkoutDetails.deliverytxt}</span>}
						</Card>

						<div className="text-right mt-3 pr-0">
							<PromoModal />
						</div>
						<div className='accept-label'><label className='accept-labelmain'><input type='checkbox' checked={props.acceptTerms} onClick={()=>props.setAcceptTerms(!props.acceptTerms)} /> I accept <a href="" target='_blank'> Terms and conditions </a>, <a href="" target='_blank'>Privacy policy</a> and <a href="" target='_blank'>Return policy</a><span className='ckmark'></span></label></div>
						{hasProps(props.selected_voucher, 'valid') ?
							evaluateVoucherMsg() : null
						}


						{gpay ? <div className="text-right"><GPayButton 

							totalPriceStatus={'FINAL'}
							totalPrice={`${props.checkoutDetails.grandtotalamt}`}
							currencyCode={'INR'}
							countryCode={'IN'}
							allowedPaymentMethods={paymentMethods()}
							development={true}
							type={'short'}
							merchantInfo={{
								merchantName: '<YOUR MERCHANT NAME>',
								// A Google merchant identifier issued after your website is approved by Google ✅
								merchantId: '<YOUR MERCHANT ID>'
							}}
							onLoadPaymentData={loadPaymentDataHandler}

						/></div> : <div className="text-right mt-4">
							<button className="btn btn-secondary bgpinbutton position-relative min-same-widthxs" disabled={!props.acceptTerms} onClick={onOrderClicked}>Place Order</button></div>}
						<div className="mt-3"><small className='text-muted'><i>Payment Instructions will be provided to you over Phone and Email</i> <Info title="Return and Cancellation Policy" content={info_content} /></small></div>
						<div className="mt-4">
                      <div className="shield-did d-inline-block"><i class="fa fa-shield" aria-hidden="true"></i> </div><div className="d-inline-block shild-txt">Safe and Secure Payments.Easy returns.100% Authentic products.</div>
                    </div>
						{props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={(props.modal.message == '') ? 'Error' : props.modal.message} closeAction={onErrorLoginClose} />}

					</Card.Body>
				</div>
			</div>


		</Card>

		{paymentLoaderModal?
		    <Modal show={true}>
			<Modal.Header>
			  <Modal.Title>Payment Processing</Modal.Title>
			</Modal.Header>
	  
			<Modal.Body>
			  <p>Your Payment is being Processed please wait.</p>
			</Modal.Body>
		  </Modal>:null}
		  </div>
	)
}

const mapStateToProps = state => ({
	companyid: state.HomeData.companyid,
	pincode: state.LocationData.pin,
	totalAmount: state.CartData.totalAmount,
	cartList: state.CartData.cartList,
	companyPaymentMethod:state.HomeData.companyPaymentMethod,
	checkoutDetails: state.CheckoutData.checkoutDetails,
	discountDetails: state.CheckoutData.discountDetails,
	selected_voucher: state.CheckoutData.selected_voucher,
	placeOrderData: selectPlaceOrderJSON(state),
	razorpay1: state.CheckoutData.razorpay1,
	razorpaysuccess: state.CheckoutData.razorpaysuccess,
	razorpayfailure: state.CheckoutData.razorpayfailure,
	order: state.CheckoutData.order,
	loginData: state.AuthData.loginData,
	termsandcondition: state.HomeData.termsncondnord,
	authenticated: state.AuthData.authenticated,
	companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
	phonePeData: state.CheckoutData.phonePeData,
	fetchedPaymentStatusData:state.CheckoutData.fetchedPaymentStatusData,
	promoApplied:state.CheckoutData.promoApplied,
	checkoutDetails_backup:state.CheckoutData.checkoutDetails_backup,
	acceptTerms:state.CheckoutData.acceptTerms
})
export default connect(mapStateToProps, {setAcceptTerms, placeOrder,getCart,setProfoption,cleanDiscountD,fetchPaymentStatus, razorPay1func, razorpaysuccess_func, razorpayfailure_func, setRazorPayStatus, setModal, placeOrderCash, set_paymentmode, savePayment, setSelectedvoucher, setLogin, set_phoneNumber, set_password, set_phone, setValidateOtp, set_authenticated, setMsgModal,initiatePhonePe })(Payment);
