import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Card, Form, Collapse } from 'react-bootstrap';
import { connect } from 'react-redux';
import { set_phone, getCustomerDetails, validateOtp, setValidateOtp, sendOtp, clearOtpStatus,set_phoneCountryCode,set_phoneCode } from '../../actions/UserAction';
import ResendOtp from './ResendOtp';
import { Global } from '../../utils/Env';
import PhoneInput,{ getCountryCallingCode  } from 'react-phone-number-input'
import { login,verifyLoginwithotp, signup, set_phoneNumber, set_password,  set_authenticated, setMsgModal,forgotPassword,sendForgotPasswordOtp,validateForgotPasswordOtp,set_login_head,WalletInfo } from '../../actions/AuthAction';
import { getCart, saveCart } from '../../actions/CartAction';
import { clearLocation } from '../../actions/LocationAction';
import { useNavigate } from 'react-router-dom';

const PhoneValidate = (props) => {
    const navigate = useNavigate();
    const [state, setState] = useState("phone");
    const [phonenumber, setPhonenumber] = useState(props.phone);
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [signinDis,setSignInDis]=useState(false);
    
    const [otpError, setOtpError] = useState(false);

    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)
    const ref5 = useRef(null)
    
    const [countryCodeData, setCountryCode] = useState(props.countryCode==null || props.countryCode=='' || props.countryCode==undefined?'IN':props.countryCode.toUpperCase());
    const [phoneCodeValue, setPhoneCode] = useState(props.phoneCode==null || props.phoneCode=='' || props.phoneCode==undefined?'+91':props.phoneCode);
    const [codeWithPhonenumber, setPhone] = useState((props.phoneCode=='' || props.phoneCode==undefined || props.phoneCode==null)?'+91'.concat(props.phone):props.phoneCode+props.phone);
    const redirectToLogin = (typeof props.redirectToLogin=="undefined")?false:props.redirectToLogin;

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
    let secondaryColor=props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:'';
    let tertiaryColor=props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:'';
	
    

    useEffect(()=>{
        setCountryCode(props.countryCode==null || props.countryCode=='' || props.countryCode==undefined?'IN':props.countryCode.toUpperCase());
        setPhoneCode(props.phoneCode==null || props.phoneCode=='' || props.phoneCode==undefined?'+91':props.phoneCode);

    },[props.phoneCode,props.countryCode])
	
	

   
 
    useEffect(() => {
        if(props.phone && props.valid_otp){
            props.setPhnCard(false);
            props.setAddressCard(true);
        }
    }, [props.phone, props.valid_otp]);

   

    Global.callback.sendOtp_onComplete = () => {
        setSignInDis(false);
        if(props.otpSendStatus.status)
        setState('otp');
        else
        props.setMsgModal({
            "title": "Error in Phone",
            "message": props.otpSendStatus.message,
            "show": true
        });
    }

    const handleClick = () => {
        if (state === "phone")
            reqOtpClicked();
        else
            verifyOtpClicked();
    }

    const reqOtpClicked = () => {
        if (phonenumber.length >=8) {
            props.set_phone(phonenumber);
            props.sendOtp({companyid:props.companyid,phone:phonenumber})
            setSignInDis(true);
        }
    }
//props.companyid
    const verifyOtpClicked = () => {
        if (otp.length === 4) {
            setOtp(["", "", "", ""]); // clear otp fields
            console.log('Akka',redirectToLogin);
            props.verifyLoginwithotp({"CompanyId":props.companyid,"Phone":phonenumber, "OTP":otp.join('')});
            setSignInDis(true);
        }
    }
          /** @function
 * @name login_onComplete */
           Global.callback.login_onComplete = () => {
            setSignInDis(false);
            if (props.loginData.data != null) {
                props.set_authenticated(true);
                props.set_phoneNumber('');
                props.set_password('');
                props.set_phone(props.loginData.data.phone);
                props.setValidateOtp({ "status": 1, "message": "Success", "valid": true });
                if (props.loginData.data.customerId != '' && props.loginData.data.customerId != null && props.loginData.data.userType == 'b2b') {
                    props.WalletInfo({ "CustomerId": parseInt(props.loginData.data.customerId) });
                }
                console.log('OnComplete===>',props.cartList)
                if (props.cartList !=undefined &&  props.cartList !=null && props.cartList.length > 0) {
                    let items = [];
                    props.cartList.map((item, i) => {
                        items.push({ "itemId": item.id, "itemVariantId": (item.variantid ? item.variantid : 0), "itemQty": item.quantity })
                    });
                    props.saveCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId), "userid": props.loginData.data.id, "carttItemVariant": items });
                }
                else
                    props.getCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
                props.clearLocation();
                console.log('closeeeeee====>', props.closeAction())
                props.closeAction();
                // window.location.reload(); 
                navigate("/");
            }
            else
                props.setMsgModal({
                    "title": "Error in login",
                    "message": props.loginData.message,
                    "show": true
                });
        }
        Global.callback.login_onError = () => {
            setSignInDis(false);
            props.setMsgModal({
                "title": "Error in login",
                "message": "Some Error Occurred",
                "show": true
            });
        }

    const handlePhonenumberChange = (e) => {
            setPhonenumber(e)
            setPhone(e);
        var onlyPhoneCode='';
        console.log("chanig")
       
        if(countryCodeData !='' && countryCodeData !==undefined)
        {
            console.log("test123");
        onlyPhoneCode ="+" + getCountryCallingCode(countryCodeData);  
        setPhoneCode(onlyPhoneCode);
        const onlyPhoneNumber = e?e.replace(onlyPhoneCode, ''):''
        setPhonenumber(onlyPhoneNumber);
        props.set_phoneCountryCode(countryCodeData);
        props.set_phoneCode(onlyPhoneCode);
        }
      
    }
    const handleOtpChange = (e, i) => {
        let copyOtp = [...otp]
        if (e.target.value.length <= 1) {
            copyOtp[i] = e.target.value
            setOtp(copyOtp)
        }
        if (otp[i] === "") {
            switch (i + 1) {
                case 1:
                    {
                        ref2.current.focus();
                        break
                    }
                case 2:
                    {
                        ref3.current.focus();
                        break
                    }
                case 3:
                    {
                        ref4.current.focus();
                        break
                    }
                case 4:
                    {
                        ref5.current.focus();
                        break
                    }
                default: return null
            }
        }
    }

    const checkValidity = () => {
        console.log("phonenumber",phonenumber)
        console.log("state",state);
        console.log("phonenumber.length",phonenumber.length)
        if (state == "phone") {
            if (phonenumber.length >= 8) {
                console.log("got inside")
                return false
            }
        }
        else {
            if (otp[0] !== "" && otp[1] !== "" && otp[2] !== "" && otp[3] !== "") {
                return false
            }
        }
        return true
    }
    
    const renderPhoneField = () => {
        return (<React.Fragment>
                    <Row>
                        <Col className='pl-0 pr-0'>
                        <p className="w-100 fs-14 mb-1">Enter Your Phone Number</p>
                        {/* <input className="form-control" placeholder="Phone Number" type="number" value={phonenumber} onChange={handlePhonenumberChange} /> */}
                        <PhoneInput 
                                                countrySelectProps={{ unicodeFlags: false }}
                                                countryCallingCodeEditable={false}
                                                international
                                                defaultCountry={countryCodeData}
                                                value={codeWithPhonenumber}
                                                name="phonenumber"
                                                onChange={handlePhonenumberChange} onCountryChange={setCountryCode} />
                        </Col>
                    </Row>
                </React.Fragment>)
    }

    const renderOtpField = () => {
        return (<Row>
            <p className="w-100 fs-13-f-n mb-1">Enter Your OTP</p>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref1} value={otp[0]} onChange={(e) => handleOtpChange(e, 0)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref2} value={otp[1]} onChange={(e) => handleOtpChange(e, 1)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref3} value={otp[2]} onChange={(e) => handleOtpChange(e, 2)} />
            </Col>
            <Col md="3 pl-0" xs="3">
                <input className="form-control p-0-5-tab" ref={ref4} value={otp[3]} onChange={(e) => handleOtpChange(e, 3)} />
            </Col>
            {otpError && <div className="text-danger font-12 mt-2">Error in otp... try again</div>}
            <ResendOtp />
        </Row> )
    }
	
    return (
        <Card className="mb-2">
            <Card.Header className="bgf2f2f2">
                    <i className="fa fa-check-circle text-dark f-size22" aria-hidden="true"></i> 
               
                <label className="float-right mb-0">{props.phone}</label>
            </Card.Header>
            <Card.Body style={{ display:"block" }}>
                <Row>
                    <Col md="9 pl-0 pr-0-mob">
                        {(state === "phone") ? renderPhoneField() : renderOtpField()}
                    </Col>
                    <Col md="3 text-right pr-0">
                        <p className="w-100 fs-14 mb-1">&nbsp;</p>
                        {<button style={{backgroundColor:primaryColor}} ref={ref5} onClick={handleClick} disabled={checkValidity() || signinDis} className="rounded-0 primary-button-nocolor min-same-widthxs">Next</button>}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const mapStateToProps = state => ({
    phone:state.UserData.phone,
	loginData:state.AuthData.loginData,
    customerid:state.AuthData.loginData && state.AuthData.loginData.data && state.AuthData.loginData.data.customerId?state.AuthData.loginData.data.customerId:0,
    customerDetails:state.UserData.customerDetails,
    otpSendStatus:state.UserData.otpSendStatus,
    valiDated_Otp:state.UserData.valiDated_Otp,
    valid_otp:state.UserData.valid_otp,
    companyid: state.HomeData.companyid,
	pin: state.LocationData.pin,
    countryCode:state.HomeData.servicePhoneCountryCode,
    phoneCode:state.HomeData.servicePhoneCode,
    phoneCountryCode: state.UserData.phoneCountryCode,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
	loginPhoneCode: (state.AuthData.loginData.data==null)?null:state.AuthData.loginData.data.userPhoneCode,
	loginPhoneCountryCode: (state.AuthData.loginData.data==null)?null:state.AuthData.loginData.data.userPhoneCountryCode
})

export default connect(mapStateToProps, { setMsgModal,clearLocation,getCart,saveCart,WalletInfo,set_password,set_authenticated,set_phoneNumber,verifyLoginwithotp,login,set_phone, getCustomerDetails, validateOtp, setValidateOtp, sendOtp, clearOtpStatus,set_phoneCountryCode,set_phoneCode })(PhoneValidate);  