/**
 * All APIs for the application
 */
const API = {};

API.GET_COMPANY_INFO = 'GetCompany?url=';

API.GET_IP = 'https=//ipinfo.io?token=c49d0e3562b4b7';

API.SIGN_UP = "Signup";

API.LOG_IN = "Signin";
API.WALLET_INFO ="GetCustomerWalletInfo";
API.ORDER_GENERATE="GetStatement";

API.GET_USER_ALL_DETAILS = "GetCustomerProfile";
API.CHANGE_PASSWORD="ChangeUserPassword";
API.SAVE_PROFILE="SaveCustomerProfile";
API.GET_ORDER_HISTORY="GetOrderHistory";
API.ORDER_HISTORY="GetOrderHistoryCustomer";

API.GET_FEATURE_ITEMS = 'GetFeatureItems?companyid=';

API.VALIDATE_PIN = 'ValidatePinCode';
API.GET_CATEGORY = 'GetAllCategories?CompanyId=';
API.GET_ALL_ITEMS = 'GetAllItems?companyid=';
API.GET_CUSTOMER_DETAILS = 'GetCustomer?'; //Order/GetCustomerDtl
API.GET_ITEMS_BY_VARIANT = 'GetItemsByVariantIds';
API.GET_CONFIG='/config.json';

API.SEND_OTP = 'Sendotp';
API.VALIDATE_OTP = 'Verifyotp';

API.VALIDATE_DISCOUNT = 'ValidateDiscount';
API.CHECKOUT_ORDER = 'CheckOutOrder';
API.PLACE_ORDER = 'PlaceOrder';
API.SAVE_PAYMENT = 'SavePayment';
API.GET_CART = 'GetCustomerCartItem';
API.SAVE_CART='SaveCustomerCartItem';
// API.GET_WISH='GetCustomerWishItem';
API.GET_WISH='GetWishListCategory';
API.GET_WISHLIST_ITEM='GetCustomerWishItems';
API.SAVE_WISH='SaveCustomerWishItem';
API.SAVE_WISHLIST='SaveUpdateWishListCategory';
API.DELETE_WISHITEM="DeleteCustomerWishItem";
API.DELETE_WISHLISTCATEHORY="DeleteWishListCategoryWithItems";
API.REMOVE_WISH="RemoveCustomerWishItem";
API.GET_CART_PRICE="CheckCartPrice";
API.ADD_ADDRESS="SaveAddress";
API.DELETE_ADDRESS="DeleteAddress";
API.FORGOT_PASSWORD="ForgotPassword";
API.SEND_FORGOT_PASSWORD_OTP="Sendforgetpasswordotp";
API.VERIFY_FORGOT_PASSWORD_OTP="Verifyforgetpasswordotp";
API.SAVE_RATING="SaveRating";
API.GET_RATING="GetRating";
API.ORDER_INVOICE="GetStatementInvoice";
API.GET_TEMPLATE="GetTemplate";
API.GET_SECTIONS="GetItemsFromSectionId";
API.INITIATE_PHONE_PE_PAYMENT="InitiatePhonePayPayment";
API.FETCH_PHONE_PE_STATUS="PhonePePaymentStatus";


API.P_ALLPRODUCT="GetDefaultTemplateByCompanyV2";
API.P_ALLPRODUCTFORLAZYLOAD="GetAllItemsForLazyLoad?";
API.GET_ADDITIONALINFO_BY_ITEM="GetAdditionalInfoByItem"
API.Get_ITEM_DETAIL_BY_SECTIONFOR="GetItemDetailBySectionFor";
API.SIGNIN_BY_OTHERS = "SigninByOthers";
API.UPDATECUSTOMERPHONE="UpdateCustomerPhoneNumber";
API.SEARCHITEM="SearchItems";
API.VERYFY_LOGIN_WITH_OTP = "verifyLoginwithotp";
API.GET_FAQ="GetFAQ";
API.GET_RELATEDITEM="GetRelatedItem";
API.CHECK_PAYMENT_STATUS="CheckPaymentStatus";
API.GET_DELIVERY_CHARGE="GetDeliveryCharge";
//  API.P_ALLPRODUCT="GetCustomerWishItem";
export default API
