import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import ImageUtil from '../utils/ImageUtil';
import Product from '../components/Product';
import { Container, Button, Row, Col, Nav, Tab, Form, Modal, ListGroup, } from 'react-bootstrap';
import { getWishList, saveWishList, getWishListItemById, SaveUpdateWishCategory, deleteWishItem, DeleteWishListCategoryWithItems } from '../actions/ProductsAction';
import { useNavigate, Link } from 'react-router-dom';
import { setLoginModal, set_login_head } from '../actions/AuthAction';
import WishListnull from '../container/WishListnull'
import { toast } from "react-toastify";
import { formatINR, formatSymbolFromCurrency } from '../utils/CommonUtil';
import ReactStars from "react-rating-stars-component";
import { Global } from '../utils/Env';
const WishList = props => {

    const navigate = useNavigate();
    const myArray = props.productsList;
    const myFilter = props.wishlist;
    const [show, setShow] = useState(false);
    const [option_select, setOption_select] = useState(0);
    const [createListText, setcreateListText] = useState('');
    const [wishListCategoryId, setwishListCategoryId] = useState(0);
    const [showmessagepopup, setShowmessagepopup] = useState(false);
    const messagehandleClose = () => setShowmessagepopup(false);
    const messagehandleShow = () => setShowmessagepopup(true);
    const valueChange = (event) => {
        let value = event.target.value
        setcreateListText(value)
    }
    var today = new Date();
    var dd = today.getDate()
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var date = yyyy + '-' + mm + '-' + dd;
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        if (!props.authenticated) {
            props.setLoginModal(true);
            props.set_login_head("Login");
            navigate("/");
        }
    }, []);
    useEffect(() => {
        if (props.authenticated) {
            props.getWishList({ "companyId": props.loginData.data.companyId, "customerId": parseInt(props.loginData.data.customerId) });
        }
    }, []);
    useEffect(() => {
        if (props.wishlist != "undefined" && props.wishlist != null)
            props.wishlist.slice(0, 1).map((item, id) => {
                props.getWishListItemById({ "wishListCategoryId": item.wishListCategoryId, "customerId": parseInt(props.loginData.data.customerId), "companyId": props.loginData.data.companyId });
            }
            )
        setOption_select(0);
    }, [props.wishlist])

    const getWishListItemBy = async (arg1, id) => {
        setOption_select(id)
        let obj = {
            "wishListCategoryId": arg1,
            "customerId": parseInt(props.loginData.data.customerId),
            "companyId": props.loginData.data.companyId
        }
        props.getWishListItemById(obj);
    }
    const CreateList = async (e) => {
        e.preventDefault();
        console.log("props.whistlist",props.wishlist)
        let value1 = false;
        if (createListText != null && createListText != "") {
            if (props.wishlist != "undefined" && props.wishlist != null) {
                props.wishlist.map((item, id) => {
                    if (item.wishListCategoryName.toLowerCase() == createListText.toLowerCase()) {
                        if (item.wishListCategoryId != wishListCategoryId) {
                            value1 = true;
                        }
                    }
                })
            }
            if (value1 != true) {
                let obj = {
                    "WishListCategoryId": wishListCategoryId,
                    "WishListCategoryName": createListText,
                    "IsDefault": props.wishlist ? false : true,
                    "Date": date,
                    "customerId": parseInt(props.loginData.data.customerId),
                    "companyId": props.loginData.data.companyId
                }
                props.SaveUpdateWishCategory(obj);
                setShow(false);
                if (wishListCategoryId == 0) {
                    toast.info(createListText + " Successfully Created");
                }
                else {
                    toast.info(createListText + " Successfully Updated");
                }
            }
            else {
                toast.info(createListText + " Already Exist");
                setShow(true);
            }
            setcreateListText("");
        }
        else {
            toast.info("Please enter a name for the list.");
            setShow(true);
        }
    }
    const RemoveWishItem = async (e, arg, arg1) => {
        e.preventDefault();
        let obj = {
            "CustomerWishItemId": arg,
            "customerId": parseInt(props.loginData.data.customerId),
            "companyId": props.loginData.data.companyId,
            "Date": date
        }
        props.deleteWishItem(obj);
        setwishListCategoryId(arg1);
    }
    Global.callback.getWishListItemById_onComplete = () => {
        if (props.deletewishlist == "Yes") {
             getWishListItemBy(wishListCategoryId)
            toast.info("Deleted Successful");
            setwishListCategoryId(0)
        }
        else {
            toast.info("Failed To Delete");
        }
    }
    const deletewishlistcategory = async (e, arg1) => {
        setShowmessagepopup(true)
        setwishListCategoryId(arg1);
    }
    const yesdeletewishlistcategory = async (e) => {
        let obj = {
            "wishListCategoryId": wishListCategoryId,
            "customerId": parseInt(props.loginData.data.customerId),
            "companyId": props.loginData.data.companyId
        }
        props.DeleteWishListCategoryWithItems(obj);
        setShowmessagepopup(false);
    }
    Global.callback.deletedWishlistCategory_onComplete = () => {
        if(props.wishlist != null){
        if (props.wishlist[0].returnMessage == "Yes") {
            setwishListCategoryId(0)
            toast.info("Deleted Successful");
        }
        else {
            setwishListCategoryId(0)
            toast.info("Failed To Delete");
        }
    }
}
    const updatewishlistcategory = async (e, arg, arg1) => {
        e.preventDefault();
        setShow(true);
        setwishListCategoryId(arg);
        setcreateListText(arg1);
    }
    const productsData = myArray.filter(array => myFilter.some(filter => filter.itemId === array.id)).map((product) => {
        return <Product key={product.id} product={product} wish={true} />
    });

    return (
        <React.Fragment>
            <div>
                <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton className='custom-modal-header pt-2 pb-2 text-white'>
                        <Modal.Title>Create a new list</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group className="mb-3">
                                <Form.Label>List name</Form.Label>
                                <Form.Control
                                    type="text"
                                    autoFocus
                                    name="search" value={createListText} onChange={valueChange}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="secondary-round-button min-same-width buy-now-addcart" onClick={handleClose}>
                            Close
                        </button>
                        <button className="primary-button min-same-width buy-now-addcart" onClick={(e) => CreateList(e)}>Create</button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div>
                <Modal show={showmessagepopup} onHide={messagehandleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm delete</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to delete this list?</Modal.Body>
                    <Modal.Footer>
                        <button className="secondary-round-button min-same-width buy-now-addcart" onClick={messagehandleClose}>No</button>
                        <button className="primary-button min-same-width buy-now-addcart" onClick={(e) => yesdeletewishlistcategory(e)}>Yes</button>
                    </Modal.Footer>
                </Modal>
            </div>
            <h3 className="text-center pt-4 mb-0 pb-4">Wish List </h3>
            <div className='row'>
                <div className='col-sm-3'>
                    <div className=' bg-control-wishlist'>
                        <div className='list-wishlist'>
                            <button type="button" class="third-type-buuton-with-icon" onClick={handleShow} ><i class="fa fa-plus-circle" aria-hidden="true"></i> Create New List</button>
                        </div>
                        {props.wishlist != "undefined" && props.wishlist != null ?
                            props.wishlist.length > 0 ?
                                props.wishlist.map((item, id) => {
                                    return (
                                        <>
                                            <Tab.Container key={option_select} id="left-tabs-example" defaultActiveKey={option_select}>
                                                <div className="">
                                                    <Nav variant="pills" className="flex-column custom-sidetab">
                                                        <Nav.Item className="border-bottom text-left">
                                                            <Nav.Link eventKey={id} className='nav-link-new' onClick={() => getWishListItemBy(item.wishListCategoryId, id)} >{item.wishListCategoryName}
                                                                <div className='inline-wishlistdiv' style={{ float: "right", marginRight: "20px" }}>
                                                                    <label className="position-relative hover-submenu mb-0" >
                                                                        <i class="fa fa-ellipsis-h bi bi-three-dots" aria-hidden="true" ></i>
                                                                        <label className="position-absolute mb-0 hovdropdowndiv" style={{ width: "80px" }}>
                                                                            <ListGroup>
                                                                                <ListGroup.Item className="profile-each-list">
                                                                                    <a href="#" className="text-decoration-none" onClick={(e) => updatewishlistcategory(e, item.wishListCategoryId, item.wishListCategoryName)} >
                                                                                        Update</a>
                                                                                </ListGroup.Item>
                                                                                <ListGroup.Item className="profile-each-list">
                                                                                    <a href="#" className="text-decoration-none" onClick={(e) => deletewishlistcategory(e, item.wishListCategoryId)}>
                                                                                        Delete</a>
                                                                                </ListGroup.Item>
                                                                            </ListGroup>
                                                                        </label>
                                                                    </label>
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    </Nav>
                                                </div>
                                            </Tab.Container>
                                        </>
                                    )
                                }) : null : <WishListnull />}
                    </div>
                </div>
                <div className="products-wrapper mt-0 border-none-mob col-sm-9" style={{ maxHeight: "600px", overflow: "auto" }}>
                    {props.wishlistitem != "undefined" && props.wishlistitem != null ?
                        props.wishlistitem.length > 0 ?
                            props.wishlistitem.map((item, id) => {
                                return (
                                    <React.Fragment>
                                        <Row className="cart-item border mt-0 mb-2" >
                                            <Col md="1" xs="3" className="p-0-5-tab pl-1 pr-1">
                                                <div className="chkout-main-imgpanel gg">
                                                    <a href={(`/categorySection/${2}/${item.id}/${item.variantid}`)}>
                                                        <img
                                                            className="product-image product-image-cart wishlist-product-image"
                                                            src={(item.images != null) ? (item.images[0].name ? ImageUtil.getImage(item.images[0].name) : ImageUtil.getErrorImage()) : ImageUtil.getErrorImage()}
                                                            alt={item.bbrandicon}
                                                            onError={(e) => {
                                                                e.target.onerror = null;
                                                                e.target.src = ImageUtil.getErrorImage();
                                                            }}
                                                        /></a>
                                                </div>
                                            </Col>
                                            <Col md="11 p-0" xs="9">
                                                <Col md="12" className="text-left pl-0 pl-0-mob">
                                                    <Row>
                                                        <Col md="9">
                                                            <a href={(`/categorySection/${2}/${item.id}/${item.variantid}`)} className="product-name product-name-cart product-name-cart-newpage mb-0 cursor"><b>{`${item.name} `}</b></a>
                                                            {item.color && item.color != '' ? <p className='prod-variant-text'>Color: <span className='a-text-regular col8f8f8f fsize13'>{item.color}</span></p> : ''}
                                                            {item.size && item.size != '' ? <p className='prod-variant-text'>Size: <span className='a-text-regular col8f8f8f fsize13'>{item.size}</span></p> : ''}
                                                            {item.weight && item.weight != '' ? <p className='prod-variant-text'>Weight: <span className='a-text-regular col8f8f8f fsize13'>{item.weight}</span></p> : ''}
                                                            {item.material && item.material != '' ? <p className='prod-variant-text'>Material: <span className='a-text-regular col8f8f8f fsize13'>{item.material}</span></p> : ''}
                                                            <div className='star-div'>
                                                                <ReactStars
                                                                    count={5}
                                                                    size={24}
                                                                    value={item.itemRating}
                                                                    isHalf={true}
                                                                    edit={false}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col md="3">
                                                            <p className="product-name product-name-cart mb-1 text-right text-left-mob d-inb-mob">Price per unit: <b className='wishlist-value'>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (item.price_regular))}</b></p>
                                                            <div className='text-right text-left-mob d-inb-mob' style={{ marginTop: "50px" }}>
                                                                {/* <i class="fa fa-trash-o del-color del-color-light mt-2 Remove-item-mobile" title="Remove Item" href="#" onClick={(e) => RemoveWishItem(e, item.customerWishItemId, item.wishListCategoryId)}></i> */}
                                                                {/* <button type="button" className="primary-button min-same-width buy-now-addcart " title="Remove Item" href="#" onClick={(e) => RemoveWishItem(e, item.customerWishItemId, item.wishListCategoryId)} >Remove</button> */}
                                                                <a aria-hidden="true" title="Return Item" class="ret-item" href="#" onClick={(e) => RemoveWishItem(e, item.customerWishItemId, item.wishListCategoryId)} >Remove</a>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Col>
                                        </Row>
                                        {/* <MsgModal show={show} title={head} message={content} closeAction={closeAction} /> */}
                                    </React.Fragment>
                                )
                            }) : null : <WishListnull />}
                </div>
            </div>
        </React.Fragment >
    );
}

const mapStateToProps = state => ({
    authenticated: state.AuthData.authenticated,
    productsList: state.ProductsData.productsList,
    wishlist: state.ProductsData.wishlist,
    deletewishlist: state.ProductsData.deletewishlist,
    wishlistitem: state.ProductsData.wishlistitem,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
    loginData: state.AuthData.loginData
})
export default connect(mapStateToProps, { setLoginModal, getWishList, getWishListItemById, SaveUpdateWishCategory, deleteWishItem, DeleteWishListCategoryWithItems, set_login_head })(WishList);
