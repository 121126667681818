import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';
import ImageUtil from '../../utils/ImageUtil';
import { Col, Row } from 'react-bootstrap';
import MsgModal from '../../container/MsgModal';
import ReactStars from "react-rating-stars-component";
import {saveRating} from "../../actions/ProductsAction";
import { Global } from '../../utils/Env';
import { formatINR,formatSymbolFromCurrency } from '../../utils/CommonUtil';

const OrderItem = (props) => {
	
	const [show,setShow]=useState(false);
  const [content,setContent]=useState(null);
  const [head,setHead]=useState("Wish List");
  const [rate,setRate]=useState(props.product.rating); 
  
  const closeAction=()=>{
		setShow(false);
	}
	
	const handleReturn=()=>{
		setShow(true);
		setHead("Return Item");
		setContent("Please call up 033-40653034/+91 80172 14927 to request for returning the product.");
	}
	
const handleNotReturn=()=>{
	setShow(true);
		setHead("Return Item");
		setContent("This item is not eligible for return.");
	}
const ratingChanged = (newRating) => {
  props.saveRating({"CompanyId":props.companyid,"ItemId":props.product.itemId,"VariantId":props.product.itemVariantId,"CustomerId":parseInt(props.loginData.data.customerId),"Rating":newRating,"Note":""});
  setRate(newRating);
  
  };
  
   Global.callback.saverating_onComplete = () => {
	   if(props.saveratingdata.status)
	   {setShow(true);
		setHead("Save Rating");
		setContent("Your rating has been saved. Thank you for your feedback.");
	   }
   }

useEffect(()=>{
	setRate(props.product.rating);
},[props.product.rating]);

    return (
        <React.Fragment>
            <Row className="cart-item border mt-2 " >

                <Col md="2" xs="3" className="p-0-5-tab pl-1 pr-1">
                    <div className="chkout-main-imgpanel gg">
                        <img
                            className="product-image product-image-cart"
                            src={props.product.imageName ? ImageUtil.getImage(props.product.imageName) : ImageUtil.getErrorImage()}

                            alt={props.product.imageAlt}
                            onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = ImageUtil.getErrorImage();
                            }}
                        />
                    </div>
                </Col>
                <Col md="10 p-0" xs="9">
                    <Col md="12" className="text-left pl-0 pl-0-mob">
					<p className="product-name-order product-name product-name-cart mb-2 "><b>{`${props.product.name} `}</b></p>
					
					<Row>
					<Col md="6">
						{props.product.color && props.product.color!=''?<p className='prod-variant-text'>Color: <span className='a-text-regular col8f8f8f fsize13'>{props.product.color}</span></p>:''}
                        {props.product.size && props.product.size!=''?<p className='prod-variant-text'>Size: <span className='a-text-regular col8f8f8f fsize13'>{props.product.size}</span></p>:''}
                        {props.product.weight && props.product.weight!=''?<p className='prod-variant-text'>Weight: <span className='a-text-regular col8f8f8f fsize13'>{props.product.weight}</span></p>:''}
                        {props.product.material && props.product.material!=''?<p className='prod-variant-text'>Material: <span className='a-text-regular col8f8f8f fsize13'>{props.product.material}</span></p>:''}
						<p className="product-name product-name-cart mb-1">Ordered Quantity: <b>{props.product.qty}</b></p>
						<p className="product-name product-name-cart mb-1">Price per unit: <b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),(props.product.price))}</b></p>
						<p className="product-name product-name-cart mb-1">Tax per unit: <b>{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode),((props.product.tax/props.product.qty)))}</b> @ (<b>{props.product.taxRate}%)</b></p>
						{props.product.isReturn?<p className="product-name product-name-cart mb-0">Last Return Date: <b>{props.product.lastReturnDate}</b></p>:null}
						
						
						</Col>
						<Col md="6">
						<p className="product-name product-name-cart mb-1">Invoice Quantity: <b>{props.product.invQty}</b></p>
						<p className="product-name product-name-cart mb-1">Invoice Date: <b>{props.product.invDate}</b></p>
						<p className="product-name product-name-cart mb-1">Invoice Status: <b>{props.product.status}</b></p>
						</Col>
						<Col md="12">
						<p className="product-name product-name-cart mb-0 mt-4"><b>Your Rating for this Item:</b></p>
						<ReactStars
						count={5}
						onChange={ratingChanged}
						size={24}
						value={rate}
						isHalf={false}
						/>
						</Col>
						</Row>
						<p className="amount product-price text-right mt-2 mb-0"><b>
                                {props.product.isReturn 
                                    ? <a aria-hidden="true" title="Return Item" className="ret-item" href="#" onClick={handleReturn}>Return</a>: <a aria-hidden="true" title="Return Item" className="ret-item" href="#" onClick={handleNotReturn}>Return</a>}</b></p>
						
                    </Col>
                </Col>
            </Row>
			<MsgModal show={show} title={head} message={content} closeAction={closeAction} />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
	phone: state.HomeData.phoneno,
	companyid:state.HomeData.companyid,
	loginData: state.AuthData.loginData,
	saveratingdata:state.ProductsData.saveratingdata,
	getratingdata:state.ProductsData.getratingdata,
	companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
	
})
export default connect(mapStateToProps, {saveRating})(OrderItem);

