import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Container, Row, Col, Form, Navbar } from "react-bootstrap";
import {
  login,
  SigninByOthers,
  signup,
  set_phoneNumber,
  set_password,
  set_authenticated,
  setMsgModal,
  forgotPassword,
  sendForgotPasswordOtp,
  validateForgotPasswordOtp,
  set_login_head,
  WalletInfo,
  setUpdatedPhoneNumber,
} from "../../actions/AuthAction";
import { getCart, saveCart } from "../../actions/CartAction";
import { clearLocation } from "../../actions/LocationAction";
import { getfeaturedItems } from "../../actions/ProductsAction";
import { set_phone, setValidateOtp } from "../../actions/UserAction";
import { Global } from "../../utils/Env";
import MsgModal from "../../container/MsgModal";
import ResendForgotOtp from "./ResendForgotOtp";
import Footer from "../../components/Footer";
import ImageUtil from "../../utils/ImageUtil";
import SignUpDetails from "../../container/SignUpDetails";
import PhoneValidate from "../../components/checkout/PhoneValidate";
import FacebookLogin from "react-facebook-login";
import { GoogleLogin } from "@react-oauth/google";
import jwt_decode from "jwt-decode";

const Login = (props) => {
  const [phnCard, setPhnCard] = useState(true);
  const [addressCard, setAddressCard] = useState(false);
  const [deliveryCard, setDeliveryCard] = useState(false);
  const [paymentCard, setPymentCard] = useState(false);
  const [redirectToLogin, setredirectToLogin] = useState(true);
  const [signInDis,setSignInDis]=useState(false);

  const [validated, setValidated] = useState(false);
  const [state, setState] = useState("login");
  const [username, setUserName] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const [otp, setOtp] = useState(["", "", "", ""]);

  const [otpError, setOtpError] = useState(false);
  const navigate = useNavigate();
  const disableAllState = () => {
    setPhnCard(true);
    setAddressCard(false);
    setDeliveryCard(false);
    setPymentCard(false);
  };
  Global.callback.savecart_onComplete = () => {
    setSignInDis(false);
    props.getCart({
      companyid: props.companyid,
      customerid: parseInt(props.loginData.data.customerId),
    });
  };
  Global.callback.login_onComplete = () => {
    setSignInDis(false);
    if (props.loginData.data != null) {
      props.set_authenticated(true);
      props.set_phoneNumber("");
      props.set_password("");
      props.set_phone(props.loginData.data.phone);
      props.setValidateOtp({ status: 1, message: "Success", valid: true });
      if (
        props.loginData.data.customerId != "" &&
        props.loginData.data.customerId != null &&
        props.loginData.data.userType == "b2b"
      ) {
        props.WalletInfo({
          CustomerId: parseInt(props.loginData.data.customerId),
        });
      }
      if (props.cartList.length > 0) {
        let items = [];
        props.cartList.map((item, i) => {
          items.push({
            itemId: item.id,
            itemVariantId: item.variantid ? item.variantid : 0,
            itemQty: item.quantity,
          });
        });
        props.saveCart({
          companyid: props.companyid,
          customerid: parseInt(props.loginData.data.customerId),
          userid: props.loginData.data.id,
          carttItemVariant: items,
        });
      } else
        props.getCart({
          companyid: props.companyid,
          customerid: parseInt(props.loginData.data.customerId),
        });
      props.clearLocation();
      props.closeAction();
    } else
      props.setMsgModal({
        title: "Error in login",
        message: props.loginData.message,
        show: true,
      });
  };
  Global.callback.login_onError = () => {
    setSignInDis(false);
    props.setMsgModal({
      title: "Error in login",
      message: "Some Error Occurred",
      show: true,
    });
  };
  Global.callback.SigninByOthers_onComplete = () => {
    setSignInDis(false);
    socialcallbackMethod();
  };
  Global.callback.forgotpassword_onComplete = () => {
    setSignInDis(false);
    props.setMsgModal({
      title: "Forgot Password",
      message: props.forgotpassworddata.message,
      show: true,
    });
    setState("login");
    setNewPassword("");
    setConfirmPassword("");
    props.set_login_head("Login");
  };

  Global.callback.validateforgotpasswordotp_onComplete = () => {
    setSignInDis(false);
    if (!props.validateforgotpasswordotpdata.valid) setOtpError(true);
    else {
      setState("reset");
    }
  };

  Global.callback.sendforgotpasswordotp_onComplete = () => {
    setSignInDis(false);
    if (props.sendforgotpasswordotpdata.status == 1) {
      setState("otp");
      props.set_login_head(
        <>
          Login As : <b>{props.sendforgotpasswordotpdata.userName}</b>
        </>
      );
    } else
      props.setMsgModal({
        title: "Error",
        message: props.sendforgotpasswordotpdata.message,
        show: true,
      });
  };

  const onErrorLoginClose = () => {
    props.setMsgModal({
      title: "",
      message: "",
      show: false,
    });
    props.closeAction();
  };
  /** @function
   * @name linkToSignUp */
  const linkToSignUp = () => {
    // navigate("/register");
    setState("signup");
  };
  /** @function
   * @name linkToOTPSignUp */
  const linkToOTPSignUp = () => {
    // navigate("/register");
    setState("OTPLogin");
  };

  /** @function
   * @name linkToGuestUser */
  const linkToGuestUser = () => {
    navigate("/");
  };

  /** @function
   * @name handlePhonenumberChange */
  const handlePhonenumberChange = (e) => {
    props.set_phoneNumber(e.target.value);
  };
  /** @function
   * @name isValidNumber */
  /** @function
   * @name handlePasswordChange */
  const handlePasswordChange = (e) => {
    props.set_password(e.target.value);
  };
  /** @function
   * @name handleClick */
  const handleClick = () => {
    if (validated && props.phoneNumber != "" && props.password != "")
    {
      props.login({
        CompanyId: props.companyid,
        UserName: props.phoneNumber,
        Password: props.password,
      });
      setSignInDis(true);
    }
    else
      props.setMsgModal({
        title: "Error in login",
        message:
          "There is a validation error in the form. Please check the form Details",
        show: true,
      });
  };

  const handleForgotClick = () => {
    if (validated && username != "" && username.length == 10) {
      props.sendForgotPasswordOtp({
        CompanyId: props.companyid,
        phone: username,
      });
      setValidated(false);
      setSignInDis(true);
    } else
      props.setMsgModal({
        title: "Error in Phone Number",
        message: "This is an invalid Phone Number. Please check..",
        show: true,
      });
  };

  const validOtp = () => {
    if (otp.length === 4) {
      props.validateForgotPasswordOtp({
        companyid: props.companyid,
        phone: username,
        otp: otp.join(""),
      });
      setOtp(["", "", "", ""]); // clear otp fields
      setSignInDis(true);
    }
  };

  useEffect(() => {
    //props.setValidateOtp({ status: 1, message: "Failed", valid: false });
    if (props.authenticated) {
      navigate("/", { replace: true });
    }
  }, []);

  const linkToForgot = () => {
    setState("forgot");
    setValidated(false);
    setUserName("");
    props.set_phoneNumber("");
    props.set_password("");
  };

  const handleForgotChange = (e) => {
    if (e.target.value.length <= 10) setUserName(e.target.value);
  };

  const handleOtpChange = (e, i) => {
    let copyOtp = [...otp];
    if (e.target.value.length <= 1) {
      copyOtp[i] = e.target.value;
      setOtp(copyOtp);
    }
    if (otp[i] === "") {
      switch (i + 1) {
        case 1: {
          ref2.current.focus();
          break;
        }
        case 2: {
          ref3.current.focus();
          break;
        }
        case 3: {
          ref4.current.focus();
          break;
        }
        case 4: {
          ref5.current.focus();
          break;
        }
        default:
          return null;
      }
    }
  };

  const renderOtpField = () => {
    return (
      <Row className="pl-4 pr-4">
        <p className="w-100 fs-14 mb-1">Enter Your OTP</p>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab"
            ref={ref1}
            value={otp[0]}
            onChange={(e) => handleOtpChange(e, 0)}
          />
        </Col>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab"
            ref={ref2}
            value={otp[1]}
            onChange={(e) => handleOtpChange(e, 1)}
          />
        </Col>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab"
            ref={ref3}
            value={otp[2]}
            onChange={(e) => handleOtpChange(e, 2)}
          />
        </Col>
        <Col md="3 pl-0" xs="3">
          <input
            className="form-control p-0-5-tab"
            ref={ref4}
            value={otp[3]}
            onChange={(e) => handleOtpChange(e, 3)}
          />
        </Col>
        {otpError && (
          <div className="text-danger font-12 mt-2">
            Error in otp... try again
          </div>
        )}
        <ResendForgotOtp phone={username} />
      </Row>
    );
  };

  const handleResetChange = (e) => {
    if (e.target.name == "new_password") setNewPassword(e.target.value);
    else if (e.target.name == "confirm_password")
      setConfirmPassword(e.target.value);
  };

  const handleResetClick = () => {
    if (validated && new_password != "" && new_password == confirm_password) {
      props.forgotPassword({
        CompanyId: props.companyid,
        UserId: props.sendforgotpasswordotpdata.userId,
        NewPassword: new_password,
        ConfirmNewPassword: confirm_password,
      });
      setValidated(false);
    } else
      props.setMsgModal({
        title: "Error in Form",
        message:
          "Please check the two passwords should be same and both should be filled",
        show: true,
      });
  };

  const onKeyDownHandler = (e) => {
    setValidated(true);
    if (e.keyCode === 13 && state == "login") {
      handleClick();
      e.preventDefault();
    } else if (e.keyCode === 13 && state == "forgot") {
      handleForgotClick();
      e.preventDefault();
    } else if (e.keyCode === 13 && state == "reset") {
      handleResetClick();
      e.preventDefault();
    }
  };

  const checkOtpButtonValidity = () => {
    if (otp[0] !== "" && otp[1] !== "" && otp[2] !== "" && otp[3] !== "") {
      return false;
    } else return true;
  };

  const responseFacebook = (response) => {
    console.log("facebook console");
    console.log("faceboo",response);
    var FirstName = response.name.split(" ")[0];
    var LastName = response.name.split(" ")[1];
    props.SigninByOthers({
      CompanyId: props.companyid,
      Email: response.email,
      FirstName: FirstName,
      LastName: LastName,
      Media: "facebook",
      mediaId: response.id,
    });
  };
  const responseGoogle = (response) => {
    console.log("google console");
    console.log(response);
    var decoded = jwt_decode(response.credential);
    console.log("decoded", decoded);
    var FirstName = decoded.given_name;
    var LastName = decoded.family_name;
    props.SigninByOthers({
      CompanyId: props.companyid,
      Email: decoded.email,
      FirstName: FirstName,
      LastName: LastName,
      Media: "google",
      mediaId: decoded.sub,
    });
  };
  const socialcallbackMethod = () => {
    if (props.loginData.data != null) {
      props.setUpdatedPhoneNumber(props.loginData.data.phone);
      props.set_authenticated(true);
      props.set_phoneNumber("");
      props.set_password("");
      props.set_phone(props.loginData.data.phone);
      props.setValidateOtp({ status: 1, message: "Success", valid: true });
      if (
        props.loginData.data.customerId != "" &&
        props.loginData.data.customerId != null &&
        props.loginData.data.userType == "b2b"
      ) {
        props.WalletInfo({
          CustomerId: parseInt(props.loginData.data.customerId),
        });
      }
      if (props.cartList.length > 0) {
        let items = [];
        props.cartList.map((item, i) => {
          items.push({
            itemId: item.id,
            itemVariantId: item.variantid ? item.variantid : 0,
            itemQty: item.quantity,
          });
        });
        props.saveCart({
          companyid: props.companyid,
          customerid: parseInt(props.loginData.data.customerId),
          userid: props.loginData.data.id,
          carttItemVariant: items,
        });
      } else
        props.getCart({
          companyid: props.companyid,
          customerid: parseInt(props.loginData.data.customerId),
        });
      props.clearLocation();
      props.closeAction();
    }
  };
  const closeAction = () => {
    setState("");
    props.setMsgModal({
      title: "",
      message: "",
      show: false,
    });
  };
  return (
    <div className="page-bodesection">
      {state == "login" ? (
        <div className="d-flex h-100 justify-content-center align-self-center flex-column">
          <Form validated={validated} onKeyDown={onKeyDownHandler}>
            <fieldset className="scheduler-border rounded">
              <legend className="scheduler-border signin-label">Sign In</legend>

              <Form.Group controlId="formBasicEmail">
                <Form.Label className="fsize14 mb-1 google-colorstyle">
                  User Name
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Username"
                  value={props.phoneNumber}
                  onChange={handlePhonenumberChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <Form.Label className="fsize14 mb-1 google-colorstyle">
                  Password
                </Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Type Password"
                  value={props.password}
                  onChange={handlePasswordChange}
                  required
                />
              </Form.Group>

              <div>
                <p className="font-weight-bold text-muted text-center">
                  <a
                    href="#"
                    className="font-weight-bold text-danger text-decoration-underline"
                  >
                    {/*<span onClick={signInDis?console.log("disabled"):()=>linkToOTPSignUp()}>
                      Get an OTP on your phone
                    </span>*/}
                  </a>
                </p>
              </div>
              <div>
                <Button
                  id="submit_test"
                  className="rounded-0 primarybutton mb-0"
                  onClick={handleClick}
                  disabled={signInDis}
                >
                  SIGN IN
                </Button>{" "}
              </div>
             {/* <div className="text-center">
              <h4 className="m-0 pt-3 pb-3">OR</h4>
                <FacebookLogin
                  appId={props.companyDetailsCurrency.faceBookApiId}
                  autoLoad={false}
                  cssClass="loginBtn loginBtn--facebook"
                  fields="name,email,picture"
                  callback={responseFacebook}
                  disabled={signInDis}
                />
                <br />
                <br />
                <div className="text-center ml-0-mobile" style={{ marginLeft: "100px" }}>
                  <GoogleLogin
                    onSuccess={responseGoogle}
                    onError={() => {
                      console.log("Login Failed");
                    }}
                    disabled={signInDis}
                  />
                </div>
                  </div> */}


            </fieldset>
            <div className="row log-footer">
              <div className="col-md-6 col-6">
                <p className="fsize12 font-weight-bold text-muted mb-0">
                  New User ?{" "}
                  <a
                    href="#"
                    className="fsize13 font-weight-bold text-danger text-decoration-underline"
                  >
                    <span className="d-block-mob" onClick={signInDis?console.log("disabled"):()=>linkToSignUp()}>Register Here</span>
                  </a>
                </p>
              </div>
              <div className="col-md-6 col-6 text-right">
                <p className="fsize12 font-weight-bold text-muted mb-0">
                  Cant Login ?{" "}
                  <a
                    href="#"
                    className="fsize13 font-weight-bold text-danger text-decoration-underline"
                  >
                    <span className="d-block-mob" onClick={signInDis?console.log("disabled"):()=>linkToForgot()}>Forgot Password</span>
                  </a>
                </p>
              </div>
            </div>
          </Form>
        </div>
      ) : null}
      {state == "forgot" ? (
        <div className="d-flex h-100 justify-content-center align-self-center flex-column">
          <Form validated={validated} onKeyDown={onKeyDownHandler}>
            <fieldset className="scheduler-border rounded mb-0">
              <legend className="scheduler-border signin-label">
                Forgot Password
              </legend>
              <Form.Group controlId="formBasicEmail">
                <Form.Label className="fsize14 mb-1">
                  <b>Phone</b>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Phone"
                  value={username}
                  onChange={handleForgotChange}
                  required
                />
              </Form.Group>
              <Button
                id="submit_test"
                className="rounded-0 primarybutton pt-2 pb-2 mb-0"
                onClick={handleForgotClick}
                disabled={signInDis}
              >
                Reset Password
              </Button>{" "}
            </fieldset>
            <div className="log-footer">
              <div>
                <p className="fsize12 font-weight-bold text-muted mb-0">
                  New User ?{" "}
                  <a
                    href="#"
                    className="fsize13 font-weight-bold text-danger text-decoration-underline"
                  >
                    <span onClick={signInDis?console.log('false'):()=>linkToSignUp()}>Register Here</span>
                  </a>
                </p>
              </div>
              <div>
                <p className="fsize12 font-weight-bold text-muted mb-0">
                  Login ?{" "}
                  <a
                    href="#"
                    className="fsize13 font-weight-bold text-danger text-decoration-underline"
                  >
                    <span
                      onClick={signInDis?console.log('false'):() => {
                        setState("login");
                        setValidated(false);
                      }}
                    >
                      Login Here
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </Form>
        </div>
      ) : null}

      {state == "otp" ? (
        <>
          {renderOtpField()}
          <div className="text-right pl-4 pr-4 pb-3">
            <Button
              ref={ref5}
              onClick={validOtp}
              disabled={checkOtpButtonValidity() || signInDis}
              className="rounded-0 primarybutton pt-2 pb-2 mb-0"
            >
              Next
            </Button>
          </div>
        </>
      ) : null}

      {state == "reset" ? (
        <div className="d-flex h-100 justify-content-center align-self-center flex-column">
          <fieldset className="scheduler-border rounded">
            <legend className="scheduler-border signin-label">
              Reset Password
            </legend>
            <Form validated={validated} onKeyDown={onKeyDownHandler}>
              <Form.Group>
                <Form.Label className="fsize14 mb-1">
                  <b>New Password</b>
                </Form.Label>
                <Form.Control
                  name="new_password"
                  type="password"
                  placeholder="New Password"
                  value={new_password}
                  onChange={handleResetChange}
                  required
                />
              </Form.Group>
              <Form.Group>
                <Form.Label className="fsize14 mb-1">
                  <b>Confirm Password</b>
                </Form.Label>
                <Form.Control
                  name="confirm_password"
                  type="password"
                  placeholder="Confirm Password"
                  value={confirm_password}
                  onChange={handleResetChange}
                  required
                />
              </Form.Group>

              <Button
                id="submit_test"
                className="rounded-0 primarybutton pt-2 pb-2 mb-4"
                onClick={handleResetClick}
              >
                Confirm New Password
              </Button>
            </Form>
          </fieldset>
        </div>
      ) : null}
      {state == "signup" ? <SignUpDetails /> : null}
      {state == "OTPLogin" ? (
        <div className="ph-val" id="popupID">
          <PhoneValidate
            setAddressCard={setAddressCard}
            phnCard={phnCard}
            setPhnCard={setPhnCard}
            disableAllState={disableAllState}
            redirectToLogin={redirectToLogin}
            closeAction={onErrorLoginClose}
          />
        </div>
      ) : null}

      {!props.authenticated && props.modal && props.modal.show && (
        <MsgModal
          show={props.modal.show}
          title={props.modal.title}
          message={props.modal.message}
          closeAction={onErrorLoginClose}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  phoneNumber: state.AuthData.phoneNumber,
  password: state.AuthData.password,
  authenticated: state.AuthData.authenticated,
  loginData: state.AuthData.loginData,
  modal: state.AuthData.modal,
  cartList: state.CartData.cartList,
  companyid: state.HomeData.companyid,
  forgotpassworddata: state.AuthData.forgotpassworddata,
  sendforgotpasswordotpdata: state.AuthData.sendforgotpasswordotpdata,
  validateforgotpasswordotpdata: state.AuthData.validateforgotpasswordotpdata,
  authData: state.AuthData,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
});

export default connect(mapStateToProps, {
  setMsgModal,
  getfeaturedItems,
  login,
  SigninByOthers,
  signup,
  set_phoneNumber,
  set_password,
  set_authenticated,
  set_phone,
  setValidateOtp,
  clearLocation,
  getCart,
  saveCart,
  forgotPassword,
  sendForgotPasswordOtp,
  validateForgotPasswordOtp,
  set_login_head,
  WalletInfo,
  setUpdatedPhoneNumber,
})(Login);
