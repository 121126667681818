import React, { useState, useEffect } from 'react';
import { Form, Col, Row, Card, Button, Accordion, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { addAddress, deleteAddress } from '../../actions/CheckoutAction';
import { Global } from '../../utils/Env';
import { validatePin } from '../../actions/LocationAction';
import {
    set_fname,
    set_lname,
    set_mname,
    set_email,
    set_address1,
    set_address2,
    set_city,
    set_dist,
    set_state,
    set_country, set_pin, set_addressType, set_addressId, setCustomerDetails, getCustomerDetails,set_phone
} from '../../actions/UserAction';
import Info from '../Info';
import MsgModal from '../../container/MsgModal';
import DeleteModal from './DeleteModal';
import axios from 'axios';
import { reverseGeoCodeAPI, restAPIKey } from "../../utils/CommonUtil";

const Address = (props) => {

    const emptyForm = {
        addrid: -1,
        email: "",
        fname: "",
        mname: "",
        lname: "",
        address1: "",
        address2: "",
        city: props.city,
        dist: props.dist,
        state: props.state,
        pin: props.pincode,
        type: ""
    }

    const [formData, setFormData] = useState(emptyForm);
    const [isFormOpen, setFormOpen] = useState(false);
    const [validated, setValidated] = useState(false);
    const [addressSelected, setAddressSelected] = useState(false);
    const [show, setShow] = useState(false);
    const [content, setContent] = useState(null);
    const [head, setHead] = useState("Wish List");
    const [deleteshow, setDeleteShow] = useState(false);
    const [deletecontent, setDeleteContent] = useState(null);
    const [Latitude, setLatitude] = useState('');
    const [Longitude, setLongitude] = useState('');
    const [ForcePageLoad, setForcePageLoad] = useState(true);

    const { email, fname, mname, lname, address1, address2, city, state, pin, dist, type,phone } = formData;
    const info_content = <><p>We are unable to offer refunds if we’ve been given an incorrect or incomplete shipping address, or if there are three failed delivery attempts by our shipping agency and/or the package is refused by the recipient</p></>
    const [addressHighlighted, setaddressHighlighted] = useState("each-address-part");
    let countryDetails = '';
    let stateDetails = '';
    let DistDetails = '';
    let CityDetails = '';
    let PinDetails = '';
    let address1Details = '';
    let address2Details = '';

    const setFinalAddress = (item) => {
        //+++++++++++++++++++++++++
        props.set_fname(item.fname);
        props.set_lname(item.lname);
        props.set_mname(item.mname);
        props.set_email(item.email);
        props.set_address1(item.address1);
        props.set_address2(item.address2);
        props.set_city(item.city);
        props.set_state(item.state);
        props.set_dist(item.dist);
        props.set_pin(item.pin);
        props.set_addressType(item.type);
        props.set_addressId(item.addrid)
        props.set_phone(item.phone);
        //+++++++++++++++++++++++++
    }
    const selectAddress = (data) => {
        console.log('datadatadata', props.customerDetails)
        console.log('datadatadata111111', data)
        // if(data !=null && data.length>0 && props.customerDetails !=null && props.customerDetails.length>0)
        // {
            console.log('datadatadata2222')
            props.customerDetails.map((item, id) => {
                if(item.addrid==data.addrid)
                document.getElementById(data.addrid).className='each-address-part activ-Address';
                else
                document.getElementById(item.addrid).className='each-address-part';
            });
        // }
        setFormData(data)
        setFinalAddress(data);
        setAddressSelected(true);
        props.setDeliveryAddressSet(true);
        setTimeout(() => {
            props.setAddressCard(true);
            props.setDeliveryCard(true);
        }, 500);
    }

    
    Global.callback.getCustomerDetails_onComplete = () => {

    }

    let primaryColor=props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:'';
    let secondaryColor=props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:'';
    let tertiaryColor=props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:'';
    const closeAction = () => {
        setShow(false);
    }

    const closeDeleteAction = () => {
        setDeleteShow(false);
    }

    const handleOnChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    Global.callback.validatePin_onComplete = () => {
        setFormData({ ...formData, city: props.city, dist: props.dist, state: props.state, address2: props.address2 });
    }

    const handlePinChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            //props.validatePin({ "pinCode": e.target.value, "companyid": props.companyid });
    }

    //.. Call for NEW ADDRESS  
    const addAddressHandler = () => {
        //console.log(props);
        setFormData({ ...emptyForm, state: props.state, dist: props.dist, city: props.city, address2: props.address2, pin: props.pincode });
        //handleOnChange({target:{name:"dist", value:props.dist}});
        setAddressSelected(false);
        setFormOpen(true);
    }

    const addresslimit = () => {

        setShow(true);
        setContent("<div style='text-align: left;'>Only 3 addresses can be added per account.<br/>Delete an address to add more.</div>");
        setHead("Add Address");
    }

    //.. call for EDIT ADDRESS
    const editAddressHandler = (data, i) => {
        //console.clear();
        //.. edit address set the form to open
        setFormOpen(true);
        setFormData({ ...data });
    }

    const saveAddress = (event) => {
        //console.log("saving Customer details");
        //document.getElementById("addtype").options[1].selected = true;
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setShow(true);
            setHead("Form Validation Error");
            setContent("Please check the form data and the pincode");
        }

        setValidated(true);
        event.preventDefault();

        if (form.checkValidity() === true) {
            //.. Save DETAILS OF THE FORM
            setFormOpen(false);
            doLocalUpdate(formData); // to be replaced with updateAddress call to server
        }
    }

    Global.callback.addaddress_onComplete = () => {
        if (props.customerDetails.filter((item) => { return item.addrid === -1 }).length > 0) {
            let data = JSON.parse(JSON.stringify(props.customerDetails.filter((item) => { return item.addrid === -1 })[0]));
            data.addrid = props.addaddressdata.data;
            let updatedData = props.customerDetails.map(function (item) { return item.addrid === -1 ? data : item; });
            props.setCustomerDetails({ data: { data: updatedData, customerId: props.customerid } });

        }
    }

    const deleteAddressfinal = (id) => {

        const delAddress = () => {
            let obj = {
                "CompanyId": props.companyid,
                "AddressId": id
            }
            props.deleteAddress(obj);
            let final_index;
            final_index = props.customerDetails.findIndex(x => x.addrid === id)
            let updatedData = [...props.customerDetails];
            updatedData.splice(final_index, 1);
            props.setCustomerDetails({ data: { data: updatedData, customerId: props.customerid } });
            closeDeleteAction();
        }

        return (
            <React.Fragment>
                <p>Are you sure You want to delete this Address?</p>
                <br />
                <div className='text-right'>
                   
                    {/* <span className="delete_cancel"><Button  className='mr-3 cancel-light-button min-w-save-cancelo' onClick={() => closeDeleteAction()}>Cancel</Button></span> */}
                    <span><Button onClick={() => delAddress()} style={{backgroundColor:primaryColor}} className="bgpinbutton_nocolor position-relative min-w-save-cancelo">Delete</Button></span>
                </div>
            </React.Fragment>
        );
    }

    const deleteaddressclick = (addrid) => {
        setDeleteShow(true);
        setDeleteContent(deleteAddressfinal(addrid));
    }

    const doLocalUpdate = (data) => {
        console.log(data.addrid, "->", data, props.customerDetails);
        if (data.addrid === -1 && props.customerDetails != null) {
            if (props.customerDetails.filter((item) => { return item.addrid === -1 }).length == 0)
                props.setCustomerDetails({ data: { data: props.customerDetails.add(data), customerId: props.customerid } });
            else {
                let updatedData = props.customerDetails.map(function (item) { return item.addrid === data.addrid ? data : item; });
                props.setCustomerDetails({ data: { data: updatedData, customerId: props.customerid } });
            }
        }
        else if (data.addrid === -1 && props.customerDetails == null) {
            props.setCustomerDetails({ data: { data: [data], customerId: props.customerid } });
        }
        else if (data.addrid ==undefined && props.customerDetails == null) {
            props.setCustomerDetails({ data: { data: [data], customerId: props.customerid } });
        }
        else {
            let updatedData = props.customerDetails.map(function (item) { return item.addrid === data.addrid ? data : item; });
            props.setCustomerDetails({ data: { data: updatedData, customerId: props.customerid } });
        }
        let obj = {
            "companyid": props.companyid,
            "CustomerId": props.authenticated ? parseInt(props.loginData.data.customerId) : props.customerid,
            "customer": {
                "userid": props.authenticated ? props.loginData.data.id : null,
                "addrid": data.addrid,
                "fname": data.fname,
                "mname": data.mname,
                "lname": data.lname,
                "phone": data.phone,
                "email": data.email,
                "address1": data.address1,
                "address2": data.address2,
                "pin": data.pin,
                "city": data.city,
                "dist": data.dist,
                "state": data.state,
                "country": "India",
                "type": data.type,
                "spclrequest": null,
                "paymentmode": null,
                "deliveryslot": null,
                "UserPhoneCountryCode": props.phoneCountryCode,
                "PhoneCode": props.phoneCode
            }
        }
        props.addAddress(obj);
        setForcePageLoad(true);


    }

    useEffect(() => {
            if((props.loginData && props.loginData.data && props.loginData.data.phone !="") || (props.updatedUserPhoneNumber && props.updatedUserPhoneNumber !=""))
            props.getCustomerDetails({ companyid: props.companyid, phone: props.loginData && props.loginData.data && props.loginData.data.customerId !=""?props.loginData.data.customerId:'' });
    }, [props.loginData]);

    const isReturningUser = () => {
        //return (props.customerDetails.length>0) ? true : false
        //console.log(props.customerDetails);
        //console.log(isFormOpen || props.customerDetails!=null);
        return isFormOpen || props.customerDetails == null
    }

    const openForm = () => {
        return (
            <Col className='mt-3'>
                <Form noValidate validated={validated} onSubmit={saveAddress} className="mb-0">
                    <Form.Row>
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">First Name*</Form.Label>
                            <Form.Control placeholder="First Name" value={fname}
                                name="fname" onChange={handleOnChange} required />
                        </Form.Group>
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">Middle Name</Form.Label>
                            <Form.Control placeholder="Middle Name" value={mname}
                                name="mname" onChange={handleOnChange} />
                        </Form.Group>

                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">Last Name*</Form.Label>
                            <Form.Control placeholder="Last Name" value={lname}
                                name="lname" onChange={handleOnChange} required />
                        </Form.Group>
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">Email</Form.Label>
                            <Form.Control type="email" placeholder="Enter Email" value={email}
                                name="email" onChange={handleOnChange}  />
                        </Form.Group>
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">Phone</Form.Label>
                            <Form.Control type="number" placeholder="Enter Phone" value={phone}
                                name="phone" onChange={handleOnChange}  />
                        </Form.Group>
                        <Form.Group className='form-group-mb-cont col-md-6'>
                            <Form.Label className="form-group-labels">Address Line 1*</Form.Label>
                            <Form.Control placeholder="Number & Street" value={address1}
                                name="address1" onChange={handleOnChange} required />
                        </Form.Group>
                        <Form.Group className='form-group-mb-cont col-md-6'>
                            <Form.Label className="form-group-labels">Address Line 2</Form.Label>
                            <Form.Control placeholder="Number & Street" value={address2}
                                name="address2" onChange={handleOnChange} />
                        </Form.Group>
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">PIN/ZIP*</Form.Label>
                            <Form.Control value={pin}
                                name="pin" placeholder="Pin Code" type="number" onChange={handlePinChange} required />
                        </Form.Group>
                        {props.showDistrict?
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">District*</Form.Label>
                            <Form.Control value={dist}
                                name="dist" placeholder="District" onChange={handleOnChange} required />
                        </Form.Group>:''}
                        {props.showCity?
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">City*</Form.Label>
                            <Form.Control value={city}
                                name="city" placeholder="City" onChange={handleOnChange} required />
                        </Form.Group>:''}
                        {props.showState?
                        <Form.Group className='form-group-mb-cont col-md-3'>
                            <Form.Label className="form-group-labels">State*</Form.Label>
                            <Form.Control value={state}
                                name="state" placeholder="State" onChange={handleOnChange} required />
                        </Form.Group>:''}


                    </Form.Row>

                    {/* <Form.Group>
                                <Form.Label className="form-group-labels">Special Request</Form.Label>
                                <Form.Control as="textarea" name="rqst" value={rqst} onChange={handleOnChange} rows="3" />
                            </Form.Group>
                            
                            <Form.Group>
                                <Form.Label className="form-group-labels">Address Type</Form.Label>
                                <Form.Control id="addtype" onChange={selectAddressType}
                                    as="select" > 
                                    <option>Address Type</option>
                                     {props.addressTypes.map(item => {
                                        return (
                                            <option key={item.id} value={item.id}>{item.type}</option>
                                        )
                                    })}
                                </Form.Control>
                            </Form.Group> */}
                    <Col md="12 text-right p-0 mt-3 mb-3">
                        <button className="btn btn-light mr-3 cancel-light-button min-w-save-cancelo" type="button" onClick={closeForm}>Cancel</button>
                        <button className="btn btn-secondary bgpinbutton position-relative min-w-save-cancelo" type="submit">Save</button>
                    </Col>
                </Form>
            </Col>
        )
    }

    const closeForm = () => {
        //.. set the form to close
        setFormOpen(false);
        //console.log("closeForm");
    }

    const API_Endpoint = `${reverseGeoCodeAPI()}${restAPIKey()}/rev_geocode?`;
    const componentDidMount = () => {
        if (props.customerDetails == undefined || props.customerDetails.length < 3) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
              };
            const error=(err)=>{
                console.log(err);
            }
            navigator.geolocation.getCurrentPosition(function (position) {
                console.log("Latitude is :", position.coords.latitude);
                console.log("Longitude is :", position.coords.longitude);
                console.log(position)
                setLatitude(position.coords.latitude);
                setLongitude(position.coords.longitude);
                let finalEndPoint = `${API_Endpoint}lat=${position.coords.latitude}&lng=${position.coords.longitude}`;
                axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${props.companyDetailsCurrency.GoogleMapApiKey?props.companyDetailsCurrency.GoogleMapApiKey:'AIzaSyA7thQlKtjxX_zrJJ3ryn1PwPgcViww6m8'}`)
                    .then((Response) => {
                        if (Response.data != null && Response.data != '') {
                            if (typeof Response.data.results !== "undefined" && Response.data.results != null && Response.data.results != '') {
                                let loc_data_final=Response.data.results[0]
                                console.log('location Details', loc_data_final)
                                let loc_data=loc_data_final.address_components;
                                let parsedData=[];
                                loc_data.map((item,i)=>{
                                    let temp=JSON.parse(JSON.stringify(item));
                                    temp.types=temp.types.join(' ');
                                    temp.index=i;
                                    parsedData.push(temp);
                                })
                                let indexes=[];
                                indexes.push(parsedData.filter((item)=>item.types.indexOf("locality")!=-1).length>0?parsedData.filter((item)=>item.types.indexOf("locality")!=-1)[parsedData.filter((item)=>item.types.indexOf("locality")!=-1).length-1].index:-1);
                                    parsedData.map((item,i)=>{
                                        if(item.types.indexOf("postal_code")!=-1)
                                        indexes.push(i);
                                        if(item.types.indexOf("country")!=-1)
                                        indexes.push(i);
                                        if(item.types.indexOf("administrative_area_level_1")!=-1)
                                        indexes.push(i);
                                        if(item.types.indexOf("administrative_area_level_2")!=-1)
                                        indexes.push(i);
                                    })
                                    let indexString=indexes.join(',');
                                    address1Details=null;
                                    address2Details='';
                                    parsedData.map((item,i)=>{
                                        if(indexString.indexOf(i)==-1)
                                        {
                                            if(address1Details==null)
                                            address1Details=item.long_name;
                                            else
                                            address2Details=address2Details.concat(item.long_name).concat(',');
                                        }
                                    })

                                    PinDetails = parsedData.filter((item)=>item.types.indexOf("postal_code")!=-1).length>0?parsedData.filter((item)=>item.types.indexOf("postal_code")!=-1)[0].long_name:'';
                                    countryDetails = parsedData.filter((item)=>item.types.indexOf("country")!=-1).length>0?parsedData.filter((item)=>item.types.indexOf("country")!=-1)[0].long_name:'';
                                    stateDetails = parsedData.filter((item)=>item.types.indexOf("administrative_area_level_1")!=-1).length>0?parsedData.filter((item)=>item.types.indexOf("administrative_area_level_1")!=-1)[0].long_name:'';
                                    DistDetails = parsedData.filter((item)=>item.types.indexOf("administrative_area_level_2")!=-1).length>0?parsedData.filter((item)=>item.types.indexOf("administrative_area_level_2")!=-1)[0].long_name:'';
                                    CityDetails = parsedData.filter((item)=>item.types.indexOf("locality")!=-1).length>0?parsedData.filter((item)=>item.types.indexOf("locality")!=-1)[parsedData.filter((item)=>item.types.indexOf("locality")!=-1).length-1].long_name:'';
                                setFormData(emptyForm);
                                setFormData({ country: countryDetails, state: stateDetails, dist: DistDetails, city: CityDetails, address1: address1Details, address2: address2Details, pin: PinDetails });
                            }
                        }
                    })
                setFormOpen(true);
            },error,options)
        }
        else {
            addresslimit();
        }
    }

    const renderAddresses = () => {
        let list = []
        if (props.customerDetails != null)
            list = props.customerDetails.slice(0).reverse().map((item, i) => {
                
                
              
                if (item.fname != null && item.lname != null && item.address1 != null && item.address2 != null)
                    return (

                        <div key={i} className="col-md-4 main-add-main">
                            
                            <div id={item.addrid} className={addressHighlighted}>
                                <div className="">
                                    <div className="p-0" variant="link" eventKey={i.toString()}>
                                        <span title="Open Address" className=' add-new-add'>Address {i + 1} <label title="Edit Address" className="mb-0 float-right">

                                            <span style={{ cursor: "pointer" }} onClick={() => editAddressHandler(item, i)}> <i style={{ margin: "2px" }} className="fa fa-pencil mr-3"></i></span>
                                            <i style={{ cursor: "pointer" }} title="Delete Address" className="fa fa-trash-o del_address" onClick={() => deleteaddressclick(item.addrid)}></i>
                                        </label></span>
                                    </div>
                                </div>

                                <div eventKey={i.toString()}>
                                    <div>
                                        <Row>
                                            <div className="mb-4">
                                                <p className='add-dtlss'>{`${item.fname} ${item.mname} ${item.lname}`}

                                                </p>
                                                <p className='add-dtlss'>Email: {item.email}</p>
                                                <p className='add-dtlss'>{item.address1} {item.address2 || ""}</p>
                                                <p className='add-dtlss'>{item.city} {item.state} {item.pin}</p>
                                            </div>
                                        </Row>

                                    </div>
                                </div>
                                <div eventKey={i.toString()} className="mt-4">
                                    <div className="border-0 pt-0">
                                        <button className="btn btn-success btn btn-primary bgpinbutton  w-100 pl-4 pr-4 rounded-0 outline-0" onClick={() => selectAddress(item)}>Deliver Here</button>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    )
            })
        return (
            <div className="address_accordion row mt-3" defaultActiveKey="0" >
                {list}
            </div>

        );
    }

    return (
        <React.Fragment>
            <Card className="mb-2">
                <div className="row pt-3">
                    <Col>
                        <div className='ship-add-head'>
                            <label> Shipping Address</label>
                            {((props.customerDetails == null) ? <button className="p-0 add-new-add-link" style={{float:"right"}} onClick={addAddressHandler}>Add New Address</button> : <button className="mt-0 btn p-0 add-new-add-link" style={{float:"right"}} onClick={(props.customerDetails.length < 3) ? () => addAddressHandler() : () => addresslimit()}>Add New Address</button>)}
                        </div>
                        {addressSelected && fname ?
                            <Col className="pl-0 selected-address">{`${fname} ${mname} ${lname}, Email: ${email},Phone: ${phone},  ${address1}, ${address2 || ""} ${city}, ${state}, ${pin}`}
                                {/* <label className='feature-save-chkbox'>
                                    <label for="setOrderingPrefsCheckbox" className="a-checkbox mb-0">
                                        <input type="checkbox" />
                                        <label className="a-checkbox-label">
                                            &nbsp;&nbsp;Check this box to default to these delivery options in the future.
                                        </label>
                                    </label>
                                </label> */}

                            </Col>


                            : ""}

                    </Col>
                    {/*<Col>
                        <div className='text-right live-loc-div'> <button className="p-0 fs-14" onClick={componentDidMount}><i class="fa fa-map-marker" aria-hidden="true"></i>&nbsp;&nbsp; Get My Location</button></div>
                    </Col>*/}




                    {/* {props.addressCard && addressSelected && fname ?
                        <div>
                            <Row>
                                <Col md="6 pl-0 mb-1">
                                    <Row>
                                        <Col className="pl-0">{`${fname} ${mname} ${lname}, Email: ${email},  ${address1}, ${address2 || ""} ${city}, ${state}, ${pin}`}</Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div> : ""} */}
                </div>
                {/* <Card.Body style={{ display: props.addressCard ? "block" : "none" }}> */}
                <div className='pt-0 pb-0' style={{ display: true ? "block" : "none" }}>
                    <div className="">
                        {isReturningUser() ? openForm() : renderAddresses()}
                    </div>
                </div>
            </Card>
            {/*<p class="del-text"> <i class="fa fa-info-circle" aria-hidden="true"></i>Delivery Time Inside Dhaka - 2-3 days &amp; Outside Dhaka - 3-5 days</p>*/}
            <MsgModal show={show} title={head} message={content} closeAction={closeAction} />
            <DeleteModal show={deleteshow} message={deletecontent} closeAction={closeDeleteAction} />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    pincode: state.LocationData.pin,
    address2: state.LocationData.area,
    city: state.LocationData.city,
    dist: state.LocationData.dist,
    state: state.LocationData.state,
    customerDetails: state.UserData.customerDetails,
    addressTypes: state.UserData.addressTypes,
    authenticated: state.AuthData.authenticated,
    loginData: state.AuthData.loginData,
    phone: state.UserData.phone,
    updatedUserPhoneNumber:state.AuthData.updatedUserPhoneNumber,
    companyid: state.HomeData.companyid,
    addaddressdata: state.CheckoutData.addaddressdata,
    customerid: state.UserData.customerid,
    deliverablePin: state.LocationData.deliverablePin,
    phoneCountryCode: state.AuthData.loginData && state.AuthData.loginData.data && state.AuthData.loginData.data.userPhoneCountryCode?state.AuthData.loginData.data.userPhoneCountryCode:state.UserData.phoneCountryCode,
    phoneCode: state.AuthData.loginData && state.AuthData.loginData.data && state.AuthData.loginData.data.userPhoneCode?state.AuthData.loginData.data.userPhoneCode:state.UserData.phoneCode,
    showState:state.HomeData.showState,
    showCity:state.HomeData.showCity,
    customerid:state.AuthData.loginData.data.id,
    showDistrict:state.HomeData.showDistrict,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency
})

export default connect(mapStateToProps, {
    setCustomerDetails,
    set_fname, set_lname, set_email, set_addressType,
    set_address1, set_address2, set_city, set_dist, set_state, set_country, set_pin, set_addressId, set_mname, addAddress, deleteAddress, validatePin, getCustomerDetails,set_phone
})(Address);