//import '../utils/wdyr';

import React, { useState, useEffect, lazy } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';

import ThemeSwitcher from 'react-css-vars';
//import '../assets/css/custom.scss';

import 'bootstrap/dist/css/bootstrap.css';

import "../assets/css/generic.css";
import "../assets/css/home-v2.css";
// import "../assets/css/nayami.css";


//.. TODO theme implementation on company basis
//import "../assets/css/shakersdaily.css";
//import "../assets/css/debanjana.css";
//import "../assets/css/suswadu.css";

import Loader from './Loader';

import { useWindowResizeListener } from '../utils/useHooks';
import { setMenuState, setMobileState } from '../actions/CategoryAction';
import { getCompanyId, getConfigInfo } from '../actions/HomeAction';
import HomeContainer from './HomeContainer';
import Products from '../components/Products';
import PinModal from '../components/PinModal';
import ProductDetails from '../components/ProductDetails';
import CategoryContainer from './CategoryContainer';
import { P_ProductInfo } from '../actions/P_ProductsAction';
import ImageUtil from "../utils/ImageUtil";
import SearchPanel from './SearchPanel';
import SectionPanel from './SectionPanel';
import FeaturePanel from './FeaturePanel';
import VariantModal from "../components/VariantModal";
import CheckOut from './CheckOut';
import PhonePeOrderConfirmation from './PhonePeOrderConfirmation';
import CartPreviewModal from './CartPreviewModal';
import NotFound from './NotFound';
import SignUp from './SignUp';
import ProfileDetails from './ProfileDetails';
import OrderGenerator from './OrderGenerator';
import WishList from './WishList';
import AboutUs from './AboutUs';
import PrivacyPolicy from './PrivacyPolicy';
import TermsCondition from './TermsCondition';
import CancelPolicy from './CancelPolicy';
import Contact from './Contact';
import InvoiceGenerator from './InvoiceGenerator';
import HomeV2 from './HomeV2';

import ScrollToTop from '../components/Scroll';
import P_AllProduct from '../components/P_AllProduct';
import CartView from '../components/header/CartView';
import Cart from '../components/header/Cart';
import PreviewItemList from '../components/cartpreview/PreviewItemList';
import PreviewItemDetailsList from '../components/cartpreview/PreviewItemDetailsList';
/* const HomeContainer = lazy(() => import('./HomeContainer'));
const Products = lazy(() => import('../components/Products'));
const PinModal = lazy(() => import('../components/PinModal'));
const ProductDetails = lazy(() => import('../components/ProductDetails'));
const CategoryContainer = lazy(() => import('./CategoryContainer'));
const SearchPanel = lazy(() => import('./SearchPanel'));
const VariantModal = lazy(() => import('../components/VariantModal'));
const CheckOut = lazy(() => import('./CheckOut'));
const CartPreview = lazy(() => import('./CartPreview'));
const NotFound = lazy(() => import('./NotFound')); */

import { Global, isDev } from '../utils/Env';
import {addStyle, getThemeColors} from '../utils/CommonUtil'; 
import OrderPlace from '../components/checkout/OrderPlace';
import CategorySection from '../components/CategorySection';
import { GoogleOAuthProvider } from '@react-oauth/google';


var styles;
const loadCSSforDevEnv = cssName => {
    if (isDev()) {
        import(/* webpackMode: "eager" */ `../assets/css/${cssName}.css`).then(imported => styles = imported.default);
    }
}

const App = props => {

    const { theme, getConfigInfo } = props;
    const [themePalette, setThemePalette] = useState([]);

    Global.callback.getConfigInfo_onComplete = () => {
        if(theme.name === 'random'){
            getRandomTheme();
            //console.log("themePalatte ", themePalette);
        }else{   
            if(!isDev()){
                addStyle(`./static/css/${theme.name}.css`);
            }else{
                loadCSSforDevEnv(theme.name)
            }
        }
        props.getCompanyId(props.url);
   
      }
      Global.callback.getcompanydetails_onComplete = () =>{
        let favicon=document.getElementById("favicon");
        favicon.href=ImageUtil.getImage(props.companyDetailsCurrency.favIconFileName)
      }
     
      let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');
      let secondaryColor = getComputedStyle(document.documentElement).getPropertyValue('--secondaryColor');
      let tertiaryColor = getComputedStyle(document.documentElement).getPropertyValue('--tertiaryColor');
      if(props.petTemplateDetails !=undefined &&  props.petTemplateDetails !=null)
       {
        primaryColor=props.petTemplateDetails.primaryColor
        secondaryColor=props.petTemplateDetails.secondaryColor
        tertiaryColor=props.petTemplateDetails.tertiaryColor
        console.log(`--color-primaryColor: ${primaryColor}`);
        console.log(`--color-secondaryColor: ${secondaryColor}`);
        console.log(`--color-tertiaryColor: ${tertiaryColor}`);

      }
    const getRandomTheme = async () => {
        const randomTheme = await getThemeColors();
      
        if (randomTheme.data) {
            setThemePalette(randomTheme.data.result);
          //console.log(`Got ${Object.entries(themePalette).length} themes -> ${themePalette}`)
        }
      }
    
    //.. default theme

//     useEffect(() => {
//         let primaryColor = getComputedStyle(document.documentElement).getPropertyValue('--primaryColor');
//         // color='KKDKDKDKKD';
//         // console.log(`--color-logo: ${primaryColor}`);
// if(props.petTemplateDetails !=null)
// {
//         props.petTemplateDetails.map((item, id) => {
//             primaryColor=item.primaryColor
//             console.log(`--color-logo: ${primaryColor}`);
//         });
//     }
//       }, [])

    const [defaultTheme, setDefaultTheme] = useState({
        themecolor: '',
        mobileCardHeight: '',
        desktopCardHeight: '',
		primaryColor:'',
		secondaryColor:'',
		tertiaryColor:''
    });

    const { width } = useWindowResizeListener();
    const { setMenuState, setMobileState } = props;

    //.. on resize menu observer
    useEffect(() => {
        if (width < 768) {
            setMenuState(false);
            setMobileState(true);
        } else {
            setMenuState(true);
            setMobileState(false);
        }
    }, [width, setMenuState, setMobileState]);

    //.. update theme
    useEffect(() => {
        let { themecolor, mobileCardHeight, desktopCardHeight,primaryColor,secondaryColor,tertiaryColor } = defaultTheme;
         setDefaultTheme({
            themecolor: theme && theme.color ? theme.color : themecolor,
            mobileCardHeight: theme && theme.mobile_card_height ? theme.mobile_card_height : mobileCardHeight,
            desktopCardHeight: theme && theme.desktop_card_height ? theme.desktop_card_height : desktopCardHeight,
			primaryColor:props.petTemplateDetails && props.petTemplateDetails.primaryColor?props.petTemplateDetails.primaryColor:primaryColor,
			secondaryColor:props.petTemplateDetails && props.petTemplateDetails.secondaryColor?props.petTemplateDetails.secondaryColor:secondaryColor,
			tertiaryColor:props.petTemplateDetails && props.petTemplateDetails.tertiaryColor?props.petTemplateDetails.tertiaryColor:tertiaryColor,
        });
		//console.log(defaultTheme, themePalette);
    }, [theme, themePalette,props.petTemplateDetails]);

    //... initiate load config
    useEffect(() => {
        getConfigInfo();
    }, [])

    useEffect(()=>{
       if(props.companyid){
       props.P_ProductInfo({ "Type": "1", "companyid": props.companyid });
       }
    },[props.companyid])

    return ( 
        <ThemeSwitcher theme={defaultTheme} elementId="root">
            <GoogleOAuthProvider clientId={props.companyDetailsCurrency?props.companyDetailsCurrency.googleClientId:''}>
            <Container fluid="true">
                <ScrollToTop>
                    <Routes>
                    <Route path="/" element={<P_AllProduct />} />
                    {/* This is for Loding all item at a time all item */}
                        <Route path="/" element={<HomeContainer />} >
						<Route path="sections" element={<Outlet />}>
							<Route path="/:slug" element={<SectionPanel />} />
						</Route>
                        <Route path="about-us" element={<AboutUs />} />
                        <Route path="contact-us" element={<Contact />} />
                        <Route path="privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="terms-conditions" element={<TermsCondition />} />
                        <Route path="cancel-policy" element={<CancelPolicy />} />
                            <Route path="products" element={<Outlet />}>
                                <Route path="/" element={<P_AllProduct />} />
                                <Route path="/:slug" element={<ProductDetails />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                            <Route path="about-us" element={<AboutUs />} />
                        <Route path="contact-us" element={<Contact />} />
                        <Route path="privacy-policy" element={<PrivacyPolicy />} />
                        <Route path="terms-conditions" element={<TermsCondition />} />
                        <Route path="cancel-policy" element={<CancelPolicy />} />
                            <Route path="AllProduct" element={<Outlet />}>
                                <Route path="/" element={<P_AllProduct />} />
                                <Route path="/:slug" element={<ProductDetails />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                            
                            <Route path="/Cart" element={<PreviewItemDetailsList/>} />
                            <Route path="/OrderPlace" element={<OrderPlace/>} />
							<Route path="/PhonePeConfirmation/:slug" element={<PhonePeOrderConfirmation />} />
							<Route path="profile" element={<Outlet />} >
								<Route path="/" element={<ProfileDetails />} />
								<Route path="*" element={<NotFound />} />
								</Route>
                            <Route path="/" element={<FeaturePanel />} />
							<Route path="home-v2" element={<HomeV2 />} />
							<Route path="wishlist" element={<WishList />} />
                            <Route path="search" element={<SearchPanel />} />
                            <Route path="category" element={<Outlet />} >
                                <Route path="/" element={<Outlet />} />
                                <Route path="/:catid" element={<CategoryContainer />} />
                                <Route path="/:catid/:subcatid" element={<CategoryContainer />} />
								<Route path="/:catid/:subcatid/:subsubcatid" element={<CategoryContainer />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                            <Route path="CategorySection" element={<Outlet />} >
                                <Route path="/" element={<Outlet />} />
                                <Route path="/:catid" element={<CategorySection />} />
                                <Route path="/:catid/:subcatid" element={<CategorySection />} />
								<Route path="/:catid/:subcatid/:subsubcatid" element={<CategorySection />} />
                                <Route path="*" element={<NotFound />} />
                            </Route>
                            <Route path="*" element={<NotFound />} />
                        </Route>
                            <Route path="register" element={<SignUp />} />
                        <Route path="/checkout" element={<CheckOut />} />
						<Route path="order_print" element={<Outlet />}>
                                <Route path="/" element={<NotFound />} />
                                <Route path="/:slug" element={<OrderGenerator />} />
                                <Route path="*" element={<NotFound />} />
                        </Route>
						<Route path="invoice" element={<Outlet />}>
                                <Route path="/" element={<NotFound />} />
                                <Route path="/:slug" element={<InvoiceGenerator />} />
                                <Route path="*" element={<NotFound />} />
                        </Route>
                    </Routes>
                    <PinModal />
                    <VariantModal />
                    <CartPreviewModal />
                    <ToastContainer autoClose={5000} />
                    <Loader />
                </ScrollToTop>
            </Container>
            </GoogleOAuthProvider>
        </ThemeSwitcher>
    )
}

const mapStateToProps = state => ({
    theme: state.HomeData.theme,
	url: state.HomeData.url,
    companyid: state.HomeData.companyid,
    petTemplateDetails: state.petProductData.userP_AllProductInfo.data,
    companyDetailsCurrency: state.HomeData.companyDetailsCurrency
})

export default connect(mapStateToProps, {P_ProductInfo,setMenuState, setMobileState, getCompanyId, getConfigInfo })(App);
