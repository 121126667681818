import React, { useState, useEffect } from 'react';
import { Button, Row, Col, Container, Navbar, Nav, NavDropdown, Form, FormControl, InputGroup, ListGroup, Card, Tab, Tabs, Table, Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Global } from '../utils/Env';
import { getCustomerProfile, saveCustomerProfile, setProfoption } from '../actions/UserAction';
import { changePassword, setUpdatedPhoneNumber } from '../actions/AuthAction';
import MsgModal from './MsgModal';
import { setLoginModal, setMsgModal } from '../actions/AuthAction';
import AllOrders from '../components/OrderHistory/AllOrders';
import WishList from './WishList';
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import 'react-phone-number-input/style.css';
import { validatePin } from '../actions/LocationAction';


const ProfileDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [option_select, setOption_select] = useState(null);
  const [disable, setdisable] = useState(true);
  const [profileView, setProfileView] = useState('Edit Profile');
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    //console.log(props.authenticated);
    if (!props.authenticated) {
      navigate('/login', { replace: true });
    }
  }, [])

  useEffect(() => {
    if (props.authenticated) {
      props.getCustomerProfile({ "CompanyId": props.companyid, "UserId": props.loginData.data.id });
    }
    else {
      props.setLoginModal(true);
    }
  }, []);

  useEffect(() => {
    if (props.profile_option && (props.profile_option == "fourth" || props.profile_option == "seventh" || props.profile_option == "first" || props.profile_option == "fifth")) {
      //console.log(props.profile_option);
      setOption_select(props.profile_option);
      props.setProfoption('');
    }

  }, [props.profile_option]);

  const [validated, setValidated] = useState(false);
  const [profileCheck, setProfileCheck] = useState(false);
  const [email, setEmail] = useState('');
  const [fname, setFname] = useState('');
  const [mname, setMname] = useState('');
  const [lname, setLname] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [dist, setDist] = useState('');
  const [state, setState] = useState('');
  const [pin, setPin] = useState('');
  const [country, setCountry] = useState('');
  const [pwdvalidated, setPwdValidated] = useState(false);
  const [pwdCheck, setPwdCheck] = useState(false);

  const [countryCodeData, setCountryCode] = useState();
  const [phoneCodeValue, setPhoneCode] = useState();
  const [phone, setPhone] = useState('');
  const [onlyPhone, onlyPhoneData] = useState();

  const emptypwdForm = {
    old_pwd: '',
    new_pwd: '',
    re_new_pwd: ''
  }


  const [pwdData, setPwdData] = useState(emptypwdForm);
  /** @function
 * @name profile_onComplete */
  Global.callback.getprofile_onComplete = () => {
    if (typeof props.profile.data != "undefined" && props.profile.data != null) {
      setEmail((props.profile.data.custEmail == null) ? '' : props.profile.data.custEmail);
      setFname((props.profile.data.custFName == null) ? '' : props.profile.data.custFName);
      setMname((props.profile.data.custMName == null) ? '' : props.profile.data.custMName);
      setLname((props.profile.data.custLName == null) ? '' : props.profile.data.custLName);
      setAddress1((props.profile.data.billAddr1 == null) ? '' : props.profile.data.billAddr1);
      setAddress2((props.profile.data.billAddr2 == null) ? '' : props.profile.data.billAddr2);
      setCity((props.profile.data.billCityTownVill == null) ? '' : props.profile.data.billCityTownVill);
      setDist((props.profile.data.billDist == null) ? '' : props.profile.data.billDist);
      setState((props.profile.data.billState == null) ? '' : props.profile.data.billState);
      setPin((props.profile.data.billPIN == null) ? '' : props.profile.data.billPIN);
      setPhone((props.profile.data.custPhone == null) ? '' : props.profile.data.custPhone);
      setCountry((props.profile.data.billCountry == null) ? '' : props.profile.data.billCountry);
      setCountryCode((props.profile.data.custCountryCode == null) ? '' : props.profile.data.custCountryCode);
      setPhoneCode((props.profile.data.custPhoneCode == null) ? '' : props.profile.data.custPhoneCode);
    }
  }

  Global.callback.saveprofile_onComplete = () => {
    props.setUpdatedPhoneNumber(phone);
    props.setMsgModal({
      "title": "User Information",
      "message": props.saveprofile.data.msg,
      "show": true
    });
  }
  /** @function
* @name password_onComplete */
  Global.callback.password_onComplete = () => {
    //console.log("Password_onComplete ");
    props.setMsgModal({
      "title": "Password Change",
      "message": props.chgepassword.message,
      "show": true
    });
  }
  const onErrorLoginClose = () => {
    props.setMsgModal({
      "title": "",
      "message": "",
      "show": false
    });
  }
  /** @function
* @name setFinalData */


  //functions for Changing Profule Data Start
  /** @function
 * @name sendProfileData */
  const sendProfileData = (e) => {
    let obj = {
      "Token": props.loginData.data.token, "CustomerProfileDtl": {
        "CustId": props.profile.data.custId,
        "CompId": props.profile.data.compId,
        "CustFName": fname,
        "CustMName": mname,
        "CustLName": lname,
        "CustPhone": phone ? phone.replace(phoneCodeValue, '') : '',
        "CustEmail": email,
        "BillAddr1": address1,
        "BillAddr2": address2,
        "BillCityTownVill": city,
        "BillPIN": pin,
        "BillDist": dist,
        "BillState": state,
        "BillCountry": country,
        "UserId": props.loginData.data.id,
        "CustPhoneCode": phoneCodeValue,
        "CustCountryCode": countryCodeData,
      }
    }
    props.saveCustomerProfile(obj);
  }
  const setPhoneAndCountryCode = () => {
    var onlyPhoneCode = '';
    if (countryCodeData != '' && countryCodeData !== 'undefined') {
      onlyPhoneCode = "+" + getCountryCallingCode(countryCodeData);
      setPhoneCode(onlyPhoneCode);
      const onlyPhoneNumber = phone.replace(onlyPhoneCode, '')
      setPhone(onlyPhoneNumber);
    }
  }
  Global.callback.validatePin_onComplete = () => {
    //setFormData({ ...formData, city: props.city, dist: props.dist, state: props.state });  
    setCity(props.city);
    setDist(props.dist);
    setState(props.state);
    setCountry(props.country);

  }

  /** @function
 * @name handleOnChange */
  const handleOnChange = (e) => {
    e.preventDefault();
    var onlyPhoneCode = '';
    if (countryCodeData != '' && countryCodeData !== undefined) {
      onlyPhoneCode = "+" + getCountryCallingCode(countryCodeData);
      setPhoneCode(onlyPhoneCode);
    }
    if (e.target.name == 'pin' && (e.target.value.length == 0 || (e.target.value.length <= 6 && (e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')))) {
      setPin(e.target.value);
      if (e.target.value.length === 6) {
        //props.validatePin({ "pinCode": e.target.value, "companyid": props.companyid });
      }
    }

    if ((e.target.name == 'state' || e.target.name == 'city' || e.target.name == 'country') && !(e.target.value.charAt(e.target.value.length - 1) >= '0' && e.target.value.charAt(e.target.value.length - 1) <= '9')) {
      //console.log(e.target.value);
      if (e.target.name == 'state')
        setState(e.target.value)
      if (e.target.name == 'city')
        setCity(e.target.value)
      if (e.target.name == 'country')
        setCountry(e.target.value)
    }
    if (e.target.name == 'fname')
      setFname(e.target.value);
    if (e.target.name == 'mname')
      setMname(e.target.value);
    if (e.target.name == 'lname')
      setLname(e.target.value);
    if (e.target.name == 'address1')
      setAddress1(e.target.value);
    if (e.target.name == 'address2')
      setAddress2(e.target.value);
    if (e.target.name == 'email')
      setEmail(e.target.value);
    if (e.target.name == 'dist')
      setDist(e.target.value);
  }
  /** @function
 * @name formChange */
  const formChange = (event) => {
    setValidated(true);
    const form = event.currentTarget;
    if (form.checkValidity() === false)
      setProfileCheck(false);
    else if (form.checkValidity() == true)
      setProfileCheck(true);
  }
  /** @function
 * @name saveProfile */
  const saveProfile = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
      props.setMsgModal({
        "title": "Profile Page Validation Error",
        "message": "Data Incorrect. Please check and try again",
        "show": true
      });
    }
    event.preventDefault();

    if (form.checkValidity() === true)
      sendProfileData();

  }




  const pwdFieldChange = (e) => {
    setPwdData({ ...pwdData, [e.target.name]: e.target.value });
  }
  /** @function
 * @name pwdFormChange */
  const pwdFormChange = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false)
      setPwdCheck(false);
    else if (form.checkValidity() == true) {
      setPwdCheck(true);
    }
  }

  const PwdChange = (event) => {
    //console.log("saving Pwd changes");
    const form = event.currentTarget;
    setPwdValidated(true);
    if (form.checkValidity() === false) {
      event.stopPropagation();
      props.setMsgModal({
        "title": "Profile Page Validation Error",
        "message": "Data Incorrect. Please check and try again",
        "show": true
      });
    }
    event.preventDefault();

    if (form.checkValidity() === true) {
      //.. Save DETAILS OF THE FORM
      if (pwdData.re_new_pwd == pwdData.new_pwd) {
        let obj = {
          "Token": props.loginData.data.token,
          "CompanyId": props.companyid,
          "UserId": props.loginData.data.id,
          "OldPassword": pwdData.old_pwd,
          "NewPassword": pwdData.new_pwd
        }
        // console.log(obj);
        props.changePassword(obj);
      }
      else
        props.setMsgModal({
          "title": "Profile Page Validation Error",
          "message": "Please check data. New password and Confirm new password are not same",
          "show": true
        });;
    }
    event.stopPropagation();
  }
  const EnableEditMode = () => {
    if (disable) {
      setdisable(false);
      setProfileView('View Profile')
    }
    else {
      setdisable(true);
      setProfileView('Edit Profile')
    }
  }

  //functions for Pwd change ends


  return (
    <React.Fragment>



      <Col className="pl-0-mob pr-0-mob">

        <div>

          <Tab.Container key={option_select} id="left-tabs-example" defaultActiveKey={option_select}>
            <Row>
              <Col sm={2} className="pl-0-mob pr-0-mob">
                <div className="pt-4 pb-4">
                  <Nav variant="pills" className="flex-column custom-sidetab">
                    <Nav.Item className="border-bottom text-left">
                      <Nav.Link eventKey="fourth" className='nav-link-new' onClick={() => setOption_select("fourth")}>Order History</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item className="border-bottom text-left">
                      <Nav.Link eventKey="seventh" className='nav-link-new' onClick={() => setOption_select("seventh")}>Wish List</Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item className="border-bottom text-left">
                      <Nav.Link eventKey="first" className='nav-link-new' onClick={() => setOption_select("first")}>User Info</Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="border-bottom text-left">
                      <Nav.Link eventKey="fifth" className='nav-link-new' onClick={() => setOption_select("fifth")}>Change Password</Nav.Link>
                    </Nav.Item>

                  </Nav>
                </div>
              </Col>
              <Col sm={9} className="border-left border-none-mob pl-0-mob pr-0-mob pl-0 pr-0">
                <div className="p-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="fourth">
                      <h3 className="text-center pt-4 mb-4">Order History</h3>
                      <div>
                        {option_select == "fourth" ? <AllOrders /> : null}
                      </div>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="seventh">
                      <div>
                        {option_select == "seventh" ? <WishList /> : null}
                      </div>
                    </Tab.Pane> */}
                    <Tab.Pane eventKey="first">
                      <h3 className="text-center mb-3 pt-4">User Information</h3>


                      {/*Starting the form for saving the userData for the Customer*/}
                      <Row className="mt-2">
                        <Col className='text-right'>
                          <button className="rounded-0 secondary-button" type="submit" onClick={EnableEditMode} value="EditProfile">&nbsp;&nbsp;{profileView}&nbsp;&nbsp;</button>{' '}
                        </Col>
                      </Row>
                      <Form noValidate validated={validated} onSubmit={saveProfile} onChange={formChange} className="mb-0">
                        <Row className="infoppage">
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1 google-colorstyle">First Name <i className="text-danger">*</i></Form.Label>
                              <Form.Control type="text" placeholder="Your First Name" onChange={handleOnChange} name="fname" value={fname} autoComplete="none" required disabled={disable} />
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1 google-colorstyle">Middle Name (Optional)</Form.Label>
                              <Form.Control type="text" placeholder="Your Middle Name" onChange={handleOnChange} name="mname" value={mname} autoComplete="none" disabled={disable} />
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1">Last Name <i className="text-danger">*</i></Form.Label>
                              <Form.Control type="text" placeholder="Your Last Name" onChange={handleOnChange} name="lname" value={lname} autoComplete="none" required disabled={disable} />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row className="infoppage">
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1">Phone <i className="text-danger">*</i></Form.Label>
                              {/* <Form.Control type="text" placeholder="Your Phone " name="phone" onChange={handleOnChange} value={phone} autoComplete="none" pattern='[0-9]{10}' required /> */}
                              <PhoneInput
                                countrySelectProps={{ unicodeFlags: false }}
                                countryCallingCodeEditable={false}
                                international
                                defaultCountry={props.countryCode ? props.countryCode.toUpperCase() : 'IN'}
                                value={phone}
                                name="phone"
                                onChange={setPhone} onCountryChange={setCountryCode} disabled={disable} />

                            </Form.Group>
                          </Col>
                          <Col md={8} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1">Email </Form.Label>
                              <Form.Control type="text" placeholder="Your Email" name="email" onChange={handleOnChange} value={email} pattern='[a-zA-Z0-9_.]{1}[a-zA-Z0-9_.]*@[a-zA-Z]{1}[a-zA-Z]*[.]{1}[a-z]{1}[a-z]*' autoComplete="none" disabled={disable} />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="infoppage">

                        </Row>
                        {/*Address mini form begin*/}

                        {/*<Row className="infoppage">
                          <Col sm={6} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1"><b>Address Line 1 <i className="text-danger">*</i></b></Form.Label>
                              <Form.Control type="text" placeholder="Number & Street" onChange={handleOnChange} name="address1" value={address1} autoComplete="none" required disabled={disable}/>
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1"><b>Address Line 2 (Optional)</b></Form.Label>
                              <Form.Control placeholder="Number & Street" onChange={handleOnChange}
                                name="address2" value={address2} autoComplete="none" placeholder="Address Line 2" disabled={disable}/>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="infoppage">

                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1"><b>Pin Code <i className="text-danger">*</i></b></Form.Label>
                              <Form.Control name="pin" value={pin} onChange={handleOnChange} autoComplete="none" required placeholder="Pin Code" pattern='[0-9]{6}' disabled={disable}/>
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1"><b>District <i className="text-danger">*</i></b></Form.Label>
                              <Form.Control name="dist" value={dist} onChange={handleOnChange} autoComplete="none" required placeholder="District" required disabled={disable}/>
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1"><b>State <i className="text-danger">*</i></b></Form.Label>
                              <Form.Control name="state" value={state} onChange={handleOnChange} autoComplete="none" required placeholder="State" required disabled={disable}/>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1"><b>City <i className="text-danger">*</i></b></Form.Label>
                              <Form.Control name="city" value={city} onChange={handleOnChange} autoComplete="none" required placeholder="City" required disabled={disable}/>
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1"><b>Country <i className="text-danger">*</i></b></Form.Label>
                              <Form.Control name="country" value={country} onChange={handleOnChange} autoComplete="none" required placeholder="Country" required disabled={disable}/>
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="infoppage">

                        </Row>*/}
                        <Row className="mt-2">
                          <Col className='text-right'>
                            <Button className="rounded-0 primary-button pt-2 pb-2 min-same-widthxs" type="submit" value="Submit">&nbsp;&nbsp;SAVE&nbsp;&nbsp;</Button>{' '}
                          </Col>
                        </Row>
                      </Form>

                      {props.modal && props.modal.show && <MsgModal show={props.modal.show} title={props.modal.title} message={(props.modal.message == '') ? 'Error while saving' : props.modal.message} closeAction={onErrorLoginClose} />}

                      {/*Form ends for saving the user Data*/}

                    </Tab.Pane>



                    <Tab.Pane eventKey="fifth">

                      <Form noValidate validated={pwdvalidated} onChange={pwdFormChange} onSubmit={PwdChange} className="mb-0">

                        <h3 className="text-center pt-4 mb-5">Change Password</h3>
                        <Row className="infoppage">
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1 google-colorstyle form-label">Enter Old Password</Form.Label>
                              <Form.Control placeholder="Old Password" type="password" name="old_pwd" onChange={pwdFieldChange} required disabled={disable} />
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12} >
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1 google-colorstyle form-label">Enter New Password</Form.Label>
                              <Form.Control placeholder="New Password" type="password" name="new_pwd" onChange={pwdFieldChange} required disabled={disable} />
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12}>
                            <Form.Group>
                              <Form.Label className="fsize14 mb-1 google-colorstyle form-label">Retype New Password</Form.Label>
                              <Form.Control placeholder="Retype New Password" type="password" name="re_new_pwd" onChange={pwdFieldChange} required disabled={disable} />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row className="mt-2">
                          <Col className="text-right">
                            <Button disabled={disable} className="rounded-0 primary-button pt-2 pb-2 min-same-widthxs" type="submit" value="Submit">&nbsp;&nbsp;SAVE&nbsp;&nbsp;</Button>{' '}
                          </Col>
                        </Row>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                      </Form>
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth">
                      <h3 className="text-center">Log Out</h3>
                      <div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                      </div>
                    </Tab.Pane>

                    {/* form for change password ends*/}
                  </Tab.Content>
                </div>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Col>
      <br />
      <br />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  companyid: state.HomeData.companyid,
  authenticated: state.AuthData.authenticated,
  loginData: state.AuthData.loginData,
  profile: state.UserData.profile,
  saveprofile: state.UserData.saveprofile,
  countryCode: state.HomeData.servicePhoneCountryCode,
  phoneCode: state.HomeData.servicePhoneCode,
  chgepassword: state.AuthData.chgepassword,
  profile_option: state.UserData.profile_option,
  city: state.LocationData.city,
  dist: state.LocationData.dist,
  state: state.LocationData.state,
  country: state.LocationData.country,
})

export default connect(mapStateToProps, {
  getCustomerProfile, saveCustomerProfile, changePassword, setMsgModal, setProfoption, setLoginModal, setUpdatedPhoneNumber
  , validatePin
})(ProfileDetails);

//export default ProfileDetails;