import { apiAction } from '../middleware/api/apiActions';
import API from './APIConst';

// Description on const CATEGORYLIST

const ADDTOCART = "ADDTOCART";
const SETCARTBOUNCE = "SETCARTBOUNCE";
const ADDQUANTITY = "ADDQUANTITY";
const SUBTRACTQUANTITY = "SUBTRACTQUANTITY";
const REMOVE_FROM_CART = "REMOVE_FROM_CART";
const CLEAR_CART = "CLEAR_CART";

const PREVIEW_CART = "PREVIEW_CART";
const CHECKEDOUT_CART = "CHECKEDOUT_CART";
const GET_CART="GET_CART";
const SAVE_CART="SAVE_CART";
const GET_CART_PRICE="GET_CART_PRICE";

//.....................................

//...................................................................
/**
 * Action addToCart defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const addToCart = param => ({
    type: ADDTOCART,
    payload: param
});

//...................................................................
/**
 * Action addQuantity defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const addQuantity = param => ({
    type: ADDQUANTITY,
    payload: param
});

//...................................................................
/**
 * Action subtractQuantity defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const subtractQuantity = param => ({
    type: SUBTRACTQUANTITY,
    payload: param
});

//...................................................................
/**
 * Action subtractQuantity defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const removeItem = param => ({
    type: REMOVE_FROM_CART,
    payload: param
});

//...................................................................
/**
 * Action clearCart defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const clearCart = () => ({
    type: CLEAR_CART,
    payload: null
});

//...................................................................
/**
 * Action setCartBounce defined.
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setCartBounce = param => ({
    type: SETCARTBOUNCE,
    payload: param
});


//...................................................................
/**
 * Action Preview cart .
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const previewCart = param => ({
        type: PREVIEW_CART,
        payload: param
});
//...................................................................
/**
 * Action setCheckOutCartState >> cart clicked for checkout .
 * @constructor
 * @param {string} param - Pass param to update the payload.
 */
export const setCheckOutCartState = param => ({
    type: CHECKEDOUT_CART,
    payload: param
});

export const saveCart = (obj) => {
    return apiAction({
        url: API.SAVE_CART,
        method: "post",
        data: obj,
        onSuccess: savedCart,
        onFailure: () => console.log("Error occured in saveCart"),
		callback:'savecart',
        label: SAVE_CART
    });
}
export const saveCartbuynow = (obj) => {
    return apiAction({
        url: API.SAVE_CART,
        method: "post",
        data: obj,
        onSuccess: savedCart,
        onFailure: () => console.log("Error occured in saveCart"),
		callback:'savecartbuynow',
        label: SAVE_CART
    });
}

export const savedCart = param => ({
    type: SAVE_CART,
    payload: param
});

export const getCart = (obj) => {
    return apiAction({
        url: API.GET_CART,
        method: "post",
        data: obj,
        onSuccess: gotCart,
        onFailure: () => console.log("Error occured in getCart"),
        label: GET_CART
    });
}

export const getCartbuynow = (obj) => {
    return apiAction({
        url: API.GET_CART,
        method: "post",
        data: obj,
        onSuccess: gotCart,
        onFailure: () => console.log("Error occured in getCart"),
        callback:'getcartbuynow',
        label: GET_CART
    });
}

export const gotCart = param => ({
    type: GET_CART,
    payload: param.data
});

export const getCartPrice = (obj) => {
    return apiAction({
        url: API.GET_CART_PRICE,
        method: "post",
        data: obj,
        onSuccess: gotCartPrice,
        onFailure: () => console.log("Error occured in getCartPrice"),
        label: GET_CART_PRICE
    });
}

export const gotCartPrice = param => ({
    type: GET_CART_PRICE,
    payload: param.data
});

//...................................................................


//...................................................................
/**
 * set up with initial state.
 */
const initialState = {
    preview: false,
    totalItems: 0,
    totalAmount: 0,
    cartBounce: false,
    cartList: [],
    checkOutClicked: false,
	savecartdata:{}
}

const getTotalAmount = (x) => {
    let totalAmount = 0
    for (let i = 0; i < x.length; i++) {
      let price = x[i].offer?x[i].offer.offerSalePrice:x[i].price_regular
      totalAmount += price * parseInt(x[i].quantity);
    }
    return totalAmount
  }

/**
 * reducer .
 * @constructor
 * @param {string} state - state of the data shape.
 * @param {string} action - action payload passed for data updates.
 */
let reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CART: {
            return {...state, cartList:(action.payload==null?[]:action.payload), totalItems:(action.payload==null?0:action.payload.length),totalAmount: (action.payload==null?0:getTotalAmount(action.payload)), cartBounce: true }
        }
		case GET_CART_PRICE: {
            return {...state, cartList:(action.payload==null?[]:action.payload), totalItems:(action.payload==null?0:action.payload.length),totalAmount: (action.payload==null?0:getTotalAmount(action.payload)), cartBounce: true }
        }
		case SAVE_CART: {
            return {...state, savecartdata:action.payload}
        }
        case ADDTOCART: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount, cartBounce: true }
        }
        case ADDQUANTITY: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount }
        }
        case SUBTRACTQUANTITY: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount}
        }
        case REMOVE_FROM_CART: {
            const { cart, totalAmount } = action.payload
            return {...state, cartList: cart, totalItems: cart.length, totalAmount: totalAmount}
        }
        case CLEAR_CART: {
            return {...state, cartList: [], totalItems: 0, totalAmount: 0} 
        }
        case PREVIEW_CART: {
            return {...state, preview: action.payload} 
        }
        case CHECKEDOUT_CART: {
            return {...state, checkOutClicked: action.payload} 
        }
        default:
            return state;
    }
}

//...................................................................
//.. selectors 

//...................................................................
/**
 * Export Object (Action + Reducer)
 */
const CartAction = {
    reducer: reducer,
    actions: {
        addToCart,
        addQuantity,
        subtractQuantity,
        setCartBounce,
        removeItem,
		getCart,
		saveCart,
		getCartPrice,
        saveCartbuynow
    }
}

export default CartAction