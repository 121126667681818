import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Card, Form, Container } from "react-bootstrap";
import P_Loading from "./P_Loading";
import { connect } from "react-redux";
import { formatSymbolFromCurrency } from '../utils/CommonUtil';
import { addQuantity, subtractQuantity, addToCart, setCartBounce, saveCart, getCart, saveCartbuynow, getCartbuynow, previewCart, getCartPrice } from "../actions/CartAction";
import { setUpdatedPhoneNumber, setLoginModal, set_phoneNumber, set_password, set_phone } from '../actions/AuthAction';
import { Global } from '../utils/Env';
import { selectCheckOutJSON, checkOutOrder, clearOrder } from '../actions/CheckoutAction';
import { useNavigate, Link } from 'react-router-dom';
import CheckOut from "../container/CheckOut";
import PhoneNumberAdd from "./PhoneNumberAdd";
import { toast } from "react-toastify";
/**
 * Creates a new LoadingProduct.
 * @class
 */
const P_ProductLoading = (props) => {
  const [state, setState] = useState("");
  const [show, setShow] = useState(false);
  const [showbutton, setshowbutton] = useState(true);
  const navigate = useNavigate();
  const { product } = props;
  const closeAction = () => {
    setState("");
  }

  useEffect(() => {
    if (props.authenticated) {
      if (props.cartItems != null) {
        props.cartItems.map((item, id) => {
          if (item.id == props.productId) {
            setShow(true)
          }
        })
      }
    }
  }, []);
  // useEffect(() => {
  //   buttonshoworhide();
  // }, []);
  
   const onGotoCart = () =>navigate("/Cart");
  //   const buttonshoworhide=()=>{
  //     if( props.sectionFor ==2 && props.sectionType == 16 ){
  //     console.log("inside 16", props  )
  //       setshowbutton(false);
  //     }
  //   }
  const addToCart = () => {
    if (props.authenticated) {
      if ((props.loginData && props.loginData.data && props.loginData.data.phone != "") || props.updatedUserPhoneNumber != "") {
        let cart = [...props.cartList];
        cart.push({ ...product, quantity: 1 });
        let totalAmount = getTotalAmount(cart)
        props.addToCart({ cart: cart, totalAmount: totalAmount });
        props.saveCart(createobj(product, "add"));
        props.setLoginModal(false);
        toast.info("Item added to cart");
        setShow(true);
      }
      else {
        setState("PhoneReInsert");
      }
    }
    else {
      props.setLoginModal(true);
    }
    setTimeout(() => props.setCartBounce(false), 1000)
  }
  const buyNow = () => {
    if (props.authenticated) {
      if ((props.loginData && props.loginData.data && props.loginData.data.phone != "") || props.updatedUserPhoneNumber != "") {
        props.saveCartbuynow(createobj(product, "add"));
      }
      else {
        setState("PhoneReInsert");
      }
      //  let cartList0 = [...props.cartList];
      //  let cartList = [...props.cartList];
      //  let cartList2 = (createobj(product,"add"));
      //  cartList.push(...cartList,cartList2);
      //props.checkOutOrder(selectCheckOutJSON());
      //  selectCheckOutJSON();
      // navigate("/checkout", { state: { process: true } });
    }
    else {
      props.setLoginModal(true);
    }
  }
  console.log("incallback",props);
  Global.callback.savecartbuynow_onComplete = async () => {
    console.log("in save cart callback");
    await props.getCartbuynow({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
  }
  Global.callback.getcartbuynow_onComplete = async () => {
    await props.checkOutOrder(props.checkoutData);
  }
  function selectCheckOutJSON() {
    let obj = {
      "companyid": props.companyid,
      "ordered": {
        "items": props.cartList.map(item => {
          return {
            id: item.id,
            variantid: item.variantid,
            rate: item.promo_price ? item.promo_price : item.price_regular,
            quantity: item.quantity,
            status: "order placed"
          }
        })
      }
    }
    return obj;
  }
  const getTotalAmount = (x) => {
    let totalAmount = 0
    for (let i = 0; i < x.length; i++) {
      let price = (x[i].promo_price ? x[i].promo_price : x[i].price_regular)
      totalAmount += price * parseInt(x[i].quantity);
    }
    return totalAmount
  }
  Global.callback.savecart_onComplete = () => {
    props.getCart({ "companyid": props.companyid, "customerid": parseInt(props.loginData.data.customerId) });
  }
  console.log("props.companyDetailsCurrency.ShowItemlevelButton",props.companyDetailsCurrency.showItemlevelButton)
  const createobj = (prod, str) => {
    let obj = {
      "companyid": props.companyid,
      "customerid": parseInt(props.loginData.data.customerId),
      "userid": props.loginData.data.id,
      "carttItemVariant": [{
        "itemId": props.productId,
        "itemVariantId": (props.variantId) ? props.variantId : 0,
        "itemQty": (str == "add") ? 1 : 0,
      }]
    }
    return obj;
  }
  console.log("isdes",props.companyDetailsCurrency.isDescriptionPrimary)
  const sectionvalue = (typeof props.section != "undefined") ? props.section : "";
  const css = props.mobileState
    ? { height: "168.5px", width: "155px", marginLeft: "9px", marginTop: "9px" }
    : { height: "269px", width: "269px", marginLeft: "9px", marginTop: "9px" };
  return (
    <React.Fragment>
      <div className="item-on-div">
        {props.stockQty != null && props.stockQty <= 0 ?
          <Link to={`/categorySection/${props.sectionFor}/${props.productId}/${props.variantId}`}>
            <div class="out-of-stock">
              <h4 classname="m-0">Out Of Stock</h4>
            </div>
          </Link> : ''}

          <div className="garment-item-div mb-3">
                      <div className="garment-type-inner">
                          <Link to={`/categorySection/${props.sectionFor}/${props.productId}/${props.variantId}`}>
            
                            {props.offer && props.offer.offerValue ? <span className="position-absolute z-index1 percent-offer rounded-0 font-weight-normal">{props.offer.offerIsPercentage ? props.offer.offerValue : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (props.offer.offerValue))} {props.offer.offerIsPercentage ? '%' : ''} OFF</span> : null}
                            {props.companyDetailsCurrency.isDescriptionPrimary==true?
                            <img src={props.src.substring(0, props.src.lastIndexOf(".")).concat("_Medium").concat(props.src.substring(props.src.lastIndexOf("."), props.src.length))}  className="w-100 abs-img" alt={props.alt} />
                            : <img src={props.src.substring(0, props.src.lastIndexOf(".")).concat("_Thumbnail").concat(props.src.substring(props.src.lastIndexOf("."), props.src.length))}  className="w-100 abs-img" alt={props.alt} />
                            }
                          </Link>
                        
                        </div>
                     
          </div>
          
        {/* <div className="prod">
          <Link to={`/categorySection/${props.sectionFor}/${props.productId}/${props.variantId}`}>
            
            {props.offer && props.offer.offerValue ? <span className="position-absolute z-index1 percent-offer rounded-0 font-weight-normal">{props.offer.offerIsPercentage ? props.offer.offerValue : formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), (props.offer.offerValue))} {props.offer.offerIsPercentage ? '%' : ''} OFF</span> : null}
            <P_Loading css={css} href={`/categorySection/${props.sectionFor}/${props.productId}/${props.variantId}`} src={props.src.substring(0, props.src.lastIndexOf(".")).concat("_Thumbnail").concat(props.src.substring(props.src.lastIndexOf("."), props.src.length))} productId={props.productId} alt={props.alt} variantId={props.variantId} sectionFor={props.sectionFor} />
          </Link>
        </div> */}
        <div className="bottom-hov-div">
          <Row>
            <Col className="col-md-12 mt-2 col-12">
              <div className="product_name text-center">
                <a className="linecount-text" href={`/categorySection/${props.sectionFor}/${props.productId}/${props.variantId}`} src={props.src} productId={props.productId} alt={props.alt} variantId={props.variantId} sectionFor={props.sectionFor}> {" "}
                  {props.alt}</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="pl-10">
              <div class="Price-renge text-center mb-2 mb-0-mob">
                {props.mrp && props.salePrice && props.mrp > props.salePrice ? <div class="price-new mr-1">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), '')} {props.salePrice} <span class="price-old">{props.mrp}</span></div> : <div class="price-new mr-1">{formatSymbolFromCurrency((!props.companyDetailsCurrency.currency.currencyCode ? 'INR' : props.companyDetailsCurrency.currency.currencyCode), '')} {props.salePrice}</div>}
              </div>
            </Col>
          </Row>
          {props.companyDetailsCurrency.showItemlevelButton == false ? null :
          props.sectionFor ==2 && props.sectionType == 16 ? null :
          <div className="addtocart-div p-abcenterbutton text-center">
            <button className="secondary-round-button min-same-width buy-now-addcart" onClick={buyNow} style={{ marginBottom: "20px" }}>
              Buy Now
            </button>
            {show? <button className="primary-button min-same-width buy-now-addcart" onClick={onGotoCart}>
              Go to cart
            </button>:<button className="primary-button min-same-width buy-now-addcart" onClick={addToCart}>
              Add to cart
            </button>}
          </div>}
          <div className="addtocart-div text-center">

          </div>
        </div>

      </div>

      {/* <div className="pl-3 pr-3 tab-width-cont col-md-4 col-12">
        <div className="product-action pos-rel-mobile pos-sta-tab">
          <div className="bttn">
            &nbsp;&nbsp;&nbsp;&nbsp;
        </div>
        </div>
      </div> */}
      {(state == "PhoneReInsert") ? (
        <PhoneNumberAdd showValue={true} closeAction={closeAction} />
      ) : null}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  mobileState: state.CategoryData.isMobileMenu,
  cartList: state.CartData.cartList,
  loginData: state.AuthData.loginData,
  authenticated: state.AuthData.authenticated,
  loginhead: state.AuthData.loginhead,
  companyid: state.HomeData.companyid,
  cartItems: state.CartData.cartList,
  checkoutDetails: state.CheckoutData.checkoutDetails,
  totalAmount: state.CartData.totalAmount,
  checkoutData: selectCheckOutJSON(state),
  cartData: state,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency,
  updatedUserPhoneNumber: state.AuthData.updatedUserPhoneNumber
});

//export default connect(mapStateToProps)(P_ProductLoading);
export default connect(mapStateToProps, { saveCartbuynow, getCartbuynow, set_phone, setUpdatedPhoneNumber, addToCart, setLoginModal, saveCart, setCartBounce, getCart, checkOutOrder, previewCart, getCartPrice })(P_ProductLoading);
