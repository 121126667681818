import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { sendForgotPasswordOtp,setForgotPasswordOtpSent } from '../../actions/AuthAction';
import {convertTime} from '../../utils/CommonUtil';

const ResendForgotOtp = props => {

    const [seconds, setSeconds] = useState(300);
    const [isActive, setIsActive] = useState(false);

    const startCountDown = (time) => {
        setSeconds(time);
        setIsActive(true);
    }

    useEffect(() => {
        let interval = null;
        if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            if(seconds === 0) {
                setIsActive(!isActive);
            } 
        } else {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds]);

    useEffect(() => {
        if(typeof props.sendforgotpasswordotpdata.status != "undefined" && props.sendforgotpasswordotpdata.status==1){
            startCountDown(props.sendforgotpasswordotpdata.time*60); //value converted to sec    
        }
    },[props.sendforgotpasswordotpdata]);

    const resendHandler = () => {
        props.setForgotPasswordOtpSent({});
        props.sendForgotPasswordOtp({companyid:props.companyid,phone:props.phone})
    }

    return (
        <React.Fragment>
            <div className="w-100 text-left">
            <button className="btn btn-link text-danger pl-0" disabled={isActive} onClick={resendHandler}>Resend OTP</button>
           {isActive ? convertTime(seconds) : null}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    sendforgotpasswordotpdata:state.AuthData.sendforgotpasswordotpdata,
    companyid: state.HomeData.companyid
})

export default connect(mapStateToProps, {sendForgotPasswordOtp,setForgotPasswordOtpSent }) (ResendForgotOtp);