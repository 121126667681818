import React, { useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useNavigate,Link } from 'react-router-dom';
import {setProfoption } from '../../actions/UserAction';

const TopHeader = props => {
  const navigate = useNavigate();
  const order = () => {
    props.setProfoption("fourth");
    navigate("/profile");
}
  return (
    <div className="header-top">
      <Container className='mob-d-block'>
        <Row>
          <div className='col-md-6 p-0-mob col-8'>
            <div className='header-top-leftcontact-section'>
            {props.company?.showContactEmailOnline?<a href={'mailto:'+props.company?.contactEmail}><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;{props.company?.contactEmail}</a>:null}
              {props.company?.showContactPhoneOnline?<a href={'callto:'+props.company?.contactPhone}><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;{props.company?.contactPhone} </a>:null}
            </div>
          </div>
          <div className='col-md-6 p-0-mob col-4'>
            <div className='header-top-rightcontact-section'>
              <ul className='right-sm-ul'>
              {props.footerData && props.footerData.aboutUsDtl && props.footerData.aboutUsDtl.showAboutUs?props.footerData.aboutUsDtl.isHtml?
                <li><Link to={'/about-us'}>About</Link></li>:
                <li><a href={props.footerData.aboutUsDtl.aboutUs} target="_blank">About</a></li>:''}
                {/* <li><a onClick={order}>My Order</a></li> */}
                {props.footerData && props.footerData.contactUsDtl && props.footerData.contactUsDtl.showContactUs?props.footerData.contactUsDtl.isHtml?
                <li><Link to={'/contact-us'}>Contact</Link></li>:
                <li><a href={props.footerData.contactUsDtl.contactUs} target="_blank">Contact</a></li>:''}
              </ul>
              <div className='header-bottom-rightcontact-section'>
              {props.company?.showFacebookOnline?<a href={props.company?.facebook} target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a>:null}
              {props.company?.showInstagramOnline?<a href={props.company?.instagram} target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a>:null}
              {props.company?.twitter?<a href={props.company?.twitter} target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a>:null}
            </div>
            </div>
           
          </div>
        </Row>
      </Container>
    </div>
  )
}

const mapStateToProps = state => ({
  company: state.HomeData.companyDetailsCurrency,
  footerData: state.HomeData.footer
})

export default connect(mapStateToProps,{setProfoption})(TopHeader);