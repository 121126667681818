import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Login from './Login';
import { Modal, Button, Col } from 'react-bootstrap';
/**
 * Creates a new MsgModal.
 * @class
 */
const LoginModal = (props) => {  
    return (
        <React.Fragment>
            <Col className='login-modal'>
            <Modal show={props.show} backdrop="static" onHide={props.closeAction} centered>
                <Modal.Header closeButton className='border-0 pt-2 pb-1'>
                   
                </Modal.Header>
                <Modal.Body className='p-0'>
					<Login closeAction={props.closeAction}/>
                </Modal.Body>
               
            </Modal>
            </Col>
        </React.Fragment>
    )
}

LoginModal.propTypes = {
    show: PropTypes.bool.isRequired,
    closeAction: PropTypes.func
};

LoginModal.defaultProps = {
    show: false,
    closeAction: () => {},
}

export default LoginModal
