import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { fetchPhonePeStatus } from '../actions/CheckoutAction';
import { setProfoption } from '../actions/UserAction';
import {Button} from 'react-bootstrap';
import { useNavigate,useParams } from 'react-router-dom';
import { clearCart } from '../actions/CartAction';
import { cleanDiscount, clearOrder } from '../actions/CheckoutAction';
import { Global } from '../utils/Env';

const PhonePeOrderConfirmation = props => {
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        if(props.companyid && props.companyid !== '') {
			console.log("fetch");
            props.fetchPhonePeStatus({"companyid":props.companyid,"TransactionId":params.slug});
          }
    }, [props.companyid]);
    const order = () => {
        props.setProfoption("fourth");
        navigate("/profile");
    }
	Global.callback.phonepesuccess_onComplete = async () => {
		console.log("success",props.fetchedPhonePeData);
		if(props.fetchedPhonePeData && props.fetchedPhonePeData.success==true)
		{
			await props.clearCart();
			await props.cleanDiscount();
			await props.clearOrder();
            await order();
		}
	}
    return (
        <div className="products-wrapper">
            
                {/* <Row>
                    <Col>
                        <h4 className="pt-3 pb-3 mb-3 bord-bot-theme"><b>Our Best Sellers</b></h4>
                    </Col>
                </Row> */}
                <div className="mar-0-auto text-center">
                    {props.fetchedPhonePeData && props.fetchedPhonePeData.message?props.fetchedPhonePeData.message:''}
                </div>
                <br/>
                <div className="mar-0-auto text-center">
                <Button className="profile-each-list"onClick={order} >Go To Order History</Button>
                </div>
           
        </div>
    );
}

const mapStateToProps = state => ({
    companyid:state.HomeData.companyid,
    order: state.CheckoutData.order,
    fetchedPhonePeData:state.CheckoutData.fetchedPhonePeData
})

export default connect(mapStateToProps, {fetchPhonePeStatus,setProfoption,clearCart,cleanDiscount,clearOrder})(PhonePeOrderConfirmation);
