import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Container} from 'react-bootstrap';
//import ThemeSwitcher from 'react-css-vars';

import Header from '../components/header/Header';
import Footer from '../components/Footer';
import Brands from '../components/Brands';
import Notification from '../components/Notification';

import { getProductsList,getWishList } from '../actions/ProductsAction';
import { setInView } from '../actions/HomeAction';
import { setCheckOutCartState } from '../actions/CartAction';
import Wallet from '../components/Wallet';
import { useInView } from 'react-intersection-observer';


const HomeContainer = props => {
  console.log("h",props.companyDetailsCurrency.announcementText)
  const { companyid, setCheckOutCartState, getProductsList } = props;
  //.. initial call for company and app setup

  useEffect(() => {
    setCheckOutCartState(false);
  }, []);

  useEffect(() => {
    if (companyid && companyid !== '') {
      //getProductsList(companyid);
    }
  }, [companyid]);
  
  useEffect(()=>{
		//if(props.authenticated)
		//props.getWishList({"companyid":props.companyid,"customerid":parseInt(props.loginData.data.customerId)});
	},[props.authenticated]);

  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  useEffect(()=>{
    console.log("inView",inView);
    props.setInView(inView);
  },[inView])


  return (
    <React.Fragment>
      <header id='header'>
        <Notification />
        <Wallet/>
		    <Header />
        
      </header>
      <div className={ props.companyDetailsCurrency.announcementText? "full-Pagesection-notification-removed full-Pagesection-announce" : "full-Pagesection"}>
        <Brands />
        <Outlet />
      </div>
      <div ref={ref}>
      <Footer />
      </div>
    </React.Fragment>
  );

}

const mapStateToProps = state => ({
  companyid: state.HomeData.companyid,
  productsList: state.ProductsData.productsList,
  mobileState: state.CategoryData.isMobileMenu,
  notification: state.UserData.notification,
  deliverablePin: state.LocationData.deliverablePin,
  authenticated: state.AuthData.authenticated,
  loginData: state.AuthData.loginData,
  companyDetailsCurrency: state.HomeData.companyDetailsCurrency
})

export default connect(mapStateToProps, { getProductsList, setCheckOutCartState,getWishList,setInView })(HomeContainer);